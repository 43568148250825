import { performRequestSecond, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const diff_hours = (duration) => {
  try {
    let v = 0.1;
    var diff = duration / 1000;
    var minutes = Math.ceil(diff / 60);
    let val = Math.ceil(minutes / 6);
    let hoursInSix = v * val;
    if (hoursInSix) {
      return hoursInSix.toString();
    } else {
      return '0.1';
    }
  } catch (err) {
    return '0.1';
  }
};

export const dhm = (t) => {
  let duration = t;
  //let milliseconds = Math.floor((duration % 1000) / 100);
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);
  let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  let days = Math.floor(duration / (1000 * 60 * 60 * 24));

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  hours = days > 0 ? days * 24 + parseInt(hours) : hours;

  return hours + ':' + minutes + ':' + seconds; /* + "." + milliseconds; */
};

export const createTimeEntry = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createTimeEntry}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const updateTimeEntry = (data, is_lead = false, timeEntryData = '', login = true, excludeFromLoading = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateTimeEntry}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`;
  return performRequestSecond(apiEndPoints.methodType.PUT, url, data, timeEntryData, login, excludeFromLoading);
};

export const listTimeEntries = (
  data,
  is_automated = true,
  pageLimit = 10,
  pagination_token = '',
  pagination_direction = 'forward',
  is_full_list = false
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listTimeEntries}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_automated=${is_automated}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&is_full_list=${is_full_list}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const getTimeEntry = (time_entry_sk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getTimeEntry}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&time_entry_sk=${time_entry_sk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const deleteTimeEntry = (time_entry_sk, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deleteTimeEntry}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&time_entry_sk=${time_entry_sk}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequestSecond(apiEndPoints.methodType.DELETE, url);
};

export const createActivityType = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createActivityType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const updateActivityType = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.updateActivityType}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.PUT, url, data);
};

export const getActivityType = (sk, pk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getActivityTypes}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&sk=${sk}&pk=${pk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const deleteActivityType = (sk, pk) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.deleteActivityTypes}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&sk=${sk}&pk=${pk}`;
  return performRequestSecond(apiEndPoints.methodType.DELETE, url);
};

export const listActivityTypes = (next = '', limit = 10, pagination_token = '', pagination_direction = 'forward') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listActivityTypes}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&next=${next}&limit=${limit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url);
};

export const getSummaryOfTimeEntries = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getSummaryOfTimeEntries}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data);
};

export const getUserHourRate = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getHourRate}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const getTimeTracker = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.timeTracker}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};
