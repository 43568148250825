import { useState } from 'react';

export default function useHandleFilterChange() {
  const [fileManagerFilters, setFileManagerFilters] = useState({
    fileType: '',
    tag: '',
    case: '',
    selectedFolder: '',
  });

  function onFilterChange(e, clearBreadcrumbs = () => {}) {
    if (!e?.value?.id || !e?.value) {
      clearBreadcrumbs();
    }
    setFileManagerFilters((prevValues) => ({
      ...prevValues,
      selectedFolder: '',
      [e?.target?.name]: e?.value,
    }));
  }
  function onTagChange(e) {
    setFileManagerFilters((prevValues) => ({
      ...prevValues,
      [e.target.name]: e.value,
    }));
  }

  return { fileManagerFilters, onFilterChange, onTagChange, setFileManagerFilters };
}
