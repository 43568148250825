export const types = [
  { label: 'Person', value: 'person' },
  { label: 'Business', value: 'business' },
  { label: 'Location', value: 'location' },
  { label: 'Vendor', value: 'vendor' },
  { label: 'Medical Provider', value: 'medical_provider' },
];

export const contactType = ['person', 'business', 'location', 'vendor', 'medical_provider'];

export const titles = [
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Ms.', value: 'Ms.' },
  { label: 'Mrs.', value: 'Mrs.' },
  { label: 'Miss.', value: 'Miss.' },
];

export const contactTypes = [
  { label: 'Show All', value: 'All' },
  { label: 'Person', value: 'person' },
  { label: 'Location', value: 'location' },
  { label: 'Business', value: 'business' },
  { label: 'Vendor', value: 'vendor' },
  { label: 'Medical Provider', value: 'medical_provider' },
];

export const status = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'ARCHIVED' },
]; // for contact filter page

export const paginationDropdownOptions = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];
export const FileTypes = [
  { label: 'Show All', value: 'All' },
  { label: 'Pdf', value: 'pdf' },
  { label: 'Image', value: 'image' },
  { label: 'Text', value: 'text' },
  { label: 'Table', value: 'table' },
  { label: 'Docx', value: 'docx' },
  { label: 'Audio', value: 'audio' },
  { label: 'Video', value: 'video' },
];
