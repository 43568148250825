import React, { useEffect, useState } from 'react';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Dialog } from 'primereact/dialog';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

import ThumbnailComponent from './ThumbnailComponent';
import ImageViewer from './ImageViewer';
import { getFileExtension, getFileType } from 'utils/filesUtils';
import { getTenantDetails } from 'utils/utils';
import { useActivityLog } from 'hooks/useActivityLog';

import DocumentViewer from './DocumentViewer';
import TextFileViewer from './TextFileViewer';
import VideoViewer from './VideoViewer';

function PreviewModal() {
  const {
    modals,
    previewList,
    selectedPreviewItem,
    setSelectedPreviewItem,
    versionFileUpload,
    onMountUnMount,
    onOperationBreak,
    isCaseFileManager,
    isClientFileManager,
  } = useFileManagerContext();
  const { showPreview, setShowPreview } = modals;

  const [filesData, setFilesData] = useState([]);
  const { addActivity } = useActivityLog();

  useEffect(() => {
    if (versionFileUpload) {
      const updatedFilesData = previewList.map((item) => ({
        ...item,
        type: findType(item),
      }));

      setFilesData(updatedFilesData);
    } else {
      const filtered = previewList.filter((item) => item.entry_type === 'file');
      const updatedFilesData = filtered.map((item) => ({
        ...item,
        type: findType(item),
      }));

      setFilesData(updatedFilesData);
    }
  }, [previewList, versionFileUpload]);

  //Automated time entry logic
  useEffect(() => {
    return () => {
      isCaseFileManager && typeof onMountUnMount === 'function' && onMountUnMount('file-preview', 'unmounted');
      setTimeout(() => {
        isCaseFileManager && typeof onOperationBreak === 'function' && onOperationBreak();
      }, 3000);
    };
  }, []);

  const createActivityLog = (item) => {
    setTimeout(() => {
      const [tenantId] = getTenantDetails();
      const data = {
        event_key: 'Read',
        event_type: 'document',
        event_subtype: 'read_document',
        message: `Viewed Document: ${item.display_name}`,
        event_status: 'success',
        event_items: {
          case_id: item?.case_id || '',
          tenant_id: tenantId,
          case_name: item?.case_name || '',
          is_lead: item?.is_lead,
        },
      };
      addActivity(data);
    }, 5000);
    //Automated time entry logic
    isCaseFileManager && typeof onMountUnMount === 'function' && onMountUnMount('file-preview', 'mounted');
  };

  function findType(item) {
    return getFileType(getFileExtension(item.display_name));
  }

  function closePreviewModal() {
    setShowPreview(false);
    setSelectedPreviewItem();
  }

  const customRenderArrowPrev = (clickHandler, hasPrev) => {
    return <i className={'pi pi-chevron-left ' + (!hasPrev && 'opacity-50')} style={arrowStyles} onClick={clickHandler} />;
  };

  const customRenderArrowNext = (clickHandler, hasNext) => {
    return <i className={'pi pi-chevron-right ' + (!hasNext && 'opacity-50')} style={arrowStyles} onClick={clickHandler} />;
  };

  const arrowStyles = {
    padding: '10px',
    border: '1px solid',
    margin: 'auto 0',
    borderRadius: '8px',
    cursor: 'pointer',
  };

  const thumbnails = filesData.map((item, index) => (
    <ThumbnailComponent key={index} type={item.type} url={item.url} display_name={item.display_name} />
  ));

  function selectedDataIndex(selectedItem, data) {
    if (versionFileUpload) {
      if (selectedItem) {
        const selectedIndex = data.findIndex((item) => item.version_id === selectedItem.version_id);
        return selectedIndex !== -1 ? selectedIndex : 0;
      }
      return 0;
    } else {
      if (selectedItem) {
        const selectedIndex = data.findIndex((item) => item.object_id === selectedItem.object_id);
        return selectedIndex !== -1 ? selectedIndex : 0;
      }
      return 0;
    }
  }

  const customHeaderTemplate = (data) => {
    return (
      <div>
        <div className="d-flex justify-content-between">
          <div>
            <i className="icon-window-filled pointer F-size14" onClick={data?.onHide}></i>
            {/* <i className="icon-window-filled pointer F-size14 icon-minimize"></i> */}
          </div>
          <span className="ms-3">{selectedPreviewItem?.display_name}</span>
          <span></span>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      visible={showPreview}
      modal
      resizable={false}
      draggable={false}
      className="my-dialog custom-dialog preview-dialog"
      header={customHeaderTemplate}
      onHide={closePreviewModal}
      closable={false}
    >
      <Carousel
        renderThumbs={() => thumbnails}
        showThumbs={true}
        showStatus={true}
        showIndicators={false}
        centerMode={false}
        renderArrowPrev={customRenderArrowPrev}
        renderArrowNext={customRenderArrowNext}
        selectedItem={selectedDataIndex(selectedPreviewItem, filesData)}
        onChange={(e) => setSelectedPreviewItem(filesData[e])}
        // useKeyboardArrows={true}
      >
        {filesData.map((item, index) => (
          <div key={index} style={{ height: '100%', width: '100%' }}>
            {index === selectedDataIndex(selectedPreviewItem, filesData) ? (
              <>
                {item?.type === 'image' ? (
                  <ImageViewer imageUrl={item?.url} title={item?.caption} onLoad={() => createActivityLog(item)} item={item} />
                ) : item?.type === 'video' ? (
                  <VideoViewer onLoad={() => createActivityLog(item)} item={item} />
                ) : item?.type === 'text' ? (
                  <TextFileViewer fileUrl={item?.url} onLoad={() => createActivityLog(item)} item={item} />
                ) : (
                  <div style={{ height: '100%', width: '100%' }}>
                    <DocumentViewer
                      document={item?.url}
                      onLoad={() => createActivityLog(item)}
                      item={item}
                      isClientFileManager={isClientFileManager}
                    />
                  </div>
                )}
              </>
            ) : null}
          </div>
        ))}
      </Carousel>
    </Dialog>
  );
}

export default PreviewModal;
