import apiEndPoints from 'common/apiEndPoints';
import { performRequestThird } from 'common/apiHandler';
import { getTenantDetails } from 'utils/utils';

export const getTemporaryFundBalance = (trustAccountId, caseId) => {
  const [tenantId = '', tenantSk = ''] = getTenantDetails();

  return performRequestThird(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getTemporaryFundBalance}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${caseId ? `&case_id=${caseId}` : ''}${
      trustAccountId ? `&trust_account_id=${trustAccountId}` : ''
    }`
  );
};

//POST Operations
export const depositOrWithdrawTemporaryFund = (params = {}) => {
  const [tenantId = '', tenantSk = ''] = getTenantDetails();
  const { data } = params || {};

  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.temporaryFunds}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data
  );
};
export const updateTemporaryFundTransaction = (params = {}) => {
  const [tenantId = '', tenantSk = ''] = getTenantDetails();
  const { data, limit = 100, pagination_direction = 'forward', pagination_token = '' } = params || {};

  const url = `${apiEndPoints.updateTemporaryFundTransaction}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${limit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;

  return performRequestThird(apiEndPoints.methodType.PUT, url, data);
};

//PATCH Operations
export const listTemporaryFunds = ({ filter, limit = 100, pagination_direction = 'forward', pagination_token = '' }) => {
  const [tenantId = '', tenantSk = ''] = getTenantDetails();
  return performRequestThird(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.listTemporaryFunds}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${limit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`,
    filter
  );
};
