import React, { useEffect, useState, useContext, useRef } from 'react';
import { Auth } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Avatar } from 'primereact/avatar';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Menu } from 'primereact/menu';
import { Tooltip } from 'primereact/tooltip';
import Cookies from 'universal-cookie';
import classNames from 'classnames';

import { UserDetailsContext } from 'context/userDetailsContext';
import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';
import { notificationAction } from 'redux/actions/notificationAction';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { markNotification, clearNotifications } from 'services/notification/notificationService';
import { firmSelectHandler } from 'utils/utils';
import useSpinner from 'hooks/useSpinner';

import { getUserProfileDetails } from 'services/profileServices';
import { presignedURLGet } from 'modules/file_manager/services';
import { appVersion } from 'config/app_version';
import { openLinkInBlank } from 'utils/utils';
import { logout } from 'utils/utils';
import { loginLogoutEvent } from 'services/generalServices';
import { useNavbarContext } from 'context/NavbarContext';
import { default as InfiniteScrollList } from 'components/InfiniteScrollNotificationList/InfiniteScrollNotificationList';
import { Notification } from 'modules/layout/components/Notification/Notification';
import logo from '../../assets/images/logo_lawft_transparent.png';
import constants from 'constants/index';

const cookie = new Cookies();

export const UserProfileContext = React.createContext();

const Layout = ({ children, showSideBar }) => {
  const history = useHistory();
  const [userInfo, setUserInfo] = useState('');
  const profileRef = useRef(null);
  const [navigationToggle, setNavigationToggle] = useState(false);
  const userContext = useContext(UserDetailsContext);
  const { notificationCounts, getNotificationCounts, notificationData } = useNavbarContext(); //here
  const { notifications = [], next_token: nextToken, total_notifications } = notificationData?.notifications || {};

  const { isLoading, setNotifications } = notificationData || {};

  const webSocketContext = useContext(WebSocketConnectionContext);
  const location = useLocation();
  const { pathname } = location;
  const [newNotification, setNewNotification] = useState(false);
  const notificationPanel = useRef();
  const websocketContext = useContext(WebSocketConnectionContext);
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const [spinner, showSpinner, hideSpinner] = useSpinner(true);
  const [imageUrl, setImageUrl] = useState('');
  const [currentToken, setCurrentToken] = useState('');
  const [webSocketNotificationsCount, setWebSocketNotificationsCount] = useState(0);

  const loggedInUserId = cookie.get('userId') || null;
  const totalNotiicationsCount = Number(total_notifications || 0) + Number(webSocketNotificationsCount || 0) || 0;

  const clearAll = (e) => {
    e.preventDefault();
    showSpinner();
    clearNotifications()
      .then(() => {
        hideSpinner();
        setWebSocketNotificationsCount(0);
        setNotifications({});
      })
      .catch((err) => {
        hideSpinner();
      });
  };

  const handleLogout = () => {
    try {
      loginLogoutEvent();
      Auth.signOut()
        .then(() => {
          logout(history);
          websocketContext.closeConnection();
        })
        .catch((error) => {});
    } catch (e) {}
  };

  const markAsRead = (e, data) => {
    showSpinner();
    e.preventDefault();
    markNotification(true, [data?.sk])
      .then(() => {
        notificationData?.markasRead(data?.sk);
        notificationData?.notifications?.notifications?.length <= 8 &&
          nextToken &&
          notificationData?.loadNotifications({ notificationType: '', limit: 10, nextToken });
        hideSpinner();
      })
      .catch((err) => {
        console.log('err', err);
        hideSpinner();
      });
  };

  const profileMenu = [
    {
      label: 'Profile',
      command: () => {
        if (!openLinkInBlank('/client/profile')) {
          window.location.hash = '/client/profile';
          setNavigationToggle(false);
        }
      },
      show: true,
    },
    {
      label: `Select Firm`,
      command: () => firmSelectHandler(history),
      show: userContext?.firmDetails?.firm_details?.length > 1,
    },
    {
      label: `Logout`,
      command: handleLogout,
      show: true,
    },
  ];

  const hideDropdown = (e) => setNavigationToggle(false);
  const getUserName = () => {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo && userInfo.attributes) {
      return userInfo.attributes['custom:first_name'] + ' ' + userInfo.attributes['custom:last_name'];
    } else {
      return 'User';
    }
  };

  const fetchImage = async (filePath) => {
    try {
      let fileSize = 'small.jpg';
      if (filePath) {
        let proURL = filePath;
        let url = proURL.split('/');
        url.pop();
        if (fileSize) {
          url.push(fileSize);
        } else {
          url.push('small.jpg');
        }
        let finalUrl = url.join('/');
        let data = await presignedURLGet(finalUrl, false, true);
        if (data && data.data && data.data.file_url) {
          setImageUrl(data.data.file_url);
        } else {
          setImageUrl('');
        }
      }
    } catch (error) {}
  };

  const fetchUserDetails = () => {
    getUserProfileDetails()
      .then((res) => {
        if (res && res.data) {
          fetchImage(res?.data?.profile_image);
        }
      })
      .catch((error) => console.log(error));
  };
  const getUserDetails = () => {
    try {
      Auth.currentUserInfo()
        .then((res) => {
          localStorage.setItem('userInfo', JSON.stringify(res));
          setUserInfo(res);
        })
        .catch((error) => {
          localStorage.setItem('userInfo', null);
        });
    } catch (e) {
      localStorage.setItem('userInfo', null);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (userContext) {
      fetchUserDetails();
      getNotificationCounts(userContext?.userDetails?.contact_id);

      if (userContext?.access_level) {
        let msg = userContext?.selectedPlanDetails?.message;
        if (msg === constants?.NON_FIRM_ADMIN_SUBSCRIPTION_CANCEL_MESSAGE) {
          handleLogout();
        } else {
          let tenantId = cookie.get('tenantId');
          if (userContext?.firmDetails?.current_user && tenantId) {
            webSocketContext?.connectToWebSocket(tenantId, userContext?.firmDetails?.current_user, 'client');
            dispatch(notificationAction('', 1000, ''));
          } else if (!tenantId) {
            return history.replace('/tenant-selection');
            // if(window.location.hostname=="localhost")  return history.push("/tenant-selection");
            // return window.open(`http://${process.env.REACT_APP_DOMAIN}/#/tenant-selection`, "_self")
          }
        }
      }
    }
  }, [userContext]);

  useEffect(() => {
    let notificationsArr = [...notifications] || [];

    if (websocketContext?.notificationData) {
      if (
        websocketContext?.notificationData?.notification_type === 'secure_message' &&
        websocketContext?.notificationData?.created_by_user_id &&
        websocketContext?.notificationData?.created_by_user_id === loggedInUserId
      ) {
        return;
      }

      setWebSocketNotificationsCount(webSocketNotificationsCount + 1);
      notificationsArr.unshift(websocketContext?.notificationData);
      setNewNotification(true);
      userContext?.userDetails?.contact_id && getNotificationCounts(userContext?.userDetails?.contact_id);
    }
    setNotifications((preval) => ({ ...preval, notifications: notificationsArr }));
  }, [websocketContext?.notificationData]);

  //Secure message toast message
  useEffect(() => {
    if (
      websocketContext.secureMessageData &&
      Object.keys(websocketContext?.secureMessageData)?.length > 0 &&
      websocketContext?.secureMessageData?.created_by_user_id === loggedInUserId &&
      websocketContext?.secureMessageData?.origin !== 'automation'
    ) {
      addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.MESSAGE_SEND_SUCCESS);
    }
  }, [websocketContext?.secureMessageData]);

  if (!showSideBar) return <div id="layout">{children}</div>;

  return (
    <div id="layout">
      <Notification
        visible={webSocketContext.notificationPopup}
        notificationData={webSocketContext?.notificationData}
        onHide={() => webSocketContext.setNotificationPopup(false)}
      />
      <OverlayPanel
        ref={notificationPanel}
        id="overlay_panel"
        style={{ width: '500px' }}
        className="overlaypanel-demo shadow p-1"
        onHide={() => null}
        ariaCloseLabel="notification-panel"
        onContentClick={() => null}
      >
        {spinner}
        <div className="notification-wrapper" style={{ overflow: 'hidden' }}>
          <div className="pb-2">
            <div className="col-12 d-flex justify-content-between align-items-center">
              <h6 className="fw-bold text-dark m-0">Notifications</h6>
              {notifications?.length > 0 && (
                <a href="/" className="text-muted text-decoration-none" onClick={clearAll}>
                  <small>Clear All</small>
                </a>
              )}
            </div>
          </div>

          {notifications?.length > 0 ? (
            <InfiniteScrollList
              isClient
              {...{
                notificationList: notifications,
                loadNotifications: notificationData?.loadNotifications,
                isLoading,
                nextToken,
                currentToken,
                setCurrentToken,
                markAsRead,
              }}
            />
          ) : (
            <div className="row mt-2">
              <div className="col-12 d-flex justify-content-center">No notifications found</div>
            </div>
          )}
        </div>
      </OverlayPanel>
      <nav className="navbar navbar-expand-lg px-2" style={{ background: '#edeef2' }}>
        <div className="container-fluid flex-wrap">
          <Link to="/" className="text-dark text-bold navbar-brand">
            <img src={logo} alt="logo" width="88" height="18" />
            <span className="small text-medium ms-2 version">v{appVersion}</span>
          </Link>
          <h1 className="title gray-800 mb-0">{userContext?.tenantName}</h1>
          <button
            className="navbar-toggler ms-3"
            type="button"
            data-toggle="collapse"
            data-target="#basicExampleNav"
            aria-controls="basicExampleNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setNavigationToggle(!navigationToggle)}
          >
            <span className="navbar-toggler-icon d-flex align-items-center">
              <i className="fa fa-bars"></i>
            </span>
          </button>

          <div
            className={navigationToggle ? 'collapse navbar-collapse show' : 'collapse navbar-collapse justify-content-end'}
            id="basicExampleNav"
          >
            <ul className="navbar-nav mx-5">
              <li
                className={classNames('nav-item', {
                  active: pathname === '/client/dashboard',
                })}
              >
                <Link to="/" className="nav-link">
                  Dashboard
                </Link>
              </li>
              <li
                className={classNames('nav-item', {
                  active: pathname === '/client/files',
                })}
              >
                <Link to="/client/files" onClick={hideDropdown} className="nav-link">
                  Files
                  {Boolean(notificationCounts?.file_count) && <i className="icon-window-filled pointer F-size12"></i>}
                </Link>
              </li>
              {userContext.checkSubPermission('calendaring') && (
                <li
                  className={classNames('nav-item', {
                    active: pathname === '/client/calendar',
                  })}
                >
                  <Link to="/client/calendar" onClick={hideDropdown} className="nav-link">
                    Calendar
                    {Boolean(notificationCounts?.event_count) && <i className="icon-window-filled pointer F-size12"></i>}
                  </Link>
                </li>
              )}
              {userContext.checkSubPermission('task_management') && (
                <li
                  className={classNames('nav-item', {
                    active: pathname === '/client/tasks',
                  })}
                >
                  <Link to="/client/tasks" onClick={hideDropdown} className="nav-link">
                    Tasks
                    {Boolean(notificationCounts?.task_count) && <i className="icon-window-filled pointer F-size12"></i>}
                  </Link>
                </li>
              )}
              {userContext.checkSubPermission('secure_messages') && (
                <li
                  className={classNames('nav-item', {
                    active: pathname === '/client/messages',
                  })}
                >
                  <Link to="/client/messages" onClick={hideDropdown} className="nav-link">
                    Messages
                    {Boolean(notificationCounts?.secure_message_count) && <i className="icon-window-filled pointer F-size12"></i>}
                  </Link>
                </li>
              )}
              <li
                className={classNames('nav-item', {
                  active: pathname === '/client-billing',
                })}
              >
                <Link to="/client-billing" onClick={hideDropdown} className="nav-link">
                  Billing
                  {Boolean(notificationCounts?.billing_count) && <i className="icon-window-filled pointer F-size12"></i>}
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-flex ms-auto align-items-center">
            <div className="d-flex align-items-center top-nav-avatar">
              <a
                className="nav-link dropdown-toggle d-flex align-items-center"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-expanded="false"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  profileRef.current.toggle(e);
                }}
              >
                <Avatar
                  image={imageUrl}
                  onImageError={() => setImageUrl('')}
                  className={'me-1'}
                  size={'medium'}
                  shape="circle"
                  imageAlt=""
                  label={!imageUrl ? (getUserName() ? getUserName().charAt(0) : 'C') : ''}
                />
              </a>
              <div className="dropdown-menu dropdown-primary" style={{ right: '80px' }}>
                <Menu model={profileMenu.filter((v) => v.show)} popup ref={profileRef} id="popup_menu" />
              </div>
            </div>

            <div
              className="mr-4 px-2 d-flex align-items-center"
              aria-controls="notification-panel"
              onClick={(e) => {
                notificationPanel.current.toggle(e);
                setNewNotification(false);
              }}
            >
              <i className={newNotification ? 'icon-notification bell fa-lg me-1 pointer' : 'icon-notification fa-lg me-1 pointer'}></i>
              <Tooltip content="Notifications" target=".icon-notification" position="bottom" showDelay={500} />
              {totalNotiicationsCount ? (
                <span className={newNotification ? 'p-badge bg-danger' : 'p-badge'}>
                  {totalNotiicationsCount ? totalNotiicationsCount : null}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </nav>

      <div className="content-container">
        <div className="container-fluid px-3">
          <div className="jumbotron px-1 admin-portal">
            <UserProfileContext.Provider value={userInfo}>{children}</UserProfileContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
