import { performRequest, performRequestSecond, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const createCase = (data, tenantId, tenantSk, timeEntryData = '') => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`,
    data,
    timeEntryData
  );
};

export const updateCase = (data, _t1, _t2, is_lead = false, timeEntryData = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};
export const getCaseList = (data, status, tag, practice, caseMatter, searchValue, limit, pagination_token, pagination_direction, next) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.getCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_status=${status}${tag ? `&tags=${tag}` : ''}${
      practice ? `&case_practice_area=${practice}` : ''
    }${caseMatter ? `&case_matter=${caseMatter}` : ''}${
      searchValue ? `&keyword=${searchValue}` : ''
    }&next=${next}&limit=${limit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}
    `,
    data
  );
};
export const getCase = (
  tenantId,
  tenantSk,
  data,
  status,
  tag,
  practice,
  caseMatter,
  searchValue,
  limit,
  pagination_token,
  pagination_direction,
  next
) => {
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.getCase}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_status=${status}${tag ? `&tags=${tag}` : ''}${
      practice ? `&case_practice_area=${practice}` : ''
    }${caseMatter ? `&case_matter=${caseMatter}` : ''}${
      searchValue ? `&keyword=${searchValue}` : ''
    }&next=${next}&limit=${limit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}
    `,
    data
  );
};
export const deleteCase = (casePk, caseSk, data = {}, timeEntryData = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  let tenantSkModified = tenantSk.replace('#', '%23');
  let caseSkModified = caseSk.replace('#', '%23').replace('#', '%23');
  let pkModified = casePk.replace('#', '%23');
  return performRequestSecond(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.deleteCase}?tenant_id=${tenantId}&tenant_sk=${tenantSkModified}&case_pk=${pkModified}&case_sk=${caseSkModified}`,
    data,
    timeEntryData
  );
};
export const closeCase = (isOpen, data, timeEntryData = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  let tenantSkModified = tenantSk.replace('#', '%23');
  return performRequestSecond(
    apiEndPoints.methodType.PATCH,
    `${apiEndPoints.closeCase}?tenant_id=${tenantId}&tenant_sk=${tenantSkModified}&is_open=${isOpen}`,
    data,
    timeEntryData
  );
};
export const getCaseDetails = (tenantId, tenantSk, pk, sk, timeEntryData = {}, case_id = '') => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints?.getCaseDetails}?tenant_id=${tenantId ? tenantId : ''}&tenant_sk=${tenantSk ? tenantSk : ''}&pk=${pk ? pk : ''}&sk=${
      sk ? sk : ''
    }
    ${case_id ? `&case_id=${case_id}` : ''}`,
    {},
    timeEntryData
  );
};

export const createCaseTag = (data, tenantId, tenantSk) => {
  return performRequest(apiEndPoints.methodType.POST, `${apiEndPoints.createCaseTag}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`, data);
};

export const getCaseTag = (tenantId = '', tenantSk = '', isLead = false) => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getCaseTag}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_lead=${isLead}`
  );
};

export const getPracticeAreas = (tenantId, tenantSk, pageLimit, pagination_token = '', pagination_direction = 'forward', filters = {}) => {
  [tenantId, tenantSk] = getTenantDetails();
  const { status } = filters || {};

  const method = apiEndPoints.methodType.GET;
  const url = `${
    apiEndPoints.practiceArea
  }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}${
    status ? `&status=${status}` : ''
  }`;

  return performRequest(method, url);
};

export const getCaseStage = (
  tenantId,
  tenantSk,
  practice_area = false,
  pageLimit = 10,
  pagination_token = '',
  pagination_direction = 'forward'
) => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${
      apiEndPoints.getCaseStage
    }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}${
      practice_area ? `&practice_area=${practice_area}` : ''
    }`
  );
};

export const getContact = (tenantId, tenantSk, limit = 10, pagination_token = '', pagination_direction = 'forward') => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getContact}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${limit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}&contact_status=ACTIVE`
  );
};

export const getConflictCheck = (tenantId, tenantSk, id, is_lead = false) => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getConflictCheck}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&id=${id}${is_lead ? `&is_lead=${is_lead}` : ''}`
  );
};

export const getCaseMatter = (tenantId, tenantSk, pageLimit, pagination_token, pagination_direction) => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getCaseMatter}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const getCaseNumberValidation = (tenantId, tenantSk, case_number) => {
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getCaseNumberValidation}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_number=${case_number}`
  );
};

export const listCasesForUser = (target_user_type, target_user_id, target_user_name, is_lead = true, is_events = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  const url = `${apiEndPoints.getUserCases}?tenant_sk=${tenantSk}&tenant_id=${tenantId}&target_user_type=${target_user_type}&target_user_id=${target_user_id}&target_user_name=${target_user_name}&is_lead=${is_lead}&is_events=${is_events}`;
  return performRequest(apiEndPoints.methodType.GET, url);
};

export const getTaskList = (
  caseId,
  startDate,
  end_date,
  priority,
  isSelf,
  assigneeId,
  status,
  data = { firm_user: [] },
  pageLimit = 10,
  pagination_token = '',
  pagination_direction = 'forward'
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.getTaskList}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${caseId ? `&case_id=${caseId}` : ''}${
    startDate ? `&start_date=${startDate}` : ''
  }${end_date ? `&end_date=${end_date}` : ''}${priority ? `&priority=${priority}` : ''}${isSelf ? `&is_self=${isSelf}` : ''}${
    assigneeId ? `&assignee_id=${assigneeId}` : ''
  }${
    status ? `&task_stage=${status}` : ''
  }&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`;

  // return performRequest(apiEndPoints.methodType.GET, url);
  return performRequest(apiEndPoints.methodType.POST, url, data); //"data" is the newly added parameter
};
export const createTasks = (data, is_lead = false, timeEntryData = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createTask}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};
export const deleteTask = (caseId, sk, pk, is_lead = false, timeEntryData = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  let tenantSkModified = tenantSk.replace('#', '%23');
  let pkModified = pk.replace('#', '%23');
  let skModified = sk.replace('#', '%23');
  let skTrimmedFromHash = skModified.replace('#', '%23');
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${
      apiEndPoints.deleteTask
    }?tenant_id=${tenantId}&tenant_sk=${tenantSkModified}&case_id=${caseId}&pk=${pkModified}&sk=${skTrimmedFromHash}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    {},
    timeEntryData
  );
};
export const updateTaskDetails = (taskId, userId, data, isReminderUpdated, is_lead = false, timeEntryData = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.PUT,
    `${apiEndPoints.updateTask}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&task_id=${taskId}${
      isReminderUpdated ? `&is_reminder_updated=${isReminderUpdated}` : ''
    }${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data,
    timeEntryData
  );
};
export const getTaskDetails = (taskId, timeEntryData = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getTaskDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&
    &task_id=${taskId}`,
    {},
    timeEntryData
  );
};

export const getClientUserList = (
  caseId,
  is_contact_list = false,
  is_team_member_list = false,
  all_contacts = false,
  contact_id = false,
  is_lead = false,
  is_mandatory_lawft_access = true,
  is_active = false
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.calendar.userList}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_mandatory_lawft_access=${is_mandatory_lawft_access}`;
  if (caseId) url = url + '&case_id=' + caseId;
  if (is_contact_list) url = url + '&is_contact_list=' + is_contact_list;
  if (is_team_member_list) url = url + '&is_team_member_list=' + is_team_member_list;
  if (all_contacts) url = url + '&all_contacts=' + all_contacts;
  if (contact_id) url = url + '&contact_id=' + contact_id;
  if (is_lead) url = url + '&is_lead=' + is_lead;
  if (is_active) url = url + '&is_active=' + is_active;
  return performRequest(apiEndPoints.methodType.GET, url);
};
export const createTaskStages = (data, is_lead = false) => {
  const [tenantId, tenantSk] = getTenantDetails();
  //as an object {}
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.createTaskStage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${is_lead ? `&is_lead=${is_lead}` : ''}`,
    data
  );
};
export const getTaskStages = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(apiEndPoints.methodType.GET, `${apiEndPoints.getTaskStages}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`);
};

export const deleteTaskStage = (pk, sk, is_lead = false) => {
  const tenantId = cookie.get('tenantId') || null;
  const tenant_sk = cookie.get('tenantSk') || null;
  let tenantSk = tenant_sk ? tenant_sk.replace('#', '%23') : '';
  let skNew = sk.replace('#', '%23');
  let skUpdated = skNew.replace('#', '%23');
  let pkNew = pk.replace('#', '%23');
  //let pkUpdated = pkNew.replace("#", "%23");
  return performRequest(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteTaskStage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pkNew}&sk=${skUpdated}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`
  );
};

export const updateTaskStage = (pk, sk, data, is_lead) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let skNew = sk.replace('#', '%23');
  let skUpdated = skNew.replace('#', '%23');
  let pkNew = pk.replace('#', '%23');
  //let pkUpdated = pkNew.replace("#", "%23");
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.updateTaskStage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pkNew}&sk=${skUpdated}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    data
  );
};

export const addTeamMember = (data, link_to_user = '', case_id = '', is_lead = false, time_entry_data = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.addTeamMember}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${case_id}&link_to_user=${link_to_user}${
    is_lead ? `&is_lead=${is_lead}` : ''
  }`;
  return performRequestSecond(apiEndPoints.methodType.POST, url, data, time_entry_data);
};

export const deleteCaseTeamMember = (sk, pk, is_lead = false, timeEntryData = '') => {
  let skNew = sk.replaceAll('#', '%23');
  let pkNew = pk.replaceAll('#', '%23');
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.DELETE,
    `${apiEndPoints.deleteCaseTeamMember}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&pk=${pkNew}&sk=${skNew}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }`,
    {},
    timeEntryData
  );
};

export const updateCaseContact = (case_id, contact_id, data, contact_update = false, is_lead = false, timeEntryData = '') => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.updateCaseContact}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&case_id=${case_id}&contact_id=${contact_id}${
      is_lead ? `&is_lead=${is_lead}` : ''
    }${contact_update ? `&contact_update=${contact_update}` : ''}`,
    data,
    timeEntryData
  );
};

export const caseUserClientDetails = (case_id, all_contacts, is_mandatory_lawft_access) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.calendar.userList}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${
      case_id ? `&case_id=${case_id}` : ''
    }&all_contacts=${all_contacts}&is_mandatory_lawft_access=${is_mandatory_lawft_access}`
  );
};

export const getCaseBillingSummary = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.caseBillingSummary}?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

export const getOutcomeList = (is_lead) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listOutcome}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_lead=${is_lead}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const createOutcome = (is_lead, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createOutcome}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_lead=${is_lead}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

export const saveCaseRelatedOutcomeItem = (is_lead, lead_sk, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createOutcomeItem}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_lead=${is_lead}&lead_sk=${lead_sk}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

export const saveCaseRelatedReasonItem = (is_lead, lead_sk, data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.createClosureItem}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&is_lead=${is_lead}&lead_sk=${lead_sk}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

//Api to get list of cases and lead
// export const listCasesAndLeads = (lead_list = true, case_id = '', exclude_pi_case = false) => {
//   const [tenantId, tenantSk] = getTenantDetails();
//   let url = `${apiEndPoints.listCasesAndLeads}?tenant_id=${tenantId ? tenantId : ''}&tenant_sk=${
//     tenantSk ? tenantSk : ''
//   }&lead_list=${lead_list}${case_id ? `&case_id=${case_id}` : ''}${exclude_pi_case ? `&exclude_pi_case=${exclude_pi_case}` : ''}`;
//   return performRequestThird(apiEndPoints?.methodType?.GET, url);
// };

export const listCasesAndLeads = (
  lead_list = true,
  case_id = '',
  exclude_pi_case = false,
  keyword = '',
  limit,
  is_filter_dropdown = false
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `${apiEndPoints.listCasesAndLeads}?tenant_id=${tenantId ? tenantId : ''}&tenant_sk=${
    tenantSk ? tenantSk : ''
  }&lead_list=${lead_list}${case_id ? `&case_id=${case_id}` : ''}${exclude_pi_case ? `&exclude_pi_case=${exclude_pi_case}` : ''}${
    keyword ? `&keyword=${keyword}` : ''
  }${limit ? `&limit=${limit}` : ''}${is_filter_dropdown ? `&is_filter_dropdown=${is_filter_dropdown}` : ''}`;
  return performRequestThird(apiEndPoints?.methodType?.GET, url);
};
