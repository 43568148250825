import React, { useContext } from 'react';
import FlatFeeBillingDetails from './FlatFeeBillingDetails/FlatFeeBillingDetails';
import EverGreenStandardRetainerBillingDetails from './EverGreenStandardRetainerBillingDetails/EverGreenStandardRetainerBillingDetails';
import SubscriptionBillingDetails from './SubscriptionBillingDetails/SubscriptionBillingDetails';
import ContigencyFeeBillingDetails from './ContigencyFeeBillingDetails/ContigencyFeeBillingDetails';
import TimeEntries from './TimeEntries/TimeEntries';
import Expenses from './Expenses/Expenses';
import Adjustments from './Adjustments/Adjustments';
import { UserDetailsContext } from 'context/userDetailsContext';
import EnableTimeEntriesToggleSwitch from './EnableTimeEntriesToggleSwitch/EnableTimeEntriesToggleSwitch';
import MarkAllAsBillableToggleSwitch from './MarkAllAsBillableToggleSwitch/MarkAllAsBillableToggleSwitch';

const BillingDetails = (props) => {
  const userContext = useContext(UserDetailsContext);

  const {
    isFlatFee,
    isSubscription,
    isContigencyFee,
    isEverGreenRetainer,
    isStandardRetainer,
    isPersonalInjuryPracticeArea,
    caseDetails,
    invoiceDetails,

    flatFees,
    isChanged,
    teamMembers,
    flatFeeActualTotal,
    flatFeeTotal,
    settlementAmount,
    setSettlementAmount,
    setFormErrors,
    litigation,
    setLitigation,
    contingencyItems,
    showTimeEntry,
    timeEntires,
    activityTypes,
    expenses,
    rateWithTax,
    expenseTotal,
    subTotalAmount,
    billsAndExpenseTotal,
    expenseTypes,
    expenseError,
    adjustmentError,
    adjustments,
    flatFeeError,
    timeEntiresError,
    adjustmentTotal,
    discount,
    settlementDetails,
    subscriptionItems,
    addExpense,
    addFlatFee,
    onChangeFlatFeeDate,
    setIsChanged,
    setFlatFees,
    deleteFlatFee,
    setShowTimeEntry,
    setTimeEntriesError,
    setTimeEntries,
    addTimeEntry,
    setExpenses,
    setExpenseError,
    deleteExpense,
    addAdjustment,
    setAdjustments,
    setAdjustmentError,
    setFlatFeeError,
    deleteTimeEntry,
    deleteAdjustment,
    setCaseDetails,
    formErrors,
    totalHours,
    timeEntriesTotal,
    selectedCase,
  } = props || {};
  return (
    <div>
      {!isSubscription && (
        <>
          {/* showe time entries and mark as billable buttons */}
          <div className="mt-4">
            <div className="d-flex align-items-center justify-content-between  mb-2">
              <EnableTimeEntriesToggleSwitch
                showTimeEntry={showTimeEntry}
                setShowTimeEntry={setShowTimeEntry}
                setTimeEntriesError={setTimeEntriesError}
              />
              <MarkAllAsBillableToggleSwitch
                timeEntires={timeEntires}
                setTimeEntries={setTimeEntries}
                setTimeEntriesError={setTimeEntriesError}
              />
            </div>

            <div className="shadow-small">
              <div className="row mt-3 ">
                {/* time entry table */}
                <div className="col-12 datatable-responsive">
                  {showTimeEntry && (
                    <TimeEntries
                      activityTypes={activityTypes}
                      caseDetails={caseDetails}
                      timeEntires={timeEntires}
                      userContext={userContext}
                      addTimeEntry={addTimeEntry}
                      timeEntiresError={timeEntiresError}
                      setTimeEntries={setTimeEntries}
                      setTimeEntriesError={setTimeEntriesError}
                      isChanged={isChanged}
                      setIsChanged={setIsChanged}
                      deleteTimeEntry={deleteTimeEntry}
                      isEverGreenRetainer={isEverGreenRetainer}
                      isStandardRetainer={isStandardRetainer}
                      teamMembers={teamMembers}
                      totalHours={totalHours}
                      timeEntriesTotal={timeEntriesTotal}
                      rateWithTax={rateWithTax}
                      selectedCase={selectedCase}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* expenses */}
          <Expenses
            isContigencyFee={isContigencyFee}
            expenses={expenses}
            setExpenses={setExpenses}
            expenseTypes={expenseTypes}
            expenseError={expenseError}
            setExpenseError={setExpenseError}
            isChanged={isChanged}
            setIsChanged={setIsChanged}
            addExpense={addExpense}
            teamMembers={teamMembers}
            deleteExpense={deleteExpense}
            expenseTotal={expenseTotal}
            caseDetails={caseDetails}
          />
          {!isContigencyFee && (
            <>
              {/* adjustments */}
              <Adjustments
                isFlatFee={isFlatFee}
                adjustments={adjustments}
                addAdjustment={addAdjustment}
                adjustmentError={adjustmentError}
                setAdjustments={setAdjustments}
                setAdjustmentError={setAdjustmentError}
                isChanged={isChanged}
                setIsChanged={setIsChanged}
                deleteAdjustment={deleteAdjustment}
                adjustmentTotal={adjustmentTotal}
                discount={discount}
                flatFeeTotal={flatFeeTotal}
                rateWithTax={rateWithTax}
                expenseTotal={expenseTotal}
                subTotalAmount={subTotalAmount}
              />
            </>
          )}
          {/* TODO:This section moved under contigency fee section */}
          {/* {isContigencyFee && isPersonalInjuryPracticeArea && (
            <>
              <div className="mt-4">
                      <div className="caption-bold mb-3">Case Expenses</div>
                      <div className="shadow-small">
                        <div className="row mt-3">
                          <div className="col-12 datatable-responsive">
                            <DataTable
                              className="p-datatable-responsive"
                              value={settlementDetails[0]?.case_expense_per_vendor ?? []}
                              emptyMessage={() => <div onClick={addExpense}>No records found</div>}
                            >
                              <Column
                                header="Vendor"
                                body={(rowData, props) => {
                                  return (
                                    <>
                                      <span className="p-column-title text-break">Vendor</span>
                                      <span className="text-break">{rowData?.case_vendor?.vendor?.title ?? ''}</span>
                                    </>
                                  );
                                }}
                                footer={<div>Total</div>}
                              />
                              <Column
                                header="Amount"
                                body={(rowData, props) => {
                                  return (
                                    <>
                                      <span className="p-column-title text-break">Amount</span>
                                      <span className="text-break">
                                        {formatNumbersToCurrencyString(rowData?.case_vendor?.bill_amount) ?? ''}
                                      </span>
                                    </>
                                  );
                                }}
                                footer={formatNumbersToCurrencyString(settlementDetails[0]?.total_case_expense ?? 0)}
                              />
                            </DataTable>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <div className="caption-bold mb-3">Medical Bills</div>
                      <div className="shadow-small">
                        <div className="row mt-3">
                          <div className="col-12 datatable-responsive">
                            <DataTable
                              className="p-datatable-responsive"
                              value={settlementDetails[0]?.medical_expense_per_provider ?? []}
                              emptyMessage={() => <div onClick={addExpense}>No records found</div>}
                            >
                              <Column
                                header="Provider"
                                body={(rowData, props) => {
                                  return (
                                    <>
                                      <span className="p-column-title text-break">Provider</span>
                                      <span className="text-break">{rowData?.medical_provider?.provider?.title ?? ''}</span>
                                    </>
                                  );
                                }}
                                footer={<div>Total</div>}
                              />
                              <Column
                                header="Amount"
                                body={(rowData, props) => {
                                  return (
                                    <>
                                      <span className="p-column-title text-break">Amount</span>
                                      <span className="text-break">
                                        {formatNumbersToCurrencyString(rowData?.medical_provider?.bill_amount) ?? ''}
                                      </span>
                                    </>
                                  );
                                }}
                                footer={formatNumbersToCurrencyString(settlementDetails[0]?.total_bill ?? 0)}
                              />
                            </DataTable>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 col-12 col-sm-4">
                      <div className="shadow-small p-3 align-self-start">
                        <div className="d-flex align-items-center py-2">
                          <div className="col-5">Total Bills And Expenses:</div>
                          <div className="col-7 d-flex justify-content-end text-bold">
                            {formatNumbersToCurrencyString(billsAndExpenseTotal)}
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="mt-4">
                      <div className="caption-bold mb-3">Settlement</div>
                      <div className="shadow-small">
                        <div className="row mt-3">
                          <div className="col-12 datatable-responsive">
                            <DataTable
                              className="p-datatable-responsive"
                              value={settlementDetails}
                              emptyMessage={() => <div onClick={addExpense}>No records found</div>}
                            >
                              <Column
                                header=" Medical Bill Balance"
                                body={(rowData, props) => {
                                  return (
                                    <>
                                      <span className="p-column-title text-break">Medical Bill Balance</span>
                                      <span className="text-break">
                                        {rowData.balance_owed ? formatNumbersToCurrencyString(rowData.balance_owed) : ''}
                                      </span>
                                    </>
                                  );
                                }}
                              />
                              <Column
                                header="Total Legal Fee"
                                body={(rowData, props) => {
                                  return (
                                    <>
                                      <span className="p-column-title text-break">Total Legal Fee</span>
                                      <span className="text-break">
                                        {rowData.attorney_fee_amount ? formatNumbersToCurrencyString(rowData.attorney_fee_amount) : ''}
                                      </span>
                                    </>
                                  );
                                }}
                              />
                              <Column
                                header="Total Case Expenses"
                                body={(rowData) => {
                                  return (
                                    <>
                                      <span className="p-column-title text-break">Total Case Expenses</span>
                                      <span className="text-break">
                                        {rowData.total_case_expense ? formatNumbersToCurrencyString(rowData.total_case_expense) : ''}
                                      </span>
                                    </>
                                  );
                                }}
                              />
                            </DataTable>
                          </div>
                        </div>
                      </div>
                    </div>
            </>
          )} */}
        </>
      )}

      {/* flat fee */}
      {isFlatFee && (parseFloat(caseDetails?.billing_preferences?.due) !== 0 || invoiceDetails?.flat_fees) && (
        <FlatFeeBillingDetails
          caseDetails={caseDetails}
          invoiceDetails={invoiceDetails}
          addFlatFee={addFlatFee}
          flatFees={flatFees}
          flatFeeError={flatFeeError}
          onChangeFlatFeeDate={onChangeFlatFeeDate}
          flatFeeActualTotal={flatFeeActualTotal}
          flatFeeTotal={flatFeeTotal}
          setIsChanged={setIsChanged}
          isChanged={isChanged}
          teamMembers={teamMembers}
          setFlatFeeError={setFlatFeeError}
          setFlatFees={setFlatFees}
          deleteFlatFee={deleteFlatFee}
        />
      )}

      {/* evergreen and standard retainer */}
      {(isEverGreenRetainer || isStandardRetainer) && (
        <EverGreenStandardRetainerBillingDetails caseDetails={caseDetails} setCaseDetails={setCaseDetails} />
      )}

      {/* subscription */}
      {isSubscription && (
        <SubscriptionBillingDetails caseDetails={caseDetails} formErrors={formErrors} subscriptionItems={subscriptionItems} />
      )}

      {/* contigency fee  */}
      {isContigencyFee && (
        <ContigencyFeeBillingDetails
          settlementAmount={settlementAmount}
          setSettlementAmount={setSettlementAmount}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          isChanged={isChanged}
          setIsChanged={setIsChanged}
          isPersonalInjuryPracticeArea={isPersonalInjuryPracticeArea}
          litigation={litigation}
          setLitigation={setLitigation}
          contingencyItems={contingencyItems}
          settlementDetails={settlementDetails}
          addExpense={addExpense}
          billsAndExpenseTotal={billsAndExpenseTotal}
        />
      )}
    </div>
  );
};

export default BillingDetails;
