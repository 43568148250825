/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';

import ImageComponent from 'shared/ImageComponent';
import NotesCreate from './NotesCreate.jsx';
import constants from 'constants/index';
import { UserDetailsContext } from 'context/userDetailsContext';
import { handleRedirection } from 'utils/utils';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { getLocalStorageParseItem, removeLocalStorageItem } from 'utils/localStorageUtils';

import { ReactComponent as OutgoingIcon } from 'assets/svg/outgoing_call.svg';
import { ReactComponent as IncomingIcon } from 'assets/svg/incoming_call.svg';
import { ReactComponent as FilesIcon } from 'assets/svg/files.svg';
import { useCaseNotes } from '../hooks/useCaseNotes.jsx';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader.jsx';
import Filter from './Filter.jsx';
import { useCaseListFilter as useFilter } from '../hooks/useCaseListFilter.jsx';
import useIsInitialRender from 'hooks/useIsInitialRender.jsx';

const Notes = ({
  isLeadSpecific,
  caseDetails,
  isFormDashboard,
  isFromCaseSpecificModule,
  isLeadClosed,
  onMountUnMount,
  onOperationBreak,
}) => {
  const { case_id } = caseDetails || {};
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { userDetails } = useContext(UserDetailsContext);
  const isInitialRender = useIsInitialRender();

  const { filterDebounced, filter, filterOnChange } = useFilter({});
  const { caseNoteList, paginatorTemplate, loadCaseNoteList } = useCaseNotes(
    { ...filter, case_id: case_id || id },
    undefined,
    isLeadSpecific,
    isFromCaseSpecificModule
  );

  const [show, setShow] = useState(false);
  const [isGlobal, setIsGlobal] = useState(location?.state?.isGlobal);

  useEffect(() => {
    if (location?.state?.isGlobal) {
      setShow(true);
      setIsGlobal(location?.state?.isGlobal);
    }
  }, [location?.state?.isGlobal]);

  useEffect(() => {
    if (isInitialRender) {
      loadCaseNoteList({
        pagination_direction: localStorage.getItem('pagination_direction_notes') || '',
        pagination_token: localStorage.getItem('pagination_token_notes') || '',
      });
    } else {
      loadCaseNoteList({});
    }
  }, [filterDebounced]);

  useEffect(() => {
    const caseNoteDetails = location?.state?.caseNoteData || getLocalStorageParseItem('stateData')?.caseNoteData;
    if (caseNoteDetails) {
      removeLocalStorageItem('stateData');
    }
  }, [location, isFormDashboard]);

  const isCaseClosed = useMemo(() => {
    if (isFromCaseSpecificModule && caseDetails?.sk && caseDetails?.pk) {
      return caseDetails?.status === 'INACTIVE';
    } else if (caseDetails?.is_lead) {
      return !caseDetails?.is_open;
    }
    return false;
  }, [caseDetails, isFromCaseSpecificModule]);

  const titleTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Title</span>
        <div className="row d-inline-flex">
          <div className="d-flex">
            <span>
              {rowData?.note_type === 'case_note' ? (
                <FilesIcon width={18} height={18} className="me-2" />
              ) : rowData?.note_type === 'call_log' && rowData?.call_type === 'outgoing' ? (
                <OutgoingIcon width={18} height={18} className="me-2" />
              ) : rowData?.note_type === 'call_log' && rowData?.call_type === 'incoming' ? (
                <IncomingIcon width={18} height={18} className="me-2" />
              ) : null}
            </span>
            <span>
              {rowData?.title ? (
                <>
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      const url = `/${caseDetails?.is_lead ? 'leads' : 'cases'}/${caseDetails?.case_id}/notes/${rowData?.case_note_id}`;
                      handleRedirection(history, url, rowData);
                    }}
                    className="text-decoration-none "
                  >
                    <span className="redirection-link text-bold">{rowData.title}</span>
                  </a>
                  {rowData.note_type === 'call_log' ? (
                    <>
                      {rowData?.call_type === 'incoming' && (
                        <p className="text-hint text-nowrap">
                          Call From:
                          <span
                            onClick={() => {
                              const url = `/contacts/${rowData?.caller_name?.client_id}`;
                              handleRedirection(history, url);
                            }}
                          >
                            <Tag
                              className="p-mr-1 tag-case ms-1 break-spaces"
                              value={rowData?.caller_name?.client_name ? rowData?.caller_name?.client_name : '-'}
                              rounded
                            />
                          </span>
                        </p>
                      )}

                      {rowData?.call_type === 'outgoing' && (
                        <p className="text-hint text-nowrap">
                          Called To:
                          <span
                            onClick={() => {
                              const url = `/contacts/${rowData?.person_called?.client_id}`;
                              handleRedirection(history, url);
                            }}
                          >
                            <Tag
                              className="p-mr-1 tag-case ms-1 break-spaces"
                              value={rowData?.person_called?.client_name ? rowData?.person_called?.client_name : '-'}
                              rounded
                            />
                          </span>
                        </p>
                      )}
                    </>
                  ) : null}
                </>
              ) : null}
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const statusTemplate = (rowData) => {
    let statusTag;
    if (rowData?.resolution_status && !rowData?.resolved_status) {
      statusTag = (
        <div className="blue-tag">
          <p className="blue-tag-value text-nowrap">Needs Resolution</p>
        </div>
      );
    } else if (rowData?.resolution_status && rowData?.resolved_status) {
      statusTag = (
        <div className="green-tag">
          <p className="green-tag-value text-nowrap">Resolved</p>
        </div>
      );
    }
    return (
      <>
        <span className="p-column-title text-break">Status</span>
        {statusTag}
      </>
    );
  };

  const createdTemplate = (rowData) => {
    const name = rowData?.created_by_name?.length
      ? `${rowData?.created_by_name.at(-1)['first_name']} ${rowData?.created_by_name.at(-1)['last_name']}`
      : '-';
    const url = rowData?.created_by_firm_user_id
      ? `/settings/team-management/${rowData?.created_by_firm_user_id}`
      : '/settings/team-management';
    return (
      <>
        <span className="p-column-title text-break">Created</span>
        <span className="text-break d-inline-table">
          <span className="text-bold">
            {handleDateTimeOffset(userDetails.timezone, rowData?.time, constants.month_date_year_time_12_format)}
          </span>
          <p
            className={classNames('text-muted small tbl-avatar d-flex align-items-center', {
              'cursor-pointer': rowData?.created_by_name?.length,
            })}
            onClick={() => handleRedirection(history, url)}
          >
            {name}
          </p>
        </span>
      </>
    );
  };

  const updatedTemplate = (rowData) => {
    const name = rowData?.update_by_name?.length
      ? `${rowData?.update_by_name.at(-1)['first_name']} ${rowData?.update_by_name.at(-1)['last_name']}`
      : '-';
    const filePath = rowData?.update_by_name?.length ? rowData.update_by_name.at(-1)['profile_image'] : '';
    const url = rowData?.updated_by_firm_user_id
      ? `/settings/team-management/${rowData?.updated_by_firm_user_id}`
      : '/settings/team-management';
    return (
      <>
        <span className="p-column-title text-break">Updated</span>
        <span className="text-break d-inline-table">
          <span className="text-bold">
            {handleDateTimeOffset(userDetails.timezone, rowData?.last_updated_time, constants.month_date_year_time_12_format)}
          </span>
          <p
            className={classNames('text-muted small tbl-avatar d-flex align-items-center', {
              'cursor-pointer': rowData?.update_by_name?.length,
            })}
            onClick={() => handleRedirection(history, url)}
          >
            {filePath && <ImageComponent filePath={filePath} fileName={name} fileSize="medium.jpg" avatarSize="medium" />}
            {name}
          </p>
        </span>
      </>
    );
  };

  return (
    <>
      <GlobalLoader />
      {show && (
        <NotesCreate
          show={show}
          setShow={setShow}
          fetchCaseNotes={loadCaseNoteList}
          isLeadSpecific={isLeadSpecific}
          caseDetails={caseDetails}
          isFromCaseSpecificModule={isFromCaseSpecificModule}
          isFromDashboard={isGlobal}
          setIsGlobal={setIsGlobal}
          onMountUnMount={onMountUnMount}
          onOperationBreak={onOperationBreak}
        />
      )}
      <Filter {...{ filterOnChange, filter, isCaseClosed, isLeadClosed, isLeadSpecific, setShow }} />
      <div className="row">
        <div className="col-12">
          <div className="p-2 datatable-responsive">
            <DataTable
              value={caseNoteList}
              responsiveLayout="scroll"
              className="p-datatable-responsive"
              emptyMessage="No Data Found."
              paginator
              paginatorTemplate={paginatorTemplate}
            >
              <Column field="title" header="Title" body={titleTemplate} className="notes-title" sortable />
              <Column header="Status" className="notes-status" body={statusTemplate} />
              <Column field="created" header="Created" body={createdTemplate} />
              <Column field="updated" header="Updated" body={updatedTemplate} />
            </DataTable>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notes;
