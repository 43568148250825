import { useState, createContext, useContext, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { totalCountEvent } from 'services/generalServices';
import { stageUpdate } from 'services/TaskServices';
import { useToggle } from 'hooks/useToggle';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import {
  createTaskStages,
  createTasks,
  deleteTask,
  deleteTaskStage,
  getTaskList,
  getTaskStages,
  updateTaskDetails,
  updateTaskStage,
} from 'services/CaseServices';
import { updateCaseStagePosition as updateTaskStagePosition } from 'services/Settings';
import { getLocalStorageParseItem, removeLocalStorageItem } from 'utils/localStorageUtils';
import { convertToTimezoneFormat } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';

const TaskContext = createContext(() => {});
export const useTaskContext = () => useContext(TaskContext);

const DashboardDataHandler = (props) => {
  const { isFromCase, caseDetails, isFromLead, isLeadClosed, isCaseClosed } = props;

  const isClosed = isLeadClosed || isCaseClosed;

  const { addToast } = useToast();
  const location = useLocation();
  const userContext = useContext(UserDetailsContext);

  const [addTaskToggle, setAddTaskToggle] = useToggle(Boolean(location?.state?.openTask));
  const [manageColumnToggle, setManageColumnToggle] = useToggle(false);

  const [totalCount, setTotalCount] = useState(null);
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const [selectedTask, setSelectedTask] = useState();
  const [taskStageState, setTaskStageState] = useState([]);
  const [selectedStageId, setSelectedStageId] = useState(null);
  const [taskData, setTaskData] = useState({});
  const [filterData, setFilterData] = useState({
    case: userContext?.firmDetails?.user_preferences?.filters?.task?.case || '',
    priority: userContext?.firmDetails?.user_preferences?.filters?.task?.priority || '',
    date: userContext?.firmDetails?.user_preferences?.filters?.task?.date || '3-months',
  });

  const loadTaskList = () => {
    //Load task list
    totalCountEvent('task', caseDetails?.case_id).then((response) => {
      setTotalCount(response.data);
      loadTaskData({ pageLimit: response.data });
    });
  };

  const loadData = () => {
    loadTaskStages();
    totalCountEvent('task', caseDetails?.case_id).then((response) => {
      setTotalCount(response.data);
    });
  };

  const formatEndDate = (value) => {
    if (value === 'past-due') {
      return convertToTimezoneFormat(moment().endOf('day'), userContext.userDetails.timezone);
    } else if (value === '3-months') {
      return convertToTimezoneFormat(moment().add(90, 'days').endOf('day'), userContext.userDetails.timezone);
    } else {
      return '';
    }
  };
  const loadTaskData = ({
    caseId = filterData?.case_id?.id || caseDetails?.case_id,
    startDate = filterData?.date === '3-months'
      ? convertToTimezoneFormat(moment().subtract(90, 'days').startOf('day'), userContext.userDetails.timezone)
      : undefined,
    end_date = formatEndDate(filterData?.date),
    priority = filterData?.priority,
    isSelf,
    assigneeId,
    status,
    data = { firm_user: [], all_users: true },
    pageLimit = totalCount,
  }) => {
    getTaskList(caseId, startDate, end_date, priority, isSelf, assigneeId, status, data, pageLimit)
      .then((response) => setTaskData(response.data))
      .catch((error) => console.error(error));
  };

  // Load Task Stages
  const loadTaskStages = () => {
    getTaskStages()
      .then((response) => setTaskStageState(response.data))
      .catch((error) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, error.message || error);
      });
  };

  const createStage = (data) => {
    createTaskStages(data)
      .then(() => {
        loadTaskStages();
        loadTaskData({});
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.STAGE_CREATED_SUCCESSFULLY);
      })
      .catch((e) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, e.response.data);
      });
  };

  const handleTaskComplete = (data) => {
    if (data?.sub_task?.length === 0) {
      stageUpdate(data.sk, 'done')
        .then(() => {
          loadTaskData({});
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (data?.sub_task?.length > 0) {
      handleSubTaskCheckBox(
        true,
        data.sub_task.map((item) => item?.sk),
        data
      );
    }
  };

  const handleCreateTasks = (payload) => {
    createTasks(payload)
      .then(() => {
        loadTaskList();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TASK_UPDATED_SUCCESSFULLY);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TASK_UPDATED_FAILED);
      });
  };

  const updateTask = (task_id, data) => {
    updateTaskDetails(task_id, null, data)
      .then(() => {
        loadTaskData({});
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TASK_UPDATED_SUCCESSFULLY);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TASK_UPDATED_FAILED);
      });
  };

  const handleDeleteTask = (task) => {
    deleteTask(task.case_id, task.sk, task.pk).then(() => {
      addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TASK_DELETED_SUCCESSFULLY);
      loadTaskList();
    });
  };

  const handleSubTaskCheckBox = (checkValue, sub_sk, taskData) => {
    try {
      let subSk = [];
      if (Array.isArray(sub_sk)) {
        subSk = [...sub_sk];
      } else subSk = [sub_sk];
      let data = JSON.parse(JSON.stringify(taskData));
      data.old_task_list = [...taskData.sub_task];
      data.sub_task.forEach((val) => {
        if (subSk.includes(val.sk)) {
          val.sub_task_status = checkValue;
        }
      });
      data?.old_task_list.forEach((item) => {
        item.sk = 'random';
      });

      //update the task to done stage when all the subtasks are done
      let totoalSubTaskCompleted = data?.sub_task?.filter((item) => item?.sub_task_status === true);
      if (totoalSubTaskCompleted?.length === 0) {
        data = {
          ...data,
          task_stage: data?.dynamic_task_stage || 'to_do',
        };
      } else if (totoalSubTaskCompleted?.length < data?.sub_task?.length) {
        data = { ...data, task_stage: 'in_progress' };
      } else if (totoalSubTaskCompleted?.length === data?.sub_task?.length) {
        data = { ...data, task_stage: 'done' };
      }

      updateTaskDetails(taskData?.task_id, null, data)
        .then(() => {
          loadTaskData({});
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.TASK_UPDATED_SUCCESSFULLY);
        })
        .catch(() => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TASK_UPDATED_FAILED);
        });
    } catch (err) {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.TASK_UPDATED_FAILED);
    }
  };

  const updateStagePosition = (columnOrder) => {
    let stageData = Array.isArray(taskStageState) && taskStageState;
    if (stageData) {
      const updatedStage = stageData.map((item) => {
        if (item.task_stage_name !== 'done') {
          const index = columnOrder.indexOf(item.task_stage_name);
          return { ...item, position: index + 1 };
        }
        return item;
      });
      updateTaskStagePosition({ updated_positions: updatedStage }).catch(() => {
        loadTaskStages();
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.STAGE_UPDATED_FAILED);
      });
    }
  };

  const updateStageData = (id, data) => {
    let stageData = Array.isArray(taskStageState) && taskStageState?.find((v) => v.task_stage_name === id);
    if (stageData) {
      updateTaskStage(stageData?.pk, stageData?.sk, { ...stageData, ...data })
        .then(() => {
          loadTaskStages();
          loadTaskData({});
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.STAGE_UPDATED_SUCCESSFULLY);
        })
        .catch(() => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.STAGE_UPDATED_FAILED);
        });
    }
  };

  const deleteStage = (id) => {
    let stageData = Array.isArray(taskStageState) && taskStageState?.find((v) => v.task_stage_name === id);
    if (stageData) {
      deleteTaskStage(stageData?.pk, stageData?.sk)
        .then(() => {
          loadTaskData({});
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.STAGE_DELETE_SUCCESSFULLY);
        })
        .catch(() => addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.STAGE_DELETE_FAILED));
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setAddTaskToggle(Boolean(location?.state?.openTask));
  }, [location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const task_id = queryParams.get('id');
    let selectedTaskData =
      getLocalStorageParseItem('stateData')?.viewTask || location?.state?.viewTask || (task_id ? { task_id, assign_to: [] } : null);
    if (selectedTaskData) {
      setSelectedTask(selectedTaskData);
      removeLocalStorageItem('stateData');
    }
  }, [window?.localStorage, location]); // for global search result on new tab

  const contextPayload = useMemo(
    () => ({
      userContext,
      taskData,
      totalCount,
      searchKeyWord,
      addTaskToggle,
      setAddTaskToggle,
      manageColumnToggle,
      setManageColumnToggle,
      selectedTask,
      taskStageState,
      selectedStageId,
      isFromCase,
      isFromLead,
      isClosed,
      caseDetails,
      filterData,
      setFilterData,
      loadTaskData,
      setSelectedStageId,
      createStage,
      handleDeleteTask,
      setSelectedTask,
      deleteStage,
      updateStageData,
      updateStagePosition,
      handleSubTaskCheckBox,
      updateTask,
      setSearchKeyWord,
      handleTaskComplete,
      loadTaskList,
      handleCreateTasks,
    }),
    [
      userContext,
      isFromCase,
      caseDetails,
      taskData,
      taskStageState,
      totalCount,
      searchKeyWord,
      addTaskToggle,
      manageColumnToggle,
      selectedStageId,
      isFromLead,
      isClosed,
      filterData,
      updateStagePosition,
      deleteStage,
      updateStageData,
    ] //eslint-disable-line
  );
  return <TaskContext.Provider value={contextPayload} {...props} />;
};

export default DashboardDataHandler;
