import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from './components/Header/Header';
import SelectNumber from './components/NumberSelection/NumberSelect';
import ThreadList from './components/ThreadList/ThreadList';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import MessageConatiner from './components/MessageContainer/MessageContainer';

import { useUserDetailsContext } from 'context/userDetailsContext';
import { totalCountEvent } from 'services/generalServices';
import { listCaseContacts, listTextMessages } from 'services/textMessageService';
// import { markNotification } from 'services/notification/notificationService';
import { getLocalStorageParseItem, removeLocalStorageItem } from 'utils/localStorageUtils';
import { useWebSocketContext } from 'context/WebSocketConnectionContext';
import { reorderList } from 'utils/utils';

export default function TextMessages({ isFromCase, caseDetails, isFromLead, leadDetails, onMountUnMount, onOperationBreak }) {
  const location = useLocation();
  const userContext = useUserDetailsContext();
  const websocket = useWebSocketContext();

  const [contactsOption, setContactsOption] = useState([]);
  const [messageThreads, setMessageThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState('');
  const [selectedContact, setSelectedContact] = useState(undefined);
  const [newThreadActive, setNewThreadActive] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const isClosed = caseDetails?.status === 'INACTIVE' || leadDetails?.case_closed;

  const fetchTextMessages = async (caseId = caseDetails?.case_id, contactId = false, last_message_date = false) => {
    let totalCounts = await totalCountEvent('text_message_thread');
    setTotalCount(totalCounts.data);
    listTextMessages(caseId, contactId, last_message_date, totalCounts.data)
      .then((response) => {
        if (response?.data?.text_message_threads?.length) {
          let redirectedData = location?.state?.text_message
            ? location?.state?.text_message
            : getLocalStorageParseItem('stateData')?.text_message;
          let filteredData = response?.data?.text_message_threads?.filter((data) =>
            data?.contact_id === redirectedData?.contact_id && data?.to === redirectedData?.to ? redirectedData?.to : redirectedData?.To
          );
          if (redirectedData && filteredData?.length > 0) {
            handleSelectThread(filteredData[0]);
            removeLocalStorageItem('stateData');
          } else {
            selectedContact && handleSelectThread(response.data.text_message_threads?.[0]);
          }
          setMessageThreads(response.data.text_message_threads);
        } else {
          if (selectedContact) {
            handleSelectThread({
              client_name: { first_name: selectedContact?.first_name, last_name: selectedContact?.last_name },
              contact_id: selectedContact?.contact_id,
              to: selectedContact?.phone,
              phone: selectedContact?.phone,
              from_: userContext?.firmPhoneNumber,
              is_active: true,
              latest_phone_number: selectedContact?.phone,
            });
          }
          setMessageThreads([]);
        }
      })
      .catch((error) => console.log(error));
  };

  const fetchCaseUsers = (case_ID = false) => {
    listCaseContacts()
      .then((response) => {
        let responseData = JSON.parse(JSON.stringify(response.data));
        let allContactsData = [];
        for (let i in responseData) {
          allContactsData.push(...responseData[i]);
        }
        let resData = case_ID ? allContactsData.filter((val) => val.case_id === case_ID) : allContactsData;

        let options = [];
        resData.forEach((user) => {
          if (user.contact_id && !options.find((val) => val.contact_id === user.contact_id)) {
            options.push(user);
          }
        });
        setContactsOption(options);
      })
      .catch((error) => console.log(error));
  };

  function navigateToLatest(contact_id) {
    let currentContactThreads = messageThreads?.filter((t) => t.contact_id === contact_id);
    let latestThread = currentContactThreads?.find((c) => c.is_active === true);
    handleSelectThread(latestThread);
  }

  const handleSelectThread = (thread) => setSelectedThread(thread);

  useEffect(() => {
    if (messageThreads?.length > 0) {
      let currentContactThreads = messageThreads?.filter((t) => t.contact_id === selectedThread?.contact_id);
      let checkThreadActive = currentContactThreads?.some((c) => c.is_active === true);
      setNewThreadActive(checkThreadActive);
    } else {
      setNewThreadActive(true);
    }
  }, [selectedThread, messageThreads]);

  useEffect(() => {
    if (!isFromCase) {
      fetchCaseUsers();
    }
  }, []);

  useEffect(() => {
    //Update Side thread list on websocket
    if (websocket?.textMessageData?.body?.from) {
      setMessageThreads((pre) => {
        let updatedList = pre.map((item) => {
          if (item?.from_ === websocket?.textMessageData?.body?.from || item?.to === websocket?.textMessageData.body?.from) {
            return {
              ...item,
              last_message: websocket?.textMessageData?.body?.message_body,
              last_author:
                websocket?.textMessageData?.notification_type === 'inbound_text_message'
                  ? 'client'
                  : websocket?.textMessageData?.body?.client_name,
              last_updated: Date.now(),
              unread_reply_count: 1,
            }; // Create a new object with updated name
          }
          return item; // Return the original item if ID doesn't match
        });
        const threadIndex = pre?.findIndex(
          (item) => item?.from_ === websocket?.textMessageData?.body?.from || item?.to === websocket?.textMessageData.body?.from
        );
        if (threadIndex > -1) {
          return reorderList(updatedList, threadIndex, 0);
        } else return updatedList;
      });
    }

    // delivery status Update Logic
    if (websocket?.textMessageData?.body?.SmsSid) {
      setMessageThreads((pre) => {
        return pre.map((item) => {
          if (item?.message_id === websocket?.textMessageData?.body?.SmsSid) {
            return { ...item, last_message_status: websocket?.textMessageData?.body?.SmsStatus }; // Create a new object with updated name
          }
          return item; // Return the original item if ID doesn't match
        });
      });
    }
  }, [websocket]);

  return (
    <div className="text-message-wrap px-2">
      <GlobalLoader />
      <Header {...{ isFromCase, totalCount, isFromLead }} />
      {userContext?.textMessageStatus === 'INACTIVE' ? (
        <SelectNumber />
      ) : (
        <div className="row">
          <div className="col-lg-4 col-12">
            <ThreadList
              {...{
                isFromCase,
                caseDetails,
                isFromLead,
                leadDetails,
                selectedContact,
                setSelectedContact,
                contactsOption,
                messageThreads,
                selectedThread,
                handleSelectThread,
                fetchTextMessages,
              }}
            />
          </div>
          <div className="col-lg-8 col-12">
            {Boolean(selectedThread) ? (
              <MessageConatiner
                {...{
                  isClosed,
                  setNewThreadActive,
                  handleSelectThread,
                  isFromCase,
                  caseDetails,
                  navigateToLatest,
                  selectedThread,
                  newThreadActive,
                  setMessageThreads,
                  onMountUnMount,
                  onOperationBreak,
                }}
              />
            ) : (
              <div className="shadow-middle p-3 my-3 h-100 text-message-height">
                <div className="d-flex flex-column align-items-center justify-content-center h-100 F-size16 start-conversation">
                  <i className="icon-chat" />
                  Select a Conversation
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
