import { useUserDetailsContext } from 'context/userDetailsContext';
import { useState } from 'react';
import { getFreeTime } from 'services/Calender/calendarServices';
import { convertToGroupedDates } from 'utils/utils';

const useEventFreeTime = () => {
  const { userDetails } = useUserDetailsContext();
  const [eventFreeTime, setEventFreeTime] = useState([]);
  const [loading, setloading] = useState(false);

  const loadFreeTime = async (data) => {
    setloading(true);
    return getFreeTime(data)
      .then((response) => {
        setEventFreeTime(
          convertToGroupedDates(
            response?.data?.map((item) => item?.[0]),
            userDetails?.timezone
          )
        );
      })
      .catch((error) => {
        console.log('error', error);
      })
      .finally(() => {
        setloading(false);
      });
  };

  return { eventFreeTime, loadFreeTime, loading };
};

export default useEventFreeTime;
