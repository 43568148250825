import { getFile } from 'modules/templates/services';
import { useState } from 'react';

const useExistingFiles = () => {
  const [existingFiles, setExistingFiles] = useState([]);

  const getExistingFiles = (params) => {
    getFile(params)
      .then(async (response) => {
        let arr = response?.data.data;
        const filesData = arr
          ?.filter((data) => data.entry_type === 'file')
          .map((item) => {
            return {
              ...item,
              label: item.display_name,
              value: {
                file_path: item.file_path,
                size: item.size,
                display_name: item.display_name,
                file_name: item.file_name,
                file_type: item.file_type,
                created_by: item.created_by_details,
                is_new_file: true,
              },
            };
          });
        setExistingFiles(filesData);
      })
      .catch((error) => {});
  };

  return { existingFiles, getExistingFiles };
};

export default useExistingFiles;
