const apiEndPoints = {
  methodType: {
    PUT: 'put',
    GET: 'get',
    POST: 'post',
    DELETE: 'delete',
    PATCH: 'patch',
  },
  verifyUser: '/user-verification',
  verifySubDomain: '/sub-domain-verification',
  listUserTenants: '/list-user-tenants',
  signupMultiTenantEmail: '/signup-multi-tenant-mail',
  userContext: '/user-context',
  contact: {
    create: '/create-contact',
    phoneValidation: '/validate-phone',
    list: '/list-contacts',
    details: '/view-contact-details',
    update: '/update-contact-details',
    upcomingDates: '/contact-upcoming-dates',
    relatedTasks: '/contact-related-tasks',
    relatedFiles: '/contact-related-files',
    emailValidation: '/email-checker',
  },
  practiceArea: '/list-practice-area',
  createCaseTag: '/create-tag',
  createCustomField: '/create-custom-field',
  getCaseTag: '/list-tags',
  getCaseStage: '/list-case-stages',
  createCase: '/create-case',
  getContact: '/list-contacts',
  getCase: '/list-cases',
  getCaseDetails: '/view-case-details',
  getConflictCheck: '/conflict-check',
  getCustomFieldList: '/list-custom-fields',
  getCaseMatter: '/list-case-matters',
  updateCase: '/update-case-details',
  getCompanionCase: '/get-companion',
  createCompanionCase: '/create-companion',
  updateCompanionCase: '/update-companion',
  deleteCase: '/delete-case',
  closeCase: '/close-case',
  deleteCustomField: '/delete-custom-field',
  updateCustomFieldOrder: '/update-position',
  updateCustomField: '/update-custom-field-details',
  createCaseMatter: '/create-case-matter',
  updateCaseMatter: '/update-case-matter-details',
  deleteCaseMatter: '/delete-case-matter',
  listCaseMatter: '/list-case-matters',
  user: {
    create: '/create-user',
    list: '/list-users',
    details: '/view-user-details',
    update: '/update-user',
    getProfile: '/get-user-profile',
    updateProfile: '/update-user-profile',
  },
  getCaseNumberValidation: '/case-number-exists',
  createPracticeArea: '/create-practice-area',
  listPracticeArea: '/list-practice-area',
  updatePracticeArea: '/update-practice-area-details',
  deletePracticeArea: '/delete-practice-area',
  createCaseStage: '/create-case-stage',
  updateCaseStage: '/update-case-stage-details',
  deleteCaseStage: '/delete-case-stage',
  updateCaseStagePosition: '/stage-position-update',
  getUserCases: '/list-attached-cases',
  createContactGroup: '/create-contact-group',
  deleteContactGroup: '/delete-contact-group',
  updateContactGroup: '/update-contact-group-details',
  listContactGroup: '/list-contact-groups',
  calendar: {
    createEvent: '/create-event',
    getEvent: '/get-events',
    listEvents: '/list-events',
    deleteEvent: '/delete-event',
    updateEvent: '/update-event',
    listEventTypes: '/list-event-types',
    createEventType: '/create-event-type',
    updateEventType: '/update-event-type-details',
    deleteEventType: '/delete-event-type',
    userList: '/case-user-client-details',
    updateSettings: '/update-calendar-settings',
    getSettings: '/get-calendar-settings',
    getFreeTime: '/fetch-free-times',
  },
  listLeadStages: '/list-lead-stages',
  createLeadStage: '/create-lead-stage',
  updateLeadStage: '/update-lead-stage-details',
  deleteLeadStage: '/delete-lead-stage',
  getOfficeLocations: '/list-office-locations',
  createOfficeLocation: '/create-office-location',
  updateOfficeLocation: '/update-office-location-details',
  deleteOfficeLocation: '/delete-office-location',
  createCaseNote: '/create-case-note',
  listCaseNote: '/list-case-notes',
  deleteCaseNote: '/delete-case-note',
  updateCaseNote: '/update-case-note-details',
  getNoteDetails: '/view-case-note-details',
  createCaseCourt: '/create-court-location',
  createCaseMessage: '/create-message',
  listMessages: '/list-messages',
  viewMessageThreadDetails: '/view-message-thread-details',
  getSideBarMessages: '/get-sidebar-messages',
  createMessageReply: '/create-message-reply',
  updateMessageThread: '/update-message-thread',
  getTaskList: '/list-task',
  createTask: '/create-task',
  deleteTask: '/delete-task',
  updateTask: '/update-task',
  getTaskDetails: '/get-task',
  getNotifications: '/list-notifications',
  markNotification: '/notification-mark-as-read',
  clearAllNotifications: '/clear-all-notifications',
  getNotificationCount: 'get-notification-count',
  clientNotificationCount: '/get-notification-count',
  clientNotificationMarkAsRead: '/update-client-read-status',
  createLead: '/create-lead',
  viewLeadDetails: '/view-lead-details',
  listLeads: '/list-leads',
  updateLeadDetails: '/update-lead-details',
  deleteLead: '/delete-lead',
  closeLead: '/close-lead',
  fileManager: {
    file: '/file',
    fileClients: '/list-target-clients',
    sendESignature: '/send-signature-request',
    getFile: '/get-file',
    sendESignatureToAdmin: '/send-signature-response',
    tagFile: '/tag-file',
  },
  createTaskStage: '/create-task-stage',
  getTaskStages: '/list-task-stage',
  deleteTaskStage: '/delete-task-stage',
  updateTaskStage: '/update-task-stage',
  createUserGroup: '/create-user-group',
  listUserGroups: '/list-user-groups',
  deleteUserGroup: '/delete-user-group',
  updateUserGroups: '/update-user-group-details',
  createUserRole: '/create-user-roles',
  listUserRoles: '/list-user-roles',
  userRoleDetails: '/view-user-role-details',
  updateRoleDetails: '/update-user-role-details',
  deleteUserRole: '/delete-user-role',
  convertToCase: '/convert-lead',
  addRelations: '/add-relations',
  deleteRelation: '/delete-relation',
  updateRelations: '/update-relation',
  stageUpdate: '/stage-update',
  addTeamMember: '/add-team-member',
  createTimeEntry: '/create-time-entry',
  updateTimeEntry: '/update-time-entry',
  getTimeEntry: '/get-time-entry',
  listTimeEntries: '/list-time-entries',
  createActivityType: '/create-activity-type',
  updateActivityType: '/update-activity-type',
  getActivityTypes: '/get-activity-type',
  deleteActivityTypes: '/delete-activity-type',
  listActivityTypes: '/list-activity-type',
  deleteTimeEntry: '/delete-time-entry',
  getSummaryOfTimeEntries: '/get-summary-time-entries',
  leadCloseReason: '/create-lead-closure-reason',
  deleteCaseTeamMember: '/delete-team-member',
  updateTeamMember: '/update-team-member',
  getAuditLogs: '/fetch-audit-logs',
  listExpenses: '/list-expense',
  listMedicalExpenses: '/list-medical-expenses',
  listCaseExpenses: '/list-case-expenses',
  getMedicalExpenses: '/get-medical-expense',

  createMedicalExpense: 'create-medical-expense',
  updateMedicalExpense: 'update-medical-expense',
  deleteMedicalExpense: '/delete-medical-expense',

  createCaseexpense: 'create-case-expense',
  getCaseExpenses: '/get-case-expense',
  getSettlementExpenses: '/get-settlement',
  updateSettlementExpenses: '/update-settlement',

  updateCaseExpense: 'update-case-expense',
  deleteCaseExpense: '/delete-case-expense',
  deleteExpense: '/delete-expense',
  createExpense: '/create-expense',
  getExpense: '/get-expense',
  updateExpense: '/update-expense',
  createAccount: '/create-account',
  getAccount: '/get-account',
  updateAccount: '/update-account',
  listAccount: '/list-account',
  deleteAccount: '/delete-account',
  createExpenseType: '/create-expense-type',
  updateExpenseType: '/update-expense-type',
  getExpenseType: '/get-expense-type',
  listExpenseType: '/list-expense-type',
  deleteExpenseType: '/delete-expense-type',
  listPaymentStructure: '/list-payment-structure',
  updatePaymentStructure: '/update-payment-structure',
  createTax: '/create-tax',
  updateTax: '/update-tax',
  listTax: '/list-tax',

  createSnippet: '/create-text-snippet',
  updateSnippet: '/update-text-snippet',
  getSnippet: '/list-text-snippets',
  deleteSnippet: '/delete-text-snippet',

  listAutomationRules: '/list-automation-rules',
  createAutomationRules: '/create-automation-rule',
  updateAutomationRules: '/update-automation-rule',
  getAutomationRuleDetails: '/get-automation-rule',
  validateAutomationRule: '/automation-validation',

  getHourRate: '/get-hour-rate-user',
  createPaymentRequest: '/create-payment-request',
  getPaymentRequest: '/get-payment-request',
  updatePaymentRequest: '/update-payment-request',
  listPaymentRequest: '/list-payment-request',
  deletePaymentRequest: '/delete-payment-request',
  createPaymentRecord: '/create-payment-record',
  getPaymentRecord: '/get-payment-record',
  updatePaymentRecord: '/update-payment-record',
  listPaymentRecord: '/list-payment-record',
  deletePaymentRecord: '/delete-payment-record',
  createPaymentRefund: '/create-payment-refund',
  sendRequestMail: '/send-payment-request-email',
  getTrustAccountBalance: '/get-trust-account-balance',
  changeTenant: '/change-tenant',
  deactivateFirmAccount: '/deactivate-firm',
  updateFirmName: '/update-firm-name',
  log_in_out: '/log-in-out',
  createPaymentReminder: '/create-payment-reminder',
  updatePaymentReminder: '/update-payment-reminder',
  listPaymentReminders: '/list-payment-reminder',
  deletePaymentReminder: '/delete-payment-reminder',
  createInvoice: '/create-invoice',
  listInvoice: '/list-invoice',
  getInvoiceNumber: '/get-invoice-number',
  getClientPaymentDetails: '/list-client-payment-details',
  getPresignedURL: '/presigned-post-url',
  presignedURLGet: '/presigned-get-url',
  leadClosureReasons: '/list-lead-closure-reasons',
  getInvoice: '/get-invoice',
  listClientInvoices: '/list-client-invoice-details',
  deleteCaseRelation: '/delete-case-relation',
  updateCaseContact: '/update-case-contact',
  dashboard: '/dashboard-items',
  clientBillingSummary: '/get-client-summary',
  dashBoardDataPointOne: '/dashboard-cards-one',
  dashBoardDataPointTwo: '/dashboard-cards-two',
  dashBoardDataPointThree: '/dashboard-cards-three',
  manageTask: '/manage-task-column',
  archiveUser: '/archive-user',
  getSubscriptionPlan: '/get-subscription',
  deleteInvoice: '/delete-invoice',
  updateInvoice: '/update-invoice',
  availablePhoneNumber: '/available-phone-numbers',
  listTextMessage: '/text-message-threads',
  textMessageHistory: '/text-message-history',
  updateTextStatus: '/update-text-mesage-status',
  sendTextMessage: '/send-text-message',
  purchasePhoneNumber: '/purchase-phone-number',
  listCaseContacts: '/list-case-contacts',
  listTrustAccount: '/list-trust-account',
  caseBillingSummary: '/get-billing-summary',
  sendInvoiceEmail: '/send-invoice-email',
  createClientReminder: '/create-client-reminder',
  globalSearch: '/global-search',

  updateLeadUser: '/update-lead-users',
  createSettings: '/create-setting',
  getSettings: '/get-setting',
  updateSettings: '/update-setting',
  sendInvoice: '/send-invoice',
  getTrustBalanceInfo: '/get-trust-balance-info',

  updateUserPermission: '/update-user-permissions',
  listLeadSources: '/list-lead-source',
  clientDashboard: '/client-dashboard',
  fileSharing: '/file-sharing',
  timeTracker: '/time-tracker',

  generateReport: '/generate-firm-reports',
  reports: '/get-reports',
  exportReports: '/export-report',
  exportFrrReports: '/export-frr',
  saveReport: '/save-firm-reports',
  loadReport: '/list-saved-report',
  getFutureMonths: '/frr',
  getCaseList: '/basic-case-list',
  medicalProvider: '/mpr',
  getGlanceTotals: '/glance-totals',

  resendInvitation: '/resend-invitation',
  getUserProfile: '/get-user-profile',
  updateUserProfile: '/update-user-profile',

  listRelationRoles: '/list-relation-roles',
  createRelationRoles: '/create-relation-roles',
  updateRelationRoles: '/update-relation-roles',
  deleteRelationRoles: '/delete-relation-roles',

  listLeadSource: '/list-lead-source',
  createLeadSource: '/create-lead-source',
  updateLeadSource: '/update-lead-source',
  deleteLeadSource: '/delete-lead-source',

  getJobTitle: '/job-title',
  createJobTitle: '/job-title',
  updateJobTitle: '/job-title',
  deleteJobTitle: '/job-title',

  listGlobalTags: '/list-global-tags',
  createGlobalTags: '/create-global-tags',
  updateGlobalTags: '/update-global-tags',
  deleteGlobalTags: '/delete-global-tags',

  listBusinessRole: '/list-business-role',
  createBusinessRole: '/create-business-role',
  deleteBusinessRole: '/delete-business-role',
  updateBusinessRole: '/update-business-role',

  listSubscriptionProducts: '/list-subscription-products',
  addCard: '/add-card',
  subscribeToProduct: '/subscribe',
  listCard: 'list-cards',
  changeDefaultCard: '/change-default-card',
  cancelSubscription: '/cancel-Subscription',
  listSubscriptionInvoice: '/list-subscription-invoices',
  getProration: '/prorated-invoice',
  deleteCard: '/delete-card',

  //secure message module edit case section
  chageMessageCase: '/change-message-case',

  //secure message module invite user section
  sendInviteToUser: '/save-invite-user',

  totalCount: '/total-count',

  //outcome for case closure
  listOutcome: '/list-outcome',
  createOutcome: '/create-outcome',
  updateOutcome: '/update-outcome',
  deleteOutcome: '/delete-outcome',
  createOutcomeItem: '/create-outcome-item',

  //closure reason
  deleteClosureReason: '/delete-closure-reason',
  updateClosureReason: '/update-closure-reason',
  createClosureItem: '/create-closure-item',

  //workflow automation - error log API end points
  listAutomationErrorLogs: '/list-automation-error-logs',

  //Single API to fetch list of cases and leads
  listCasesAndLeads: '/list-case-lead',
  bulkDeleteMoveFile: '/bulk-file-operations',
  validateUpload: '/validate-storage',

  // venue
  getVenue: '/list-venue',
  createVenue: '/create-venue',
  updateVenue: '/update-venue',

  //Billing Module/Temporary Funds
  temporaryFunds: 'temp-funds',
  listTemporaryFunds: 'list-temp-funds',
  getTemporaryFundBalance: 'get-temporary-fund-balance',
  updateTemporaryFundTransaction: 'update-temporary-fund-transaction',
  updateTrustFundTransaction: 'update-trust-fund-transaction',
};

export default apiEndPoints;
