import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { getClientUserList } from 'services/CaseServices';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputNumber } from 'primereact/inputnumber';
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentRecord, getTrustAccountBalance, getInvoice } from 'services/billingServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { dollarFormat } from 'utils/utils';
import { totalCountEvent } from 'services/generalServices';
import { listAccounts } from 'redux/actions/AccountListAction';
import { convertToTimezoneWithStartOfDayFormat } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import { ModalHeader } from 'shared/ModalHeader';
import { paymentMethods } from 'constants/dropdownOptions';
import { useCaseAndLeadsList } from 'hooks/useCaseAndLeadList';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const typeOfPayments = [
  {
    label: 'Offline',
    value: 'offline',
  },
  {
    label: 'From trust account',
    value: 'trust',
  },
];

export const RecordPayment = (props) => {
  const { isLeadSpecific = false } = props;
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [billingContacts, setBillingContacts] = useState([]);
  const [typeOfPayment, setTypeOfPayment] = useState('offline');
  const [billingContact, setBillingContact] = useState();
  const [amount, setAmount] = useState();
  const [recordDate, setRecordDate] = useState();
  const [payFull, setPayFull] = useState(false);
  const [description, setDescription] = useState();
  let [formErrors, setFormErrors] = useState({
    contact: '',
    amount: '',
    record_date: '',
    payment_method: '',
    deposit_info: '',
  });

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const accountsList = useSelector((state) => state.accountsList.accounts.account);
  const [selectedAccount, setSelectedAccount] = useState();
  const [loading, setLoading] = useState(false);
  const [trustAccountBalance, setTrustAccountBalance] = useState();
  const [selectedTrustAccount, setSelectedTrustAccount] = useState();
  const [invoiceDetails, setInvoiceDetails] = useState();
  const userContext = useContext(UserDetailsContext);

  const { caseAndLeadsList } = useCaseAndLeadsList(props?.selectedRequest?.case_id);

  const trustAccount = useMemo(() => {
    let filteredAccounts = accountsList?.filter((a) => a?.account_type === 'trust');
    if (caseAndLeadsList?.case_list?.[0]?.default_trust_account?.[0]?.account_id) {
      filteredAccounts = filteredAccounts?.filter(
        (account) => account?.account_id === caseAndLeadsList?.case_list?.[0]?.default_trust_account?.[0]?.account_id
      );
    }
    return filteredAccounts || [];
  }, [accountsList, caseAndLeadsList, typeOfPayment]);

  const operatingAccounts = useMemo(() => {
    let filteredAccounts = accountsList?.filter((a) => a?.account_type === 'operating');
    if (caseAndLeadsList?.case_list?.[0]?.default_operating_account?.[0]?.account_id) {
      filteredAccounts = filteredAccounts?.filter(
        (account) => account?.account_id === caseAndLeadsList?.case_list?.[0]?.default_operating_account?.[0]?.account_id
      );
    }
    return filteredAccounts || [];
  }, [accountsList, caseAndLeadsList]);

  const depositAccountList = typeOfPayment === 'offline' ? [...trustAccount, ...operatingAccounts] : operatingAccounts;

  useEffect(() => {
    totalCountEvent('account')
      .then((response) => {
        dispatch(listAccounts());
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    //console.log("props.selectedRequest", props.selectedRequest);
    if (props.selectedRequest?.case_id) {
      clearFormError();
      setSelectedPaymentMethod();
      setSelectedAccount();
      setDescription();
      setAmount();
      setRecordDate();
      loadBillingContacts(props.selectedRequest.case_id);
      //loadTrustAccountBalance(props.selectedRequest?.billing_contact_id)
    }
  }, [props.selectedRequest]);

  useEffect(() => {
    if (props.selectedInvoice && props.show) {
      //console.log("wrked");
      clearFormError();
      setSelectedPaymentMethod();
      setSelectedAccount();
      setDescription();
      setAmount();
      setRecordDate();

      getInvoice(props.selectedInvoice.sk)
        .then((response) => {
          setInvoiceDetails({ ...response.data });
        })
        .catch((err) => {
          console.log('err', err);
        });
    }
  }, [props.selectedInvoice, props.show]);

  useEffect(() => {
    if (invoiceDetails) {
      loadBillingContacts(invoiceDetails?.invoice?.case_id);
    }
  }, [invoiceDetails]);

  const onChangePayFull = (e) => {
    setPayFull(e.target.checked);
    if (e.target.checked) {
      if (props.selectedRequest?.due_amount) {
        setAmount(props.selectedRequest?.due_amount);
      } else if (props.selectedInvoice?.due_amount) {
        setAmount(props.selectedInvoice?.due_amount);
      }
    }
  };

  useEffect(() => {
    if (selectedTrustAccount?.account_id) {
      loadTrustAccountBalance(selectedTrustAccount?.account_id);
    }
  }, [selectedTrustAccount]);

  useEffect(() => {
    setSelectedAccount();
  }, [accountsList, typeOfPayment]);

  const loadBillingContacts = (case_id) => {
    getClientUserList(case_id, true)
      .then((response) => {
        let contacts = [];
        if (response?.data && response.data.length > 0) {
          response.data.forEach((index) => {
            let name = index.first_name ? index.first_name : '';
            if (index.last_name) name = name + ' ' + index.last_name;
            let obj = {
              name: name,
              contact_id: index.contact_id,
            };
            contacts.push(obj);
          });
        }
        setBillingContacts([...contacts]);
      })
      .catch((err) => {});
  };

  const validateForm = () => {
    let due_amount = props.selectedRequest?.due_amount;
    if (props.selectedInvoice && invoiceDetails) {
      due_amount = invoiceDetails.due_amount;
    }
    due_amount = due_amount ? parseFloat(due_amount) : 0;
    let isValid = true;
    if (!billingContact) {
      isValid = false;
      formErrors.contact = 'Required';
    }
    if (!amount || amount <= 0) {
      isValid = false;
      formErrors.amount = 'Required a valid amount';
    }
    if (amount && amount > due_amount) {
      isValid = false;
      formErrors.amount = "Amount can't be higher than the due amount";
    }
    if (!recordDate) {
      isValid = false;
      formErrors.record_date = 'Required';
    }
    if (!selectedAccount) {
      isValid = false;
      formErrors.deposit_info = 'Required';
    }
    if (typeOfPayment === 'offline' && !selectedPaymentMethod) {
      isValid = false;
      formErrors.payment_method = 'Required';
    }
    if (typeOfPayment === 'trust' && amount && amount > trustAccountBalance) {
      isValid = false;
      formErrors.amount = "Amount can't be higher than the trust account balance";
    }
    if (typeOfPayment === 'trust' && !selectedTrustAccount) {
      formErrors.trust_account = 'Required';
    }
    setFormErrors({ ...formErrors });
    return isValid;
  };

  const onSubmit = () => {
    if (validateForm()) {
      if (!props.isEdit) {
        setLoading(true);
        let sk = props.selectedRequest?.sk;
        let payment_request_number = props.selectedRequest?.payment_request_number;
        let due_amount = props.selectedRequest?.due_amount;
        let case_id = props.selectedRequest?.case_id;
        let case_name = props.selectedRequest?.case_name;
        if (props.selectedInvoice && invoiceDetails) {
          sk = invoiceDetails?.sk;
          payment_request_number = invoiceDetails?.invoice?.invoice_number;
          due_amount = invoiceDetails.due_amount;
          case_id = invoiceDetails?.invoice?.case_id;
          case_name = invoiceDetails?.invoice?.case_name;
        }
        //due_amount = due_amount?parseFloat(due_amount):0
        let request_id = sk.split('#').reverse()[0];

        let data = {
          case_id: case_id,
          case_name: case_name,
          billing_contact_id: billingContact?.contact_id,
          billing_contact_name: billingContact?.name,
          description: description,
          deposit_into: selectedAccount?.account_type, //props.selectedRequest?.deposit_into,
          deposit_account_name: selectedAccount?.account_name, //props.selectedRequest?.deposit_account_name,
          paid_amount: props.selectedRequest?.paid_amount,
          payment_record_amount: amount,
          due_amount: due_amount,
          amount: invoiceDetails?.due_amount ? invoiceDetails?.due_amount : props.selectedRequest?.amount,
          payment_record_date: recordDate ? convertToTimezoneWithStartOfDayFormat(recordDate, userContext?.userDetails?.timezone) : '',
          payment_method: selectedPaymentMethod,
          payment_ref_id: request_id,
          payment_ref_number: payment_request_number,
          payment_record_status: amount === due_amount ? 'FULL' : 'PARTIAL',
          payment_record_type: typeOfPayment === 'offline' ? 'OFFLINE' : 'TRUST',
          payment_for: 'payment_request',
        };
        data.deposit_account_id = selectedAccount?.account_id;
        if (typeOfPayment === 'trust') {
          data.debit_account_name = selectedTrustAccount?.account_name;
          data.debit_account_id = selectedTrustAccount?.account_id;
        }
        let request_sk = props.selectedRequest?.sk;
        if (props.selectedInvoice && invoiceDetails) {
          request_sk = props.selectedInvoice?.sk;
          data.payment_for = 'invoice';
        }
        createPaymentRecord(data, request_sk, isLeadSpecific)
          .then((response) => {
            setLoading(false);
            addToast(
              false,
              toastConstant.toasterType.SUCCESS,
              toastConstant.api.SUCCESS,
              toastConstant.message.CREATE_PAYMENT_RECORD_SUCCESS
            );
            props.onSuccess();
          })
          .catch((err) => {
            console.log('err', err);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CREATE_PAYMENT_RECORD_FAILED);
            setLoading(false);
          });
      }
    }
  };
  const clearFormError = () => {
    formErrors = {
      contact: '',
      amount: '',
      payment_method: '',
      deposit_info: '',
    };
    setFormErrors({ ...formErrors });
  };

  const loadTrustAccountBalance = (trust_account_id) => {
    getTrustAccountBalance(trust_account_id)
      .then((response) => {
        //console.log("response", response);
        let available_balance = response?.data?.available_balance;
        available_balance = available_balance ? parseFloat(available_balance) : '';
        setTrustAccountBalance(available_balance);
      })
      .catch((err) => {});
  };

  return (
    <Dialog
      visible={props.show && (props.selectedRequest || invoiceDetails)}
      onHide={props.onHide}
      header={ModalHeader}
      className="create-msg-popup"
      closable={false}
    >
      <div>
        {(props.selectedRequest || invoiceDetails) && (
          <>
            <div className="row">
              <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                <div className="modal-heading F-size16 text-bold">{'Record Payment'}</div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-sm-4 p-medium">
                  {props.selectedRequest?.case_name}
                  {invoiceDetails?.invoice?.case_name ? invoiceDetails?.invoice?.case_name : ''}
                </div>
                <div className="col-12 col-sm-8">
                  {props.selectedRequest ? dollarFormat(props.selectedRequest?.due_amount) : ''}
                  {invoiceDetails?.due_amount ? dollarFormat(invoiceDetails?.due_amount) : ''}
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-sm-4 p-medium">Type of Payment</div>
                <div className="col-12 col-sm-8">
                  <Dropdown
                    options={typeOfPayments}
                    value={typeOfPayment}
                    className="w-100"
                    onChange={(e) => {
                      setTypeOfPayment(e.target.value);
                      formErrors.payment_method = '';
                      setFormErrors({ ...formErrors });
                      setSelectedPaymentMethod();
                    }}
                    filter
                    //showClear={(typeOfPayment ==="" )? false : true}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-sm-4 p-medium">
                  Contact
                  <span className="text-danger">*</span>
                </div>
                <div className="col-12 col-sm-8">
                  <Dropdown
                    options={billingContacts}
                    optionLabel={'name'}
                    filterBy={'name'}
                    value={billingContact}
                    onChange={(e) => {
                      setBillingContact(e.value);
                      formErrors.contact = '';
                      setFormErrors({ ...formErrors });
                    }}
                    filter
                    //showClear={(billingContact ==="" )? false : true}
                    className="w-100"
                  />
                  {formErrors && formErrors.contact && <span className="text-danger">{formErrors.contact}</span>}
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-sm-4 p-medium">
                  Amount
                  <span className="text-danger">*</span>
                </div>
                <div className="col-12 col-sm-8">
                  <div className="d-flex align-items-center">
                    <InputNumber
                      className="input-shadow"
                      value={amount}
                      onValueChange={(e) => {
                        setAmount(e.target.value);
                        formErrors.amount = '';
                        setFormErrors({ ...formErrors });
                      }}
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      disabled={payFull}
                    />
                    <div className="align-items-center ms-2">
                      <label className="switch">
                        <input type="checkbox" checked={payFull} name="weekends" onChange={onChangePayFull} />{' '}
                        <span className="slider round"></span>
                      </label>
                      <span className="ms-2">Pay in full</span>
                    </div>
                  </div>
                  {formErrors && formErrors.amount && <span className="text-danger">{formErrors.amount}</span>}
                </div>
              </div>
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-sm-4 p-medium">
                  Date
                  <span className="text-danger">*</span>
                </div>
                <div className="col-12 col-sm-8">
                  <Calendar
                    className="input-shadow w-100 border-0 p-0"
                    value={recordDate}
                    onChange={(e) => {
                      setRecordDate(e.value);
                      formErrors.record_date = '';
                      setFormErrors({ ...formErrors });
                    }}
                  />
                  {formErrors && formErrors.record_date && <span className="text-danger">{formErrors.record_date}</span>}
                </div>
              </div>
              {typeOfPayment === 'offline' && (
                <>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-12 col-sm-4 p-medium">
                      Payment Method
                      <span className="text-danger">*</span>
                    </div>
                    <div className="col-12 col-sm-8">
                      <Dropdown
                        className="input-shadow w-100 border-0 p-0"
                        options={paymentMethods}
                        value={selectedPaymentMethod}
                        onChange={(e) => {
                          setSelectedPaymentMethod(e.value);
                          formErrors.payment_method = '';
                          setFormErrors({ ...formErrors });
                        }}
                        filter
                        //showClear={(selectedPaymentMethod ==="" )? false : true}
                      />
                      {formErrors && formErrors.payment_method && <span className="text-danger">{formErrors.payment_method}</span>}
                    </div>
                  </div>
                </>
              )}

              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-sm-4 p-medium">
                  Deposit Info
                  <span className="text-danger">*</span>
                </div>
                <div className="col-12 col-sm-8">
                  <Dropdown
                    className="input-shadow w-100 border-0 p-0"
                    options={depositAccountList}
                    value={selectedAccount}
                    onChange={(e) => {
                      setSelectedAccount(e.target.value);
                      formErrors.deposit_info = '';
                      setFormErrors({ ...formErrors });
                    }}
                    optionLabel={'account_name'}
                    filter
                    //showClear={(selectedAccount ==="" )? false : true}
                  />
                  {formErrors && formErrors.deposit_info && <span className="text-danger">{formErrors.deposit_info}</span>}
                </div>
              </div>
              {typeOfPayment === 'trust' && (
                <div className="d-flex align-items-center py-2 flex-wrap">
                  <div className="col-12 col-sm-4  mt-3">
                    Trust
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-12 col-sm-8  mt-3">
                    <Dropdown
                      className="input-shadow w-100 border-0 p-0"
                      options={trustAccount}
                      value={selectedTrustAccount}
                      onChange={(e) => {
                        setSelectedTrustAccount(e.target.value);
                        formErrors.trust_account = '';
                        setFormErrors({ ...formErrors });
                      }}
                      optionLabel={'account_name'}
                      filter
                      //showClear={(selectedTrustAccount ==="" )? false : true}
                    />
                    {formErrors && formErrors.trust_account && <span className="text-danger">{formErrors.trust_account}</span>}
                  </div>
                </div>
              )}
              <div className="d-flex align-items-center py-2 flex-wrap">
                <div className="col-12 col-sm-4 p-medium">Description</div>
                <div className="col-12 col-sm-8  mt-3">
                  <TextSnippetWrapper
                    type="textarea"
                    className="input-shadow w-100 border-0 p-2"
                    value={description}
                    onChange={(e) => {
                      setDescription(e?.target?.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="footer-btn">
              <div className="col-12 d-flex justify-content-end">
                <Button
                  className="p-button-secondary outline mx-2"
                  label="Cancel"
                  onClick={() => {
                    props?.onHide();
                  }}
                />
                <Button
                  className="p-button-secondary"
                  label={props?.selectedExpense ? 'Update' : 'Save'}
                  onClick={onSubmit}
                  loading={loading}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
