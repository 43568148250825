import { useEffect, useState } from 'react';
import { getPracticeAreas } from 'services/case/caseServices';
import { totalCountEvent } from 'services/generalServices';

export function usePracticeAreaList(loadFullList = true, _pageSize = 10) {
  const [data, setData] = useState([]);
  const [isForward, setIsForward] = useState(true);
  const [pageSize, setpageSize] = useState(_pageSize);

  const next_Token = data?.next_token;
  const transition_Token = data?.transition_token;
  const practiceAreaList = data?.['practice-area'] || [];

  const hasNext = next_Token === null && isForward;
  const hasPrevious = transition_Token === null || (transition_Token && next_Token === null && !isForward);

  const fetchNext = () => {
    setIsForward(true);
    loadPracticeAreaList({
      pageSize,
      pagination_token: !isForward ? transition_Token : next_Token,
      pagination_direction: 'forward',
    });
  };

  const fetchprev = () => {
    setIsForward(false);
    loadPracticeAreaList({
      pageSize,
      pagination_token: !isForward ? transition_Token : next_Token,
      pagination_direction: 'reverse',
    });
  };

  const onPageChange = (_pageSize) => {
    setpageSize(_pageSize);
    loadPracticeAreaList({
      pageSize: _pageSize,
      pagination_token: '',
      pagination_direction: 'forward',
    });
    setIsForward(false);
  };

  const loadPracticeAreaList = (pageLimit, pagination_token, pagination_direction) => {
    getPracticeAreas(pageLimit, pagination_token, pagination_direction)
      .then((response) => setData(response?.data))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (loadFullList) {
      totalCountEvent('practice-area')
        .then((response) => {
          loadPracticeAreaList({ pageLimit: response.data });
        })
        .catch((err) => console.log(err));
    } else {
      loadPracticeAreaList({ pageLimit: pageSize });
    }
  }, []);

  return { practiceAreaList, hasNext, hasPrevious, fetchNext, fetchprev, onPageChange, loadPracticeAreaList };
}
