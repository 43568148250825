import React, { useEffect, useState, useCallback } from 'react';
import { Storage, Auth } from 'aws-amplify';
import { FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL, SetS3Config } from 'config/amplify_config';
import { useToast } from 'context/ToastContext';
import PdfViewerComponent from 'modules/file_manager/PdfViewerComponent';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { getFileClients, presignedURLGet, sendESigantureRequest } from 'services/fileManager/fileManagerServices';
import { toTitleCase } from 'utils/utils';
import { toastConstant } from 'constants/toastmessage';

function SignatureModal() {
  const { modals, selectedItem, caseDetails, isCaseFileManager, isLeadFileManager, handleGetFiles, pageLimit } = useFileManagerContext();

  const { showSignatureModal, setShowSignatureModal } = modals;

  const { addToast } = useToast();

  const [instance, setInstance] = useState(null);
  const [PSPDFKit, setPSPDFKit] = useState(false);
  const [assignee, setAssignee] = useState('');
  const [clientOptions, setClientOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (selectedItem?.case_id) {
      getFileClients({ case_id: selectedItem.case_id })
        .then((response) => {
          if (response && response.data && response.data.length) {
            let option = [];
            response.data
              .filter((val) => val.client_cognito_username)
              .forEach((element) => {
                let obj = {};
                let fullName = '';
                fullName = `${element.first_name ? toTitleCase(element.first_name) : ''} ${
                  element.middle_name ? toTitleCase(element.middle_name) : ''
                } ${element.last_name ? toTitleCase(element.last_name) : ''}`;
                obj.value = element.client_cognito_username;
                if (element.is_create_client_portal === false && element.status !== 'ACTIVE') {
                  obj.label = fullName + ' (Inactive and Disabled Lawft Access)';
                } else if (element.is_create_client_portal === false) {
                  obj.label = fullName + ' (Disabled Lawft Access)';
                } else if (element.status !== 'ACTIVE') {
                  obj.label = fullName + ' (Inactive)';
                } else if (element.is_confirmed && element.is_confirmed === true) {
                  obj.label = fullName;
                } else if (!element.is_confirmed || element.is_confirmed === false) {
                  obj.label = fullName + ' (Not Confirmed)';
                } else {
                  obj.label = fullName;
                }
                obj.value = element.client_cognito_username;

                if ((element.is_create_client_portal === false || element.status !== 'ACTIVE') && selectedItem?.case_id) {
                  obj.disabled = true;
                } else {
                  obj.disabled = false;
                }
                option.push(obj);
              });
            setClientOptions(option);
          } else {
            setClientOptions([]);
          }
        })
        .catch((error) => {
          setClientOptions([]);
        });
    }
  }, [selectedItem]);

  useEffect(() => {
    (async function () {
      let url = await fetchData();
      setUrl(url);
    })();
  }, [selectedItem]);

  const fetchData = async () => {
    let file_path = selectedItem?.file_path;
    let version_id = selectedItem?.version_id;
    let response = await presignedURLGet(file_path, version_id);
    if (response.data) {
      return response?.data?.file_url;
    }
    return '';
  };

  const applyFields = async () => {
    await uploadForSigning();
  };

  const uploadForSigning = async () => {
    setIsLoading(true);
    const arr = await instance?.exportPDF();
    const blob = new Blob([arr], { type: 'application/pdf' });
    await updateFile([blob]);

    let sendData = {};
    sendData.client_cognito_username = [assignee];

    let obj = {
      case_id: selectedItem.case_id,
      file_pk: selectedItem?.pk,
      file_sk: selectedItem?.sk,
      data: sendData,
      is_lead: isLeadFileManager,
    };

    //requesting foe signature
    sendESigantureRequest(obj)
      .then(() => {
        setIsLoading(false);
        handleGetFiles(pageLimit);
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.E_SIGNATURE_SEND_SUCCESS);
      })
      .catch((error) => {
        let msg = toastConstant.message.E_SIGNATURE_SEND_FAILED;
        if (error.response.data === 'Invalid Case') msg = 'File is not linked with a case';
        setIsLoading(false);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, msg);
      });
  };

  const updateFile = async (files) => {
    if (files && files.length > 0) {
      // let userId;
      SetS3Config(FILEUPLOAD_S3, FILEUPLOAD_S3_LEVEL);
      await Auth.currentAuthenticatedUser();

      for (let i = 0; i < files.length; i++) {
        let pathFile = selectedItem && selectedItem.file_path && selectedItem.file_path.split('public/')[1];
        let response = await Storage.put(pathFile, files[i], {
          // tagging: `tenant=${tenantId}`,
          contentType: files[i].type,
          progressCallback: (progress) => {
            const { loaded, total } = progress;
            let percent = Math.floor((loaded * 100) / total);
            if (percent <= 100) {
              //setUploadPercentage(percent);
            }
          },
          useAccelerateEndpoint: true,
        });
        if (response.key) {
          // console.log(response.key);
        }
      }
    }
  };

  const customHeaderTemplate = (data) => {
    return (
      <div>
        <div className="d-flex justify-content-between">
          <div>
            <i className="icon-window-filled pointer F-size14" onClick={data?.onHide}></i>
            {/* <i className="icon-window-filled pointer F-size14 icon-minimize"></i> */}
          </div>
          <span></span>
        </div>
      </div>
    );
  };

  const handleDragDate = useCallback((ev) => ev.dataTransfer.setData('text/plain', 'date'), []);

  const handleDragSignature = useCallback((ev) => ev.dataTransfer.setData('text/plain', 'signature'), []);

  return (
    <Dialog
      visible={showSignatureModal}
      modal
      resizable={false}
      draggable={false}
      className="new-task-popup my-dialog custom-dialog signature-modal"
      header={customHeaderTemplate}
      onHide={() => setShowSignatureModal(false)}
      closable={false}
    >
      <div className="w-100 h-100">
        <div className="d-flex flex-wrap h-100">
          <div className="pe-3 my-3 col-lg-3 col-12 d-flex flex-column">
            <div className="header fw-bold mb-2">{selectedItem?.file_name}</div>
            <div className="header fw-bolder mb-2">Recipient</div>

            <Dropdown
              value={assignee}
              onChange={(e) => setAssignee(e.value)}
              options={clientOptions}
              optionLabel="label"
              placeholder="Select Contact"
              className="w-full md:w-14rem"
            />
            {assignee && (
              <>
                <div className=" fw-bold mt-3 mb-2">Fields</div>
                <Tooltip target=".signature-field" />

                <div className="d-flex justify-content-between">
                  <div
                    onDragStart={handleDragSignature}
                    draggable
                    className="d-flex justify-content-between align-items-center  signature-field"
                    style={{ width: 150, height: 30, border: '1.5px solid #2196F3 ', cursor: 'move' }}
                    data-pr-tooltip="Drag & Drop into document "
                    data-pr-position="top"
                    //  data-pr-my="left center-2"
                  >
                    <div className="d-flex justify-content-center align-center ms-1">
                      <i className="fas fa-grip-vertical"></i>
                    </div>
                    <div>Signature</div>
                    <div className="d-flex justify-content-center align-items-center bg-primary" style={{ height: 30, width: 30 }}>
                      <i className="fas fa-file-signature text-white"></i>
                    </div>
                  </div>

                  {/* <div
                  onDragStart={handleDragDate}
                  draggable
                  className="d-flex justify-content-between align-items-center  signature-field"
                  style={{ width: 150, height: 30, border: '1.5px solid #2196F3 ', cursor: 'move' }}
                  data-pr-tooltip="Drag & Drop into document "
                  data-pr-position="top"
                  //  data-pr-my="left center-2"
                >
                  <div className="d-flex justify-content-center align-center ms-1">
                    <i className="fas fa-grip-vertical"></i>
                  </div>
                  <div>Date</div>
                  <div className="d-flex justify-content-center align-items-center bg-primary" style={{ height: 30, width: 30 }}>
                    <i className="fas fa-file-signature text-white"></i>
                  </div>
                </div> */}
                </div>
                <br />
                <br />

                <Button disabled={!assignee || isLoading} onClick={applyFields} label={'Send'} className=" p-button p-button-secondary" />
              </>
            )}
          </div>
          <div className="col-lg-9 col-12">
            {url && (
              <PdfViewerComponent
                document={url}
                setPSPDFKit={setPSPDFKit}
                setInstance={setInstance}
                signatureOption={true}
                assignee={assignee}
                clientOptions={clientOptions}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default SignatureModal;
