import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import ImageComponent from 'shared/ImageComponent';
import { handleRedirection, paginationDropdownOptions, toCapitalized } from 'utils/utils';
import { UserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';
import { Dropdown } from 'primereact/dropdown';
import { handlePageNumberInPagination } from 'common/pagination';
import { useState } from 'react';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';

function ActivityLogTable(props) {
  const { activityLog, isCaseSpecific, isLead, isFromDashBoard } = props;

  const history = useHistory();
  const userContext = useContext(UserDetailsContext);
  const [pageLimit, setPageLimit] = useState(getPageLimit() ?? 10);
  const emptyDataValue = '-';

  const dateTimeTemplate = (rowData) => {
    if (rowData && rowData.timestamp) {
      return (
        <>
          <div className="d-flex flex-wrap">
            <span className="p-column-title text-break">Date and Time</span>
            <div className="d-block">
              <div>{`${handleDateTimeOffset(
                userContext?.userDetails?.timezone,
                rowData.timestamp,
                constants.month_date_year_format
              )}`}</div>
              <div className="text-hint">
                {`${handleDateTimeOffset(userContext?.userDetails?.timezone, rowData.timestamp, constants.hour_minute_12_format)}`}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return 'N/A';
    }
  };

  const redirectUser = (e, data) => {
    e.preventDefault();
    if (data) {
      let route = '';

      //TODO:We need to add "cotact_type" key here. This will help us to distinguish between client and firm-user/firm-admin as the if condition is not meet the correct way . Add this key when it is added to BE .
      if (data?.items?.contact_id && !data?.items?.firm_user_id) {
        route = data?.items?.contact_id ? `/contacts/${data?.items?.contact_id}` : '/contacts';
      } else {
        route = data?.items?.firm_user_id ? `/settings/team-management/${data?.items?.firm_user_id}` : '/settings/team-management';
      }

      if (data?.sk && data?.pk) handleSettingSkAndPkToLocalStorage(data?.sk, data?.pk);
      handleRedirection(history, route);
    }
  };

  const userTemplate = (rowData) => {
    const userId = rowData?.items?.firm_user_id || rowData?.items?.contact_id;
    return (
      <React.Fragment>
        <div className="d-inline-flex align-items-center">
          <span className="p-column-title text-break">Users</span>
          <span className="d-flex align-items-center pointer" onClick={(e) => redirectUser(e, rowData)}>
            <ImageComponent
              // filePath={
              //   rowData?.user_id || rowData?.items?.contact_id
              //     ? `${rowData?.items?.tenant_id}/${rowData?.items?.contact_id ? rowData?.items?.contact_id : rowData?.user_id}/profile_image`
              //     : null
              // }
              filePath={userId ? `${rowData?.items?.tenant_id}/${userId}/profile_image` : null}
              fileName={rowData?.items?.first_name}
              fileSize="small.jpg"
            />

            <span className="redirection-link text-nowrap ">
              {rowData?.items?.user_name ? toCapitalized(rowData?.items?.user_name) : 'N/A'}
            </span>
          </span>
        </div>
      </React.Fragment>
    );
  };
  const changesTemplate = (rowData) => {
    return (
      <>
        <div className="d-flex flex-wrap">
          <span className="p-column-title text-break">Description</span>
          <div className="">{rowData?.message ? rowData?.message : 'N/A'}</div>
        </div>
      </>
    );
  };
  const caseLeadTemplate = (rowData) => {
    localStorage.setItem('sk', rowData?.sk);
    localStorage.setItem('pk', rowData?.pk);

    let url = '';
    let stateToPass = '';
    const caseId = rowData?.items?.case_id;

    //TODO:We need to add "is_lead"/"event_type" key to distinguish between lead and case moudle . Add this key when it is available from BE

    if (rowData?.type?.toLowerCase() === 'case') {
      url = caseId ? `/cases/${caseId}` : '/cases';
    } else if (rowData?.type?.toLowerCase() === 'lead') {
      url = caseId ? `/leads/${caseId}` : '/leads';
      stateToPass = rowData;
    }

    const isRediretionLink = Boolean(rowData?.items?.case_name);

    return isRediretionLink ? (
      <div>
        <span
          onClick={(e) => {
            e.preventDefault();
            if (rowData?.items?.case_name) {
              handleRedirection(history, url, stateToPass);
            }
          }}
          // className={`text-primary-dark case-details-link text-decoration-none`}
          className={'redirection-link-no-text-transform'}
        >
          {rowData?.items?.case_name ? rowData?.items?.case_name : 'N/A'}
        </span>
      </div>
    ) : (
      emptyDataValue
    );
  };

  function getPageLimit() {
    if (isLead && userContext && isCaseSpecific) {
      return userContext?.firmDetails?.pagination_preference?.leads?.activity_log;
    } else if (isCaseSpecific && userContext && !isLead) {
      return userContext?.firmDetails?.pagination_preference?.case?.activity_log;
    } else if (isFromDashBoard && userContext) {
      return userContext?.firmDetails?.pagination_preference?.dashboard?.activity_log;
    }
  }

  function getFilePathForPagination() {
    if (isLead && userContext && isCaseSpecific) {
      return { module: 'leads', subModule: '', listName: 'activity_log' };
    } else if (isCaseSpecific && userContext && !isLead) {
      return { module: 'case', subModule: '', listName: 'activity_log' };
    } else if (isFromDashBoard) {
      return { module: 'dashboard', subModule: '', listName: 'activity_log' };
    } else {
      return { module: 'report', subModule: '', listName: 'activity_log' };
    }
  }

  function pageOnChange(event) {
    let path = getFilePathForPagination();
    setPageLimit(event.value);
    handlePageNumberInPagination(userContext, path?.module, path?.subModule, path?.listName, event.value);
  }

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      return (
        <>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={options.value} options={paginationDropdownOptions} onChange={pageOnChange} />
        </>
      );
    },
  };

  return (
    <div className="datatable-responsive overflow-auto">
      <DataTable
        className="p-datatable-responsive"
        value={activityLog}
        paginator
        paginatorTemplate={paginatorTemplate}
        rowsPerPageOptions={[10, 20, 50, 100]}
        rows={pageLimit}
        sortField="timestamp"
        sortOrder={-1}
      >
        <Column field="timestamp" header="Date And Time" body={dateTimeTemplate} sortable responsiveLayout="scroll"></Column>

        <Column field="message" body={changesTemplate} header="Description" sortable></Column>
        {!isCaseSpecific && <Column field="items.case_name" body={caseLeadTemplate} header="Case/Lead Name" sortable></Column>}
        <Column field="items.user_name" header="Users" body={userTemplate} sortable></Column>
      </DataTable>
    </div>
  );
}

export default ActivityLogTable;
