import { performRequest } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const getMessages = (obj) => {
  let {
    tenantId,
    tenantSk,
    case_id = false,
    author_id = [],
    message_date = false,
    keyword = false,
    is_global = false,
    is_read = '',
    to_limit = false,
    pageLimit = 10,
    pagination_token = '',
    pagination_direction = 'forward',
  } = obj;
  return performRequest(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.listMessages}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${case_id ? `&case_id=${case_id}` : ''}${
      message_date ? `&message_date=${message_date}` : ''
    }${keyword ? `&keyword=${keyword}` : ''}${is_global ? `&is_global=${is_global}` : ''}&is_read=${is_read}${
      to_limit ? `&to_limit=${to_limit}` : ''
    }&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`,
    {
      author_id: author_id,
    }
  );
};

export const viewCaseMessageDetails = (obj) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let { sk = '', pk = `${tenantId}#${obj.id}`, pageLimit = 100, pagination_token = '', pagination_direction = 'forward' } = obj;
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.viewMessageThreadDetails}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&sk=${sk}&pk=${pk}&limit=${pageLimit}&pagination_token=${pagination_token}&pagination_direction=${pagination_direction}`
  );
};

export const getSidebarMessages = (sk, limit, nextToken) => {
  const [tenantId, tenantSk] = getTenantDetails();
  const _sk = encodeURIComponent(sk);
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.getSideBarMessages}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&sk=${_sk}&limit=${limit}${
      nextToken ? `&pagination_token=${nextToken}` : ''
    }`,
    null,
    null,
    true,
    true
  );
};
