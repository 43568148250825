import React, { useEffect, useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

import CustomHeader from './CustomHeader';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { getFolders } from 'services/fileManager/fileManagerServices';
import { Controller, useForm } from 'react-hook-form';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import useUpdateFiles from 'modules/file_manager/hooks/useUpdateFiles';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

function MoveItemModal() {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { addToast } = useToast();
  const { updateFiles } = useUpdateFiles();

  const {
    modals,
    isGlobalFileManager,
    fileManagerFilters,
    isLeadFileManager,
    isCaseFileManager,
    selectedItem,
    caseDetails,
    handleGetFiles,
    pageLimit,
  } = useFileManagerContext();
  const { moveItemModal, setMoveItemModal } = modals;
  const [folderList, setFolderList] = useState([]);

  useEffect(() => {
    getFoldersHandler();
  }, []);

  const getFoldersHandler = () => {
    let params = '';
    if (selectedItem.entry_type === 'folder') {
      params = `folder_id=${selectedItem.object_id}&case_id=${isGlobalFileManager ? fileManagerFilters.case : caseDetails.case_id}`;
    } else {
      params = `case_id=${isGlobalFileManager ? fileManagerFilters.case : caseDetails.case_id}`;
    }
    getFolders(params)
      .then((response) => {
        const items = response.data;
        const dropdownList = items.filter((item) => Object.keys(item).length > 1);
        setFolderList(dropdownList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const moveFileHandler = (data) => {
    if (data.folder === fileManagerFilters.selectedFolder) {
      addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.FILE_MOVE_ERROR);
      return;
    }
    let updated_file = {
      folder_id: data.folder,
      pk: selectedItem['pk'],
      sk: selectedItem['sk'],
      type: selectedItem['entry_type'],
    };

    let obj = {
      data: updated_file,
      is_lead: isLeadFileManager,
    };

    updateFiles(obj)
      .then(() => {
        handleGetFiles(pageLimit);

        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.FILE_MOVED_SUCCESS);
        setMoveItemModal(false);
      })
      .catch((error) => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.ERROR, toastConstant.message.FILE_MOVE_FAILED);
      });
  };

  const moveFooter = (
    <div className="footer-btn">
      <Button label="Cancel" onClick={() => setMoveItemModal(false)} className="p-button p-button-secondary outline mx-2" />
      <Button label="Move" onClick={handleSubmit(moveFileHandler)} className="p-button p-button-secondary" />
    </div>
  );

  return (
    <Dialog
      className="new-task-popup"
      header={<CustomHeader onHide={() => setMoveItemModal(false)} />}
      footer={moveFooter}
      visible={moveItemModal}
      style={{ width: '40vw' }}
      modal
      onHide={() => setMoveItemModal(false)}
      closable={false}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">{selectedItem.entry_type === 'folder' ? 'Move Folder' : 'Move File'}</div>
      </div>
      <div className="P-medium">
        {selectedItem.entry_type === 'folder' ? 'Folder Name' : 'File Name'} : {selectedItem.display_name}
      </div>
      <Controller
        name="folder"
        control={control}
        defaultValue={[]}
        rules={{ required: true }}
        render={({ field }) => (
          <Dropdown
            className="input-shadow mb-2 mt-2 w-100 F-size14"
            placeholder={'Target Folder'}
            onChange={(e) => {
              field.onChange(e.value);
            }}
            id={field.name}
            value={field.value}
            options={folderList}
            isSearchable
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        )}
      />
      {errors?.folder && <InputErrorMessage>Select folder</InputErrorMessage>}
    </Dialog>
  );
}

export default MoveItemModal;
