import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';

import PriorityDropdown from 'components/UI/PriorityDropdown/PriorityDropdown';
import { dateFilterOptions } from 'constants/dropdownOptions';
import { useTaskContext } from 'modules/Tasks/Context/TaskContext';
import { userOptionTemplate } from './MultiSelectTemplate';
import { bringElementToTop, bringMultiSelectElementToTop, getUserId } from 'utils/utils';
import CaseListDropDown from 'components/CaseListDropDown/CaseListDropDown';
import { multiSelectTemplate } from '../TaskBoard/Board/PriorityDropdownTemplate';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { handleFilterUpdateInUserContext } from 'common/pagination';

export default function Filter({
  filterData,
  setFilterData,
  isFromCase,
  isFromLead,
  firmUserIds,
  setFirmUserIds,
  userList,
  showUserFilter,
  setShowUserFilter,
}) {
  const { searchKeyWord, setSearchKeyWord, userContext } = useTaskContext();
  const user_id = getUserId();

  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (!(isFromCase || isFromLead)) {
      handleFilterUpdateInUserContext(userContext, 'filters', 'task', null, name, value, true);
    }
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle user filter change
  const handleUserFilterChange = (e) => {
    const ids = Array.isArray(e.value) ? [...e.value] : [];
    if (!(isFromCase || isFromLead)) {
      handleFilterUpdateInUserContext(userContext, 'filters', 'task', null, 'users', ids, true);
    }
    setFirmUserIds(ids);
  };

  // Refresh task filters
  const refreshTask = () => {
    setFilterData({});
    setSearchKeyWord('');
  };

  return (
    <div className="filter-wrap">
      <div className="d-flex flex-wrap w-100 align-items-center p-2 filter-wraper gap-8">
        {!Boolean(isFromCase || isFromLead) && (
          <span className="p-input-icon-left input-shadow autowidth">
            <i className="icon-search F-size16"></i>
            <TextSnippetWrapper
              type="text"
              placeholder="Search"
              className="p-inputtext p-component input-search"
              value={searchKeyWord}
              onChange={(e) => setSearchKeyWord(e.target.value)}
            />
          </span>
        )}
        <span className="text-bold black-600 me-2 filter-label">Filters: </span>

        {!Boolean(isFromCase || isFromLead) && (
          <CaseListDropDown
            isClient={false}
            value={filterData?.case || userContext?.firmDetails?.user_preferences?.filters?.task?.case}
            onChange={(e) => {
              if (filterData?.case !== e) {
                handleFilterChange({ target: { name: 'case', value: e }, value: e });
              }
            }}
            name="case"
            isClearable
          />
        )}

        <PriorityDropdown
          value={
            filterData?.priority || (!(isFromCase || isFromLead) ? userContext?.firmDetails?.user_preferences?.filters?.task?.priority : '')
          }
          onChange={handleFilterChange}
          name="priority"
        />
        <Dropdown
          value={filterData.date || userContext?.firmDetails?.user_preferences?.filters?.calender?.date}
          options={dateFilterOptions}
          onChange={handleFilterChange}
          placeholder="By Due Date"
          name="date"
        />
        {!showUserFilter && (
          <Button
            type="button"
            label="Show User"
            className="p-button button-text px-1 adv-filter text-bold text-primary-main"
            onClick={() => setShowUserFilter(true)}
          />
        )}
        {showUserFilter && (isFromCase || isFromLead) && (
          <MultiSelect
            value={bringElementToTop(userList, user_id)?.length > 0 ? firmUserIds : []}
            options={bringElementToTop(userList, user_id)}
            onChange={handleUserFilterChange}
            filterBy="first_name,last_name"
            placeholder="Select Users"
            filter
            className="searchable-dropdown input-shadow"
            itemTemplate={userOptionTemplate}
            maxSelectedLabels={1}
            selectAll
            optionValue="user_id"
            optionLabel="full_name"
            name="multiSelect"
            showClear
          />
        )}
        {showUserFilter && !isFromCase && !isFromLead && (
          <MultiSelect
            value={
              bringMultiSelectElementToTop(userList, user_id)?.length > 0
                ? userContext?.firmDetails?.user_preferences?.filters?.task?.users || firmUserIds
                : []
            }
            options={bringMultiSelectElementToTop(userList, user_id)}
            onChange={handleUserFilterChange}
            filterBy="first_name,last_name"
            placeholder="Select Users"
            filter
            className="searchable-dropdown input-shadow"
            // itemTemplate={userOptionTemplate}
            itemTemplate={multiSelectTemplate}
            maxSelectedLabels={1}
            selectAll
            // optionValue="user_id"
            optionValue={'assignee_id'}
            // optionLabel="full_name"
            optionLabel="assignee_name"
            optionGroupLabel="assignee_name"
            optionGroupChildren="items"
            name="multiSelect"
            showClear
          />
        )}
        <div className="pointer ms-0 p-2">
          <i className="fas fa-sync-alt" onClick={refreshTask} />
          <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
        </div>
      </div>
    </div>
  );
}
