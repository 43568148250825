import React, { Suspense, useContext, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Cookies from 'universal-cookie';

import Layout from 'modules/layout';
import constants from 'constants/index';
import ClientLayout from 'modules/client/Layout';
import SubscriptionNoPermission from 'shared/SubscriptionNoPermission';
import { UserDetailsContext } from 'context/userDetailsContext';
import { WebSocketConnectionContext } from 'context/WebSocketConnectionContext';
import { checkSubdomain, logout } from 'utils/utils';
import { mainRoutes, subRoutes } from './routes';
import { SpinnerComponent } from 'shared/SpinnerComponent';

let cookie = new Cookies();

const publicRoutes = ['/', '/login', '/signup', '/account-activate', '/forgot-password', '/email-sent'];

const handleLogout = (e) => {
  try {
    Auth.signOut()
      .then((data) => {
        logout(null);
      })
      .catch((error) => {});
  } catch (e) {}
};

const AppRoute = ({ component: Component, permission, path, showSideBar, module, ...rest }) => {
  const location = useLocation();
  const userContext = useContext(UserDetailsContext);

  const checkPermission = (access_level) => {
    if (access_level === 'client' && (permission === 'client' || permission === 'tenent')) {
      return true;
    } else if (access_level !== 'client') {
      if (['profile', 'settings', 'tenent'].includes(permission)) {
        return true;
      } else if (permission === 'firm-settings') {
        return userContext.getFirmPermission(constants?.permissions?.MANAGE_FIRM_SETTINGS);
      } else {
        let value = userContext.getPermission(permission) === constants?.permissions?.HIDDEN ? false : true;
        return value;
      }
    }
  };

  if (publicRoutes.includes(path)) {
    return (
      <Route
        {...rest}
        render={(props) => {
          const token = cookie.get('token') || null;
          const isSignUp = cookie.get('isSignUp') || null;
          let access_level = cookie.get('access_level') || null;

          if (publicRoutes.includes(path)) {
            if (token || isSignUp) {
              if (access_level !== 'undefined') {
                if (access_level === 'client') {
                  return <Redirect to="/client/dashboard" />;
                } else {
                  return <Redirect to="/dashboard" />;
                }
              }
            } else {
              return (
                <Suspense fallback={<SpinnerComponent />}>
                  <Component {...props} />
                </Suspense>
              );
            }
          } else {
            if (token) {
              if (checkPermission(access_level)) {
                if (access_level === 'client') {
                  return (
                    <ClientLayout showSideBar={showSideBar}>
                      <Suspense fallback={<SpinnerComponent />}>
                        <Component {...props} />
                      </Suspense>
                    </ClientLayout>
                  );
                } else {
                  return (
                    <Layout showSideBar={showSideBar}>
                      <Suspense fallback={<SpinnerComponent />}>
                        <Component {...props} />
                      </Suspense>
                    </Layout>
                  );
                }
              } else {
                if (access_level === 'client') {
                  return <Redirect to="/client/dashboard" />;
                } else {
                  return <Redirect to="/dashboard" />;
                }
              }
            } else {
              if (path && (path === '/sign-document' || path === '/client-billing')) {
                let remain = location && location.search;
                sessionStorage.setItem('signature_path', JSON.stringify(`${path}${remain}`));
              }

              return <Redirect to="/" />;
            }
          }
        }}
      />
    );
  } else {
    const token = cookie.get('token') || null;

    if (!(userContext?.subscriptionAccess?.[module] || module === 'common') && token) {
      let access_level = cookie.get('access_level') || null;

      if (access_level === 'client') {
        return (
          <ClientLayout showSideBar={showSideBar}>
            <SubscriptionNoPermission />
          </ClientLayout>
        );
      } else {
        return (
          <Layout showSideBar={showSideBar}>
            <SubscriptionNoPermission />
          </Layout>
        );
      }
    }
    return (
      <>
        <Route
          {...rest}
          render={(props) => {
            const token = cookie.get('token') || null;
            const isSignUp = cookie.get('isSignUp') || null;
            let access_level = cookie.get('access_level') || null;

            if (publicRoutes.includes(path)) {
              if (token) {
                if (access_level === 'client') {
                  return <Redirect to="/client/dashboard" />;
                } else {
                  return <Redirect to="/dashboard" />;
                }
              } else {
                return (
                  <Suspense fallback={<SpinnerComponent />}>
                    <Component {...props} />
                  </Suspense>
                );
              }
            } else {
              if (token || isSignUp) {
                if (checkPermission(access_level)) {
                  if (access_level === 'client') {
                    return (
                      <ClientLayout showSideBar={showSideBar}>
                        <Suspense fallback={<SpinnerComponent />}>
                          <Component {...props} />
                        </Suspense>
                      </ClientLayout>
                    );
                  } else {
                    if (
                      access_level !== 'firm-admin' &&
                      // path === "/subscription"
                      path === '/settings/subscription'
                    ) {
                      return <Redirect to="/dashboard" />;
                    } else {
                      if (
                        userContext?.selectedPlanDetails?.status &&
                        userContext?.selectedPlanDetails.status !== 'completed' &&
                        userContext?.selectedPlanDetails?.access_modules?.subscription &&
                        !userContext?.selectedPlanDetails?.access_modules?.dashboard &&
                        // path !== "/subscription"
                        path !== '/settings/subscription' &&
                        path !== '/profile'
                      ) {
                        return (
                          <Redirect
                            to={{
                              // pathname: "/subscription",
                              pathname: '/settings/subscription',
                              state: { nonSubscription: true },
                            }}
                          />
                        );
                      } else {
                        return (
                          <Layout showSideBar={showSideBar}>
                            <Suspense fallback={<SpinnerComponent />}>
                              <Component {...props} />
                            </Suspense>
                          </Layout>
                        );
                      }
                    }
                  }
                } else {
                  if (access_level === 'client') {
                    return <Redirect to="/client/dashboard" />;
                  } else {
                    return <Redirect to="/dashboard" />;
                  }
                }
              } else {
                if (path && (path === '/sign-document' || path === '/client-billing')) {
                  let remain = location && location.search;
                  sessionStorage.setItem('signature_path', JSON.stringify(`${path}${remain}`));
                }
                cookie.set('redirectPath', rest?.location?.pathname + rest?.location?.search, {
                  path: '/',
                  domain: process.env.REACT_APP_DOMAIN,
                });

                return <Redirect to="/" />;
              }
            }
          }}
        />
      </>
    );
  }
};

function AppRouter() {
  const history = useHistory();

  const websocketContext = useContext(WebSocketConnectionContext);
  useEffect(() => {
    checkSubdomain(publicRoutes, websocketContext, history);
  }, []);

  if (
    websocketContext?.assignedTask?.notification_type === 'firm_Admin_change' ||
    websocketContext?.assignedTask?.notification_type === 'firm_deactivated' ||
    websocketContext?.clientLogout
  ) {
    handleLogout();
  }

  const hostname = window.location.hostname;

  if (hostname === 'localhost') {
    return (
      <Router>
        <Switch>
          <AppRoute
            exact
            path="/"
            component={(props) => {
              return <Redirect to="/login" />;
            }}
          />
          {mainRoutes.map((item, i) => {
            return <AppRoute exact {...item} key={i} />;
          })}
          {subRoutes.map((item, i) => {
            return <AppRoute {...item} key={i} />;
          })}
        </Switch>
      </Router>
    );
  }
  if (!constants.APP_DOMAINS.includes(window.location.hostname)) {
    return (
      <Router>
        <Switch>
          <AppRoute
            exact
            path="/"
            component={(props) => {
              return <Redirect to="/dashboard" />;
            }}
          />

          {subRoutes.map((item, i) => {
            return <AppRoute {...item} key={i} />;
          })}
        </Switch>
      </Router>
    );
  } else if (constants.APP_DOMAINS.includes(window.location.hostname)) {
    return (
      <Router>
        <Switch>
          <AppRoute
            exact
            path="/"
            component={(props) => {
              return <Redirect to="/login" />;
            }}
          />
          {mainRoutes.map((item, j) => {
            return <AppRoute exact {...item} key={j} />;
          })}
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
