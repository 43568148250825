import React, { useEffect, useRef } from 'react';

import { useForm, Controller } from 'react-hook-form';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Chips } from 'primereact/chips';

import CustomHeader from './CustomHeader';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';

import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';
import { useToast } from 'context/ToastContext';

import { saveTags } from 'services/fileManager/fileManagerServices';
import { toastConstant } from 'constants/toastmessage';

function AddTagModal() {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
  } = useForm();
  const { modals, caseDetails, isGlobalFileManager, handleGetFiles, selectedItem, pageLimit } = useFileManagerContext();
  const { showAddTag, setShowAddTag, tagModalType } = modals;
  const { addToast } = useToast();

  const tagRef = useRef();

  useEffect(() => {
    if (tagModalType === 'edit') {
      setValue('tags', selectedItem.tags);
    }
  }, []);

  function submitTagHandler(data) {
    if (data.tags.length === 0 && !tagRef.current.inputRef.current.value) {
      setError('tags');
      return;
    }
    if (tagRef.current.inputRef.current.value) {
      data.tags.push(tagRef.current.inputRef.current.value);
    }

    changeTags(data.tags);
  }

  const changeTags = (tags) => {
    let sendData = {};
    sendData.pk = selectedItem.pk;
    sendData.sk = selectedItem.sk;
    sendData.tags = tags;
    let obj = {
      data: sendData,
      is_lead: false,
    };
    saveTags(obj)
      .then(() => {
        handleGetFiles(pageLimit);
        setShowAddTag(false);
        let msg = tagModalType === 'add' ? toastConstant.message.TAGS_ADDED_SUCCESS : toastConstant.message.TAGS_UPDATED_SUCCESS;
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, msg);
      })
      .catch((error) => {
        console.log(error);
        let msg = tagModalType === 'add' ? toastConstant.message.TAGS_ADDED_FAILED : toastConstant.message.TAGS_UPDATED_FAILED;
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, msg);
      });
  };

  return (
    <Dialog
      header={<CustomHeader onHide={() => setShowAddTag(false)} />}
      footer={
        <TagFooter
          setShowAddTag={setShowAddTag}
          handleSubmit={handleSubmit}
          submitTagHandler={submitTagHandler}
          tagModalType={tagModalType}
        />
      }
      visible={showAddTag}
      className="new-task-popup"
      modal
      onHide={() => {
        setShowAddTag(false);
      }}
      closable={false}
    >
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">{tagModalType === 'add' ? 'Add Tag' : 'Update Tag'} </div>
      </div>
      <Controller
        name="tags"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <Chips
            itemTemplate={chipTemplate}
            placeholder="Press enter to add a tag"
            id={field.name}
            value={field.value}
            ref={tagRef}
            onChange={(e) => {
              field.onChange(e.value);
            }}
            className="input-shadow border-0 w-100 taging update-tag add-tag-chips-input mb-0"
          />
        )}
      />
      {errors?.tags && <InputErrorMessage>Required</InputErrorMessage>}
    </Dialog>
  );
}

export default AddTagModal;

const TagFooter = ({ setShowAddTag, handleSubmit, submitTagHandler, tagModalType }) => (
  <div className="footer-btn">
    <Button
      label="Cancel"
      onClick={() => {
        setShowAddTag(false);
      }}
      className="p-button-secondary outline"
    />
    <Button label={tagModalType === 'add' ? 'Save' : 'Update'} onClick={handleSubmit(submitTagHandler)} className="p-button-secondary" />
  </div>
);

const chipTemplate = (e) => {
  if (e) {
    try {
      let formattedTagData = e?.replaceAll('#', '');
      return <div>{'#' + formattedTagData}</div>;
    } catch (error) {
      return <div>{e}</div>;
    }
  }
};
