/**
 * This is a React component that renders a header and a task board within a container, wrapped in a
 * context provider.
 * @returns The `Tasks` component is being returned, which contains the `Header` and `TaskBoard`
 * components wrapped in a `DashboardDataHandler` context provider.
 */
import React from 'react';

import { Header, TaskBoard } from './components';
import DashboardDataHandler from './Context/TaskContext';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import { classNames } from 'primereact/utils';
import useComponentLifecycle from 'hooks/useComponentLifecycle';

export default function Tasks({ isFromCase, caseDetails, isFromLead, isLeadClosed, isCaseClosed, onMountUnMount }) {
  useComponentLifecycle('task-activity', onMountUnMount);

  return (
    <DashboardDataHandler {...{ isLeadClosed, isFromCase, caseDetails, isFromLead, isCaseClosed }}>
      <div className={classNames({ 'container-fluid': !Boolean(isFromCase), 'mt-0': true })}>
        <Header />
        <TaskBoard />
        <GlobalLoader />
      </div>
    </DashboardDataHandler>
  );
}
