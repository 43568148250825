/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Controller } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { Chips } from 'primereact/chips';
import { AutoComplete } from 'primereact/autocomplete';
import { InputSwitch } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';

import { useUserDetailsContext } from 'context/userDetailsContext';
import PriorityDropdown from 'components/UI/PriorityDropdown/PriorityDropdown';
import InputErrorMessage from 'components/UI/InputErrorMessage/InputErrorMessage';
import CustomFields from '../ContactForm/CustomFields';
import AddLeadSource from './AddLeadSource';
import AddCourt from './AddCourt';
import { useGlobalTagList } from 'hooks/useGlobalTags';

import { getCaseNumberValidation, getConflictCheck, listContacts } from 'services/lead/leadServices';
import { useToggle } from 'hooks/useToggle';
import { generateFullName } from 'utils/generateFullNameUtils';
import { totalCountEvent } from 'services/generalServices';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const CaseInformation = (props) => {
  const {
    control,
    formState: { errors },
    loadCourtContacts,
    loadLeadSources,
    setValue,
    watch,
    leadStages,
    practiceAreaList,
    caseMatterList,
    officeLocationList,
    courtList,
    leadSourceList,
    trigger,
    venueOptions,
    loadVenue,
    conflictCheckResult,
    setConflictCheckResult,
  } = props;

  const [filteredClients, setFilteredClients] = useState(null);
  const [showGlobalTags, setShowGlobalTags] = useState(false);
  const [tags, setTags] = useState([]);
  const { formattedGlobalTag } = useGlobalTagList();

  const userContext = useUserDetailsContext();

  const [courtToggle, setToggle] = useToggle();
  const [sourceToggle, setSourceToggle] = useToggle();

  const [toggleVenue, setToggleVenue] = useToggle();

  // Define your chip template function
  const chipTemplate = (chip) => (
    <div className="p-chip">
      <span>#{chip}</span>
    </div>
  );

  const validateCaseNumber = async (value) => {
    if (!Boolean(value)) {
      return true;
    }
    return new Promise(async (resolve) => {
      const response = await getCaseNumberValidation(value);
      if (response?.data?.length === 0) {
        resolve(true);
      } else resolve('Duplicate Case Number');
    });
  };

  const searchClient = async (event) => {
    let totalCount = await totalCountEvent('contact');
    let obj = { pageLimit: totalCount.data, keyWord: event.query, in_lead: true, contactType: '' };
    let response = await listContacts(obj);
    if (response?.data?.contacts?.length > 0) {
      let data = [];
      response.data.contacts.forEach((element) => {
        data.push({
          label: element.contact_type === 'person' ? generateFullName(element) : element.title,
          value: element.contact_id,
          client: element,
        });
      });
      setFilteredClients(data);
    } else {
      setFilteredClients([]);
    }
  };

  const courtAddOnSuccess = (data) => {
    loadCourtContacts();
    if (data) {
      setValue('case_description.court', data);
    }
    setToggle(false);
  };

  const venueAddOnSuccess = (data) => {
    if (data) {
      setValue('case_description.venue', data?.venue_name);
    }
    loadVenue();
    setToggleVenue(false);
  };

  const sourceAddOnSuccess = (data) => {
    if (data) {
      setValue('lead_details.source', data?.Lead_source_name);
    }
    loadLeadSources();
    setSourceToggle(false);
  };

  const onSelectContact = (e) => {
    if (e.value && e.value.value) {
      getConflictCheck(e.value.value)
        .then((response) => {
          setConflictCheckResult(response.data);
          if (response.data?.message && response.data?.message === 'This contact has conflict') {
            setValue('lead_details.conflict_check', true);
          }
          if (response.data?.message && response.data?.message === 'No conflicts') {
            setValue('lead_details.conflict_check', false);
          }
        })
        .catch((error) => {});
    }
  };

  const onClearContact = () => {
    setConflictCheckResult('');
    setValue('lead_details.conflict_check', false);
  };

  const handleTagChange = (e, field) => {
    const uniqueTags = [...new Set(e.value)];
    setTags(uniqueTags);
    setValue('lead_details.tags', uniqueTags);
    field.onChange(uniqueTags);
  };

  return (
    <div className="container-fluid">
      <div className="row my-4">
        <div className="col-sm-12 col-md-6 col-6 ps-0 pe-0 pe-lg-4">
          <div className="row">
            <div className="col">
              <h5 className="caption-bold">CASE</h5>
              <div className="bg-white shadow-middle lead-data-box p-3 d-flex align-items-center mb-3 flex-wrap">
                <div className="col-md-4 col-12 p-medium">Potential Case Value</div>
                <div className="col-md-8 col-12">
                  <Controller
                    name="case_description.potential_case_value"
                    control={control}
                    render={({ field }) => (
                      <InputNumber
                        className="w-100 new-task-popup input-shadow"
                        inputId="currency-us"
                        placeholder="Type in a case value"
                        mode="currency"
                        currency="USD"
                        locale="en-US"
                        id={field.name}
                        onChange={(e) => field.onChange(e.value)}
                        value={field.value}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2 pt-2">
            <div className="col">
              <h5 className="caption-bold">POTENTIAL CASE DETAILS</h5>
              <div className="bg-white shadow-middle lead-data-box p-3">
                <div className="col">
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Practice Area</div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name="case_description.case_practice_area"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            onChange={(e) => field.onChange(e.value)}
                            value={field.value}
                            options={practiceAreaList}
                            optionLabel="practice_area_name"
                            optionValue="practice_area_name"
                            className="input-shadow border-0 w-100"
                            placeholder="Select Practice Area"
                            filter
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Case Matter</div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name="case_description.case_matter"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            onChange={(e) => field.onChange(e.value)}
                            value={field.value}
                            options={caseMatterList}
                            optionLabel="matter_name"
                            optionValue="matter_name"
                            className="input-shadow border-0 w-100"
                            placeholder="Select Case Matter"
                            filter
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Case Number</div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name={`case_description.case_number`}
                        rules={{ validate: { asyncValidation: validateCaseNumber } }}
                        defaultValue={''}
                        control={control}
                        render={({ field }) => (
                          <TextSnippetWrapper
                            type="text"
                            placeholder="Type in"
                            {...field}
                            onBlur={() => trigger('case_description.case_number')}
                            onChange={(e) => setValue('case_description.case_number', e.target.value)}
                            className="input-shadow border-0 w-100"
                          />
                        )}
                      />
                      {errors?.case_description?.case_number && <InputErrorMessage>Case Number Already Exist</InputErrorMessage>}
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Case Name</div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name={`case_description.case_name`}
                        defaultValue={''}
                        control={control}
                        render={({ field }) => (
                          <TextSnippetWrapper type="text" placeholder="Type in" {...field} className="input-shadow border-0 w-100" />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Court</div>
                    <div className="col-sm-7 col-7">
                      {courtToggle ? (
                        <AddCourt onSuccess={courtAddOnSuccess} onDismiss={setToggle} isCourt={true} />
                      ) : (
                        <Controller
                          name="case_description.court"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              onChange={(e) => field.onChange(e.value)}
                              value={field.value}
                              options={courtList}
                              optionValue="title"
                              optionLabel="title"
                              placeholder="Select"
                              className="input-shadow w-100"
                              filter
                            />
                          )}
                        />
                      )}
                    </div>
                    {userContext?.permissions?.settings?.add_court_location && (
                      <div className="col-1 col-sm-1 px-0 d-flex align-items-center justify-content-end">
                        <span className="pointer">
                          <i className="fas fa-plus-circle" onClick={setToggle} />
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Venue</div>
                    <div className="col-sm-7 col-7">
                      {toggleVenue ? (
                        <AddCourt onSuccess={venueAddOnSuccess} onDismiss={setToggleVenue} isCourt={false} />
                      ) : (
                        <Controller
                          name="case_description.venue"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              onChange={(e) => field.onChange(e.value)}
                              value={field.value}
                              options={venueOptions}
                              optionValue="venue_name"
                              optionLabel="venue_name"
                              placeholder="Select"
                              className="input-shadow w-100"
                              filter
                            />
                          )}
                        />
                      )}
                    </div>
                    <div className="col-1 col-sm-1 px-0 d-flex align-items-center justify-content-end">
                      <span className="pointer">
                        <i className="fas fa-plus-circle" onClick={setToggleVenue} />
                      </span>
                    </div>
                  </div>

                  <div className="d-flex py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Description</div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name={`lead_details.description`}
                        defaultValue={''}
                        control={control}
                        render={({ field }) => (
                          <TextSnippetWrapper
                            type="textarea"
                            placeholder="Description"
                            {...field}
                            className="input-shadow border-0 w-100 p-2 "
                            rows="8"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Tags</div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name="lead_details.tags"
                        control={control}
                        render={({ field }) => (
                          <Chips
                            id={field.name}
                            onChange={(e) => handleTagChange(e, field)}
                            value={field.value}
                            itemTemplate={chipTemplate}
                            placeholder="Select Tags"
                            className="input-shadow border-0 w-100 taging"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row mb-2">
                      <div className="col-sm-12 addfield">
                        <Button
                          type="button"
                          className="p-button button-text text-medium addfield ps-0"
                          onClick={(e) => {
                            e.preventDefault();
                            setShowGlobalTags(!showGlobalTags);
                          }}
                        >
                          {showGlobalTags ? <i className="pi pi-minus F-size14 me-1"> </i> : <i className="pi pi-plus F-size14 me-1" />}Add
                          Global Tags
                        </Button>
                      </div>
                    </div>
                    {showGlobalTags && (
                      <div className="row mb-3">
                        <div className="col-sm-4"></div>
                        {formattedGlobalTag?.length > 0 ? (
                          <div className="col-sm-7">
                            <div className="global-tag-wrapper input-shadow">
                              {formattedGlobalTag.map((element, index) => {
                                let hashReplacedTag = element.value.replace('#', '');
                                return (
                                  <span
                                    key={index}
                                    className={`tag-highlight ${tags.includes(hashReplacedTag) ? 'selected-global-tag' : ''}`}
                                    onClick={(e) => {
                                      let existingTags = watch('lead_details.tags');
                                      existingTags?.push(hashReplacedTag);
                                      setTags([...new Set(existingTags)]);
                                      setValue('lead_details.tags', [...new Set(existingTags)]);
                                    }}
                                  >
                                    {element.value}
                                  </span>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          <div className="row mb-2">
                            <div className="col-12 small">
                              <i className="fas fa-info-circle mx-2 mt-1"></i>
                              <span className="text-hint">No global tags found.</span>{' '}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {userContext.checkSubPermission('custom_fields') && (
                    <>
                      <div className="d-flex align-items-center py-2">
                        <Controller
                          name="case_description.custom_field_toggle"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <Button
                              id={field.name}
                              type="button"
                              className="p-button button-text text-medium addfield ps-0"
                              onClick={(e) => field.onChange(!field.value)}
                            >
                              <i className={field.value ? 'pi pi-minus F-size14 me-1' : 'pi pi-plus F-size14 me-1'}></i>Add Custom Fields
                            </Button>
                          )}
                        />
                      </div>
                      {watch('case_description.custom_field_toggle') && (
                        <CustomFields
                          field="case_description.custom_field"
                          control={control}
                          practiceArea={watch('case_description.case_practice_area')}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mt-3 mt-md-0">
          <div className="row">
            <div className="col-sm-12">
              <h5 className="caption-bold">DETAILS</h5>
              <div className="row bg-white shadow-middle lead-data-box p-3">
                <div className="col">
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Lead Priority</div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name="lead_details.priority"
                        control={control}
                        render={({ field }) => (
                          <PriorityDropdown
                            id={field.name}
                            onChange={(e) => field.onChange(e.value)}
                            value={field.value}
                            className="input-shadow w-100 input-height"
                            filter
                            placeholder="Select	Priority"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Lead Source</div>
                    <div className="col-sm-11 col-7 col-md-7">
                      {sourceToggle ? (
                        <AddLeadSource onSuccess={sourceAddOnSuccess} onDismiss={setSourceToggle} />
                      ) : (
                        <Controller
                          name="lead_details.source"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              onChange={(e) => field.onChange(e.value)}
                              value={field.value}
                              optionLabel="source"
                              optionValue="source"
                              options={leadSourceList}
                              placeholder="Select"
                              className="input-shadow w-100"
                              filter
                            />
                          )}
                        />
                      )}
                    </div>
                    <div className="col-1 col-sm-1 px-0 d-flex align-items-center justify-content-end">
                      <span className="pointer">
                        <i className="fas fa-plus-circle" onClick={setSourceToggle} />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium">Lead Stage</div>
                    <div className="col-md-8 col-12">
                      <Controller
                        name="leadStageSk"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            id={field.name}
                            onChange={(e) => field.onChange(e.value)}
                            value={field.value}
                            placeholder="Select"
                            options={leadStages}
                            optionDisabled={(v) => v?.lead_stage_name?.toLowerCase() === 'closed'}
                            optionLabel="lead_stage_name"
                            optionValue="sk"
                            className="input-shadow border-0 w-100"
                            filter
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-top py-2 flex-wrap">
                    <div className="col-md-4 col-12 p-medium line_height3">Conflict Check</div>
                    <div className="col-md-8 col-12">
                      <div className="mb-3">
                        {/* <AutoComplete
                          name="lead_details.conflict_contacts"
                          placeholder="Check for conflict"
                          className="w-100 input-shadow"
                          inputStyle={{ width: '100%' }}
                          suggestions={contactSuggestion}
                          completeMethod={searchContacts}
                          onChange={(e) => {
                            setConflictContact(e.value);
                          }}
                          onClear={() => {
                            checkConflict({ value: null }); // Trigger checkConflict with null value
                          }}
                          field="label"
                          value={conflictContact}
                          onSelect={checkConflict}
                        /> */}
                        <Controller
                          name="lead_details.conflict_contacts"
                          control={control}
                          // defaultValue={initialConflictClient}
                          render={({ field }) => (
                            <AutoComplete
                              placeholder="Enter Name"
                              style={{ width: '100%' }}
                              inputStyle={{ width: '100%' }}
                              value={field.value}
                              suggestions={filteredClients}
                              onSelect={(e) => onSelectContact(e)}
                              onClear={onClearContact} // Add onClear handler
                              completeMethod={searchClient}
                              field="label"
                              onChange={(e) => field.onChange(e.value)}
                              className="flex-grow-1 input-shadow"
                            />
                          )}
                        />
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <Controller
                          name="lead_details.conflict_check"
                          control={control}
                          defaultValue={false}
                          render={({ field }) => (
                            <InputSwitch
                              className="input-shadow input-swich"
                              inputId={field.name}
                              checked={field.value}
                              onChange={(e) => field.onChange(e.value)}
                            />
                          )}
                        />
                        {conflictCheckResult?.message === 'This contact has conflict' ? (
                          <label className="p-medium ms-2">Conflict</label>
                        ) : (
                          <label className="p-medium ms-2">No Conflict</label>
                        )}
                      </div>
                      {!!conflictCheckResult?.case_data?.length &&
                        conflictCheckResult?.case_data?.map((data, i) => {
                          return data?.is_lead === true ? (
                            <div className="p-2" key={i}>
                              <Link
                                to={`/leads/${data?.case_id}`}
                                target="_blank"
                                className="text-break pointer F-size14 call-back text-decoration-none"
                              >
                                {data?.case_description?.case_name}
                              </Link>
                            </div>
                          ) : (
                            <div className="p-2" key={i}>
                              <Link
                                to={`/cases/${data?.case_id}`}
                                target="_blank"
                                className="text-break pointer F-size14 call-back text-decoration-none"
                              >
                                {data?.case_description?.case_name}
                              </Link>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="d-flex align-items-top py-2 F-size14 flex-wrap">
                    <div className="col-md-4 col-12 text-muted p-medium">Conflict Notes</div>
                    <div className="col-md-8 col-12 d-flex align-items-center text-capitalize">
                      <Controller
                        name="lead_details.conflict_note"
                        control={control}
                        render={({ field }) => (
                          <TextSnippetWrapper
                            type="textarea"
                            rows={8}
                            cols={30}
                            style={{ width: '90%' }}
                            placeholder="Enter Conflict Notes"
                            value={field.value}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                            className="input-shadow"
                          />
                        )}
                      />
                    </div>
                  </div>
                  {officeLocationList?.length === 1 ? null : (
                    <div className="d-flex align-items-center py-2 flex-wrap">
                      <div className="col-md-4 col-12 p-medium">Office Location</div>
                      <div className="col-md-8 col-12">
                        <Controller
                          name="case_description.office_location"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              id={field.name}
                              onChange={(e) => field.onChange(e.value)}
                              value={field.value}
                              placeholder="Select Location"
                              options={officeLocationList}
                              optionValue="office_name"
                              optionLabel="office_name"
                              className="input-shadow border-0 w-100"
                              filter
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CaseInformation;
