import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';

import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';

import AddLeadSource from 'modules/lead/AddLead/components/CaseInformation/AddLeadSource';
import PriorityDropdown from 'components/UI/PriorityDropdown/PriorityDropdown';
import { updateLeadDetails } from 'services/lead/leadServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { useToggle } from 'hooks/useToggle';

export default function Summary(props) {
  const { leadDetails, loadLeadSources, leadSourceList, onCaseClose, leadStages, loadLeadDetails } = props;
  const [sourceToggle, setSourceToggle] = useToggle();
  const [apiLoading, setApiLoading] = useToggle();
  const { addToast } = useToast();

  const { control, reset, handleSubmit, setValue } = useForm();

  useEffect(() => {
    if (leadDetails) {
      reset({
        ...leadDetails,
        last_login: leadDetails?.main_client[0]?.last_login
          ? moment(leadDetails?.main_client[0]?.last_login).format('MM/DD/YYYY, hh:mm a')
          : '--',
      });
    }
  }, [leadDetails]);

  const sourceAddOnSuccess = (data) => {
    if (data) {
      setValue('lead_details.source', data?.Lead_source_name);
    }
    loadLeadSources();
    setSourceToggle(false);
  };

  const updateLead = (formData) => {
    const { case_description, lead_details, lead_stage, lead_status, sk } = formData || {};
    setApiLoading(true);
    updateLeadDetails(sk, {
      case_description,
      lead_details,
      lead_stage,
      lead_status,
    })
      .then(() => {
        setApiLoading(false);
        loadLeadDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LEAD_UPDATE_SUCCESS);
      })
      .catch(() => {
        setApiLoading(false);
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.LEAD_UPDATE_FAILURE);
      });
  };

  return (
    <React.Fragment>
      <h5 className="caption-bold">SUMMARY</h5>
      <div className="shadow-middle bg-white lead-data-box p-3">
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-lg-4 col-12 p-medium">Last Login</div>
              <div className="col-lg-8 col-12">
                <Controller
                  name="last_login"
                  control={control}
                  render={({ field }) => <InputText disabled id={field.name} {...field} className="w-100 new-task-popup input-shadow" />}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-lg-4 col-12 p-medium">Lead Stage</div>
              <div className="col-lg-8 col-12">
                <Controller
                  name="lead_stage"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      onChange={(e) => {
                        typeof onCaseClose === 'function' && onCaseClose(e.value);
                        field.onChange(e.value);
                      }}
                      value={field.value}
                      options={leadStages}
                      optionLabel="lead_stage_name"
                      optionValue="sk"
                      placeholder="Select"
                      className="outline-dropdown w-100"
                      disabled={!leadDetails?.is_open}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-lg-4 col-12 p-medium">Lead Priority</div>
              <div className="col-lg-8 col-12">
                <Controller
                  name="lead_details.priority"
                  control={control}
                  render={({ field }) => (
                    <PriorityDropdown
                      placeholder="Select"
                      id={field.name}
                      onChange={(e) => field.onChange(e.value)}
                      value={field.value}
                      className="outline-dropdown w-100"
                      disabled={!leadDetails?.is_open}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-lg-4 col-12 p-medium">Potential Case Value</div>
              <div className="col-12 col-lg-8">
                <Controller
                  name="case_description.potential_case_value"
                  control={control}
                  render={({ field }) => (
                    <InputNumber
                      className="w-100 new-task-popup input-shadow"
                      inputId="currency-us"
                      mode="currency"
                      currency="USD"
                      locale="en-US"
                      disabled={!leadDetails?.is_open}
                      id={field.name}
                      onChange={(e) => field.onChange(e.value)}
                      value={field.value}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-lg-4 col-12 p-medium">Lead Source</div>
              <div className="col-lg-7 col-11">
                {sourceToggle ? (
                  <AddLeadSource onSuccess={sourceAddOnSuccess} onDismiss={setSourceToggle} />
                ) : (
                  <Controller
                    name="lead_details.source"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        id={field.name}
                        onChange={(e) => field.onChange(e.value)}
                        value={field.value}
                        optionLabel="source"
                        optionValue="source"
                        options={leadSourceList}
                        placeholder="Select"
                        className="input-shadow w-100"
                        filter
                        disabled={!leadDetails?.is_open}
                      />
                    )}
                  />
                )}
              </div>
              <span className={classNames({ pointer: true, 'pe-none opacity-50': !leadDetails?.is_open })}>
                <i className="fas fa-plus-circle ms-2" onClick={setSourceToggle} />
              </span>
            </div>
          </div>
        </div>
        {leadDetails?.is_open && (
          <>
            <hr />
            <div className="d-flex justify-content-end">
              <Button label="Save Changes" className="p-button p-button-primary" loading={apiLoading} onClick={handleSubmit(updateLead)} />
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  );
}
