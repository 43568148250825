import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function TagFilter() {
  const { fullTags, onTagChange, fileManagerFilters } = useFileManagerContext();
  const [caseTagOptions, setCaseTagOptions] = useState([]);

  useEffect(() => {
    if (fullTags && fullTags.length) {
      let data = fullTags.map((val) => ({
        label: `#${val}`,
        value: `#${val}`,
      }));
      setCaseTagOptions(data);
    } else {
      setCaseTagOptions([]);
    }
  }, [fullTags]);

  return (
    <div className="me-2 filter-wraper">
      <Dropdown
        value={fileManagerFilters.tag}
        name="tag"
        options={caseTagOptions}
        placeholder="By Tag"
        className="file-manager-select col-sm-12"
        onChange={(e) => onTagChange(e)}
        filter
        // disabled
      />
    </div>
  );
}

export default TagFilter;
