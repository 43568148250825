import React, { useState, useEffect, useContext, useLayoutEffect, useMemo } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Card } from 'primereact/card';
import TimeEntries from 'modules/billing/TimeEntries';
import Expenses from 'modules/billing/Expenses';
import { Tag } from 'primereact/tag';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import { PaymentRequests } from 'modules/billing/PaymentRequests';
import { PaymentHistory } from 'modules/billing/PaymentHistory';
import { Invoices } from 'modules/billing/Invoices';
import { getCaseBillingSummary, updateCase } from 'services/CaseServices';
import { useLocation, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { dollarFormat, handleRedirection } from 'utils/utils';
import { Button } from 'primereact/button';
import { updateLeadDetails } from 'services/leadServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { getSettleMentDetails, updateSettleMent } from 'services/expenseService';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';
import NumberFormat from 'react-number-format';
import TrustAccounting from 'modules/billing/TrustAccounting';
import { paymentStructure } from 'constants/dropdownOptions';
import { clearLocalStorageItems, getLocalStorageItem, handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import TemporaryFunds from 'modules/billing/TemporaryFund/TemporaryFunds';
import { generateFullName } from 'utils/generateFullNameUtils';

const Billing = (props) => {
  const { addToast } = useToast();
  const { isLeadSpecific, caseDetails, isLeadClosed, getCaseDetails } = props;
  const [activeIndex, setActiveIndex] = useState();
  const [billingSummary, setBillingSummary] = useState({});
  const location = useLocation();
  const [isCaseClosed, setIsCaseClosed] = useState(false);

  const [preLitigation, setPreLitigation] = useState(0);
  const [litigation, setLitigation] = useState(0);
  const [attorneyFee, setAttorneyFee] = useState(0);
  const [isExpense, setIsExpense] = useState(false);
  const [settlementData, setSettlementData] = useState({});
  const userContext = useContext(UserDetailsContext);

  const history = useHistory();

  const clientDetails = useMemo(() => {
    return caseDetails?.client_details?.find((data) => data?.is_billing_contact === true);
  }, [caseDetails]);

  const loadBillingSummary = async () => {
    let case_id = caseDetails?.case_id;
    await getCaseBillingSummary({ case_id: case_id }).then((response) => {
      if (response?.data && response?.data.length > 0) {
        setBillingSummary({ ...response?.data[0] });
        setPreLitigation(response?.data[0]?.billing_info?.pre_litigation);
        setLitigation(response?.data[0]?.billing_info?.litigation);
        setIsExpense(response?.data[0]?.billing_info?.is_expense);
      }
    });
  };

  const getSettleMent = async () => {
    let case_id = caseDetails?.case_id;
    await getSettleMentDetails(case_id).then((response) => {
      setAttorneyFee(response?.data?.attorney_fee);
      setSettlementData(response?.data);
    });
  };

  const updateCaseDetails = async () => {
    try {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      let sendData = JSON.parse(JSON.stringify(props.caseDetails));

      sendData.billing_preferences.litigation = litigation.toString();
      sendData.billing_preferences.pre_litigation = preLitigation.toString();
      sendData.billing_preferences.is_expense = isExpense;
      sendData.stage_change = sendData?.case_description?.case_stage?.stage_name;

      if (!props.isLeadSpecific) {
        const updateCasePromise = updateCase(sendData, null, null, props.isLeadSpecific);

        let promises = [updateCasePromise];

        if (typeof attorneyFee !== 'undefined') {
          const updateSettlementPromise = updateSettleMent({
            ...settlementData,
            attorney_fee: attorneyFee,
          });

          promises.push(updateSettlementPromise);
        }
        await Promise.all(promises)
          .then(async () => {
            const getCaseBillingSummaryPromise = loadBillingSummary();
            const getSettleMentDetails = getSettleMent();
            await Promise.all([getCaseBillingSummaryPromise, getSettleMentDetails]).then(() => {
              addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CASE_UPDATED_CREATED);
            });
          })
          .catch(() => {
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CASE_UPDATED_FAILED);
          });
      } else {
        const updateLeadDetailsPromise = updateLeadDetails(sendData.sk, sendData);

        let promises = [updateLeadDetailsPromise];

        if (typeof attorneyFee !== 'undefined') {
          const updateSettlementPromise = updateSettleMent({
            ...settlementData,
            attorney_fee: attorneyFee,
          });

          promises.push(updateSettlementPromise);
        }
        await Promise.all(promises)
          .then(async () => {
            const getCaseBillingSummaryPromise = loadBillingSummary();
            const getSettleMentDetails = getSettleMent();
            await Promise.all([getCaseBillingSummaryPromise, getSettleMentDetails]).then(() => {
              addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.CASE_UPDATED_CREATED);
            });
          })
          .catch(() => {
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.CASE_UPDATED_FAILED);
          });
      }
      // props.hideSpinner();
    } catch (error) {
      console.log('ERROR = ', error);
    }
  };

  const handleBillingRedirection = (e, rowData, route) => {
    e?.preventDefault();
    const { sk, pk } = rowData;
    if (sk && pk) handleSettingSkAndPkToLocalStorage(sk, pk);
    handleRedirection(history, route);
  };

  useEffect(() => {
    // let activeIndexValue = 0;
    let activeIndexValue = props?.caseDetails?.case_description?.case_practice_area === 'Personal Injury' ? 0 : 5;
    if (getLocalStorageItem('redirectToInvoice') || getLocalStorageItem('viewInvoice')) {
      activeIndexValue = 4;
    } else if (getLocalStorageItem('redirectToRequestPayment') || getLocalStorageItem('viewPaymentRequest')) {
      activeIndexValue = 3;
    }
    setActiveIndex(activeIndexValue);
    loadBillingSummary();
    getSettleMent();
    clearLocalStorageItems(['redirectToInvoice', 'viewInvoice', 'redirectToRequestPayment', 'viewPaymentRequest']);
  }, [location?.state, localStorage]);

  useLayoutEffect(() => {
    if (caseDetails?.status === 'INACTIVE') {
      setIsCaseClosed(true);
    } else if (caseDetails?.status === 'Active') {
      setIsCaseClosed(false);
    }
  }, [caseDetails]);

  return (
    <React.Fragment>
      <div className="row my-4">
        <div className="col-12 col-lg-5">
          <div className="caption-bold mb-2">Summary</div>
          <Card className="shadow-middle">
            <div className="container px-0">
              <div className="row">
                <div className="col-sm text-center col-6">
                  <div className="text-bold">{billingSummary?.unpaid_info?.unpaid_expenses}</div>
                  <div className="text-muted F-size12 pb-1 text-medium">Expenses</div>
                  <div className="text-muted F-size12">
                    <Tag className="da-tag" value="Unpaid" severity="danger"></Tag>
                  </div>
                </div>
                <div className="col-sm text-center col-6">
                  <div className="text-bold">{billingSummary?.unpaid_info?.unpaid_time_entries}h</div>
                  <div className="text-muted F-size12 pb-1 text-medium">Time entries</div>
                  <div className="text-muted F-size12">
                    <Tag className="da-tag" value="Unpaid" severity="danger"></Tag>
                  </div>
                </div>
                <div className="col-sm text-center col-6 mt-sm-20">
                  <div className="text-bold">{billingSummary?.unpaid_info?.unpaid_payment_request}</div>
                  <div className="text-muted F-size12 pb-1 text-medium">Requests</div>
                  <div className="text-muted F-size12">
                    <Tag className="da-tag" value="Unpaid" severity="danger"></Tag>
                  </div>
                </div>
                <div className="col-sm text-center col-6 mt-sm-20">
                  <div className="text-bold">{billingSummary?.unpaid_info?.unpaid_invoices}</div>
                  <div className="text-muted F-size12 pb-1 text-medium">Invoices</div>
                  <div className="text-muted F-size12">
                    <Tag className="da-tag" value="Unpaid" severity="danger"></Tag>
                    {/*   <Tag className="s-tag" value="Paid" severity="danger"></Tag> */}
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div className="caption-bold mb-2 mt-5">Next PAYMENTS</div>
          <Card className="shadow-middle">
            {billingSummary?.next_payment?.length > 0 &&
              billingSummary?.next_payment?.map((index) => {
                return (
                  <>
                    <div className="mb-2">
                      <div className="text-muted F-size12">Date</div>
                      <div className="text-bold F-size14">
                        {handleDateTimeOffset(userContext?.userDetails?.timezone, index.due_date, constants.month_date_year_format)}
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="text-muted F-size12">Amount</div>
                      <div className="text-bold F-size14">{dollarFormat(index.amount)}</div>
                    </div>
                  </>
                );
              })}
            {!billingSummary?.next_payment ||
              (billingSummary?.next_payment && billingSummary?.next_payment.length === 0 && (
                <>
                  <div className="mb-2">
                    <div className="text-muted F-size12">Date</div>
                    <div className="text-bold F-size14">-</div>
                  </div>
                  <div className="mb-2">
                    <div className="text-muted F-size12">Amount</div>
                    <div className="text-bold F-size14">-</div>
                  </div>
                </>
              ))}
          </Card>
        </div>
        <div className="col-12 col-lg-7 mt-lg-0 mt-3">
          <div className="caption-bold mb-2">Billing info</div>
          <Card className="shadow-middle F-size14">
            <div className="d-flex align-items-center py-2">
              <div className="col-4 form-label">Billing Account</div>
              <div className="col-8">
                <InputText className="input-shadow w-100" value={generateFullName(clientDetails)} disabled />
              </div>
            </div>
            {!isLeadSpecific && (
              <>
                <div className="d-flex align-items-center py-2">
                  <div className="col-4 form-label">Operating Account</div>
                  <div className="col-8">
                    <InputText
                      className="input-shadow w-100"
                      value={caseDetails?.default_operating_account?.[0]?.account_name || '-'}
                      disabled
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center py-2">
                  <div className="col-4 form-label">Trust Account</div>
                  <div className="col-8">
                    <InputText
                      className="input-shadow w-100"
                      value={caseDetails?.default_trust_account?.[0]?.account_name || '-'}
                      disabled
                    />
                  </div>
                </div>
              </>
            )}
            <hr></hr>
            <div className="d-flex align-items-center py-2">
              <div className="col-4 form-label">Payment Structure</div>
              <div className="col-8">
                {/*  <Dropdown className="w-100" options={[]} /> */}
                <InputText className="input-shadow w-100" value={billingSummary?.billing_info?.payment_structure} disabled />
              </div>
            </div>
            {!!billingSummary?.billing_info?.retainer_amount && (
              <div className="d-flex align-items-center py-2">
                <div className="col-4 form-label">Retainer Amount</div>
                <div className="col-8">
                  <span className="p-input-icon-right w-100">
                    <InputText className="input-shadow" value={dollarFormat(billingSummary?.billing_info?.retainer_amount)} disabled />
                  </span>
                </div>
              </div>
            )}
            {!!billingSummary?.billing_info?.rate &&
              [paymentStructure.FLAT_FEE].includes(billingSummary?.billing_info?.payment_structure) && (
                <div className="d-flex align-items-center py-2">
                  <div className="col-4 form-label">Rate</div>
                  <div className="col-8">
                    <span className="p-input-icon-right w-100">
                      <InputText className="input-shadow" value={dollarFormat(billingSummary?.billing_info?.rate)} disabled />
                    </span>
                  </div>
                </div>
              )}
            {!!billingSummary?.billing_info?.tax_percentage &&
              [paymentStructure.FLAT_FEE, paymentStructure.SUBSCIPTION].includes(billingSummary?.billing_info?.payment_structure) && (
                <div className="d-flex align-items-center py-2">
                  <div className="col-4 form-label">Tax %</div>
                  <div className="col-8">
                    <span className="p-input-icon-right w-100">
                      <InputText className="input-shadow" value={`${billingSummary?.billing_info?.tax_percentage}%`} disabled />
                    </span>
                  </div>
                </div>
              )}
            {!!billingSummary?.billing_info?.amount_with_tax &&
              [paymentStructure.FLAT_FEE, paymentStructure.SUBSCIPTION].includes(billingSummary?.billing_info?.payment_structure) && (
                <div className="d-flex align-items-center py-2">
                  <div className="col-4 form-label">Total Amount</div>
                  <div className="col-8">
                    <span className="p-input-icon-right w-100">
                      <InputText className="input-shadow" value={dollarFormat(billingSummary?.billing_info?.amount_with_tax)} disabled />
                    </span>
                  </div>
                </div>
              )}
            {billingSummary?.billing_info?.frequency_of_payment && (
              <div className="d-flex align-items-center py-2">
                <div className="col-4 form-label">Frequency of Payment</div>
                <div className="col-8">
                  <span className="p-input-icon-right w-100">
                    <InputText className="input-shadow" value={billingSummary?.billing_info?.frequency_of_payment} disabled />
                  </span>
                </div>
              </div>
            )}
            {billingSummary?.billing_info?.payment_plan_details?.first_payment_amount >= 0 && (
              <div className="d-flex align-items-center py-2">
                <div className="col-4 form-label">First Payment Amount</div>
                <div className="col-8">
                  <span className="p-input-icon-right w-100">
                    <InputText
                      className="input-shadow"
                      value={billingSummary?.billing_info?.payment_plan_details?.first_payment_amount}
                      disabled
                    />
                  </span>
                </div>
              </div>
            )}
            {billingSummary?.billing_info?.payment_plan_details?.payment_starting_date && (
              <div className="d-flex align-items-center py-2">
                <div className="col-4 form-label">First Payment Amount</div>
                <div className="col-8">
                  <span className="p-input-icon-right w-100">
                    <InputText
                      className="input-shadow"
                      value={format(new Date(billingSummary?.billing_info?.payment_plan_details?.payment_starting_date), 'MM/dd/yyyy')}
                      disabled
                    />
                  </span>
                </div>
              </div>
            )}
            {billingSummary?.billing_info?.number_of_payments && (
              <div className="d-flex align-items-center py-2">
                <div className="col-4 form-label">Number of Payments</div>
                <div className="col-8">
                  <span className="p-input-icon-right w-100">
                    <InputText className="input-shadow" value={billingSummary?.billing_info?.number_of_payments} disabled />
                  </span>
                </div>
              </div>
            )}

            {billingSummary?.billing_info?.payment_structure === paymentStructure.CONTINGENCY_FEE && (
              <>
                {typeof attorneyFee !== 'undefined' && (
                  <div className="d-flex align-items-center py-2">
                    <div className="col-4 form-label">Attorney Fee %</div>
                    <div className="col-8">
                      <span className="p-input-icon-right">
                        <NumberFormat
                          className="input-shadow w-100 p-2 border-0 number-format"
                          placeholder="Attorney Fee %"
                          value={attorneyFee}
                          suffix="%"
                          allowNegative={false}
                          decimalScale={2}
                          isAllowed={(values) => {
                            const { floatValue } = values;
                            return floatValue === undefined || floatValue <= 100;
                          }}
                          onValueChange={(e) => {
                            const { value } = e;
                            if (value === '.' && !value.startsWith('0')) {
                              setAttorneyFee('0' + value);
                            } else {
                              setAttorneyFee(value);
                            }
                          }}
                          disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
                        />
                      </span>
                    </div>
                  </div>
                )}
                <div className="d-flex align-items-center py-2">
                  <div className="col-4 form-label">If the case is resolved pre-litigation, the contingency fee is set at</div>
                  <div className="col-8">
                    <NumberFormat
                      className="input-shadow  p-2 border-0 number-format"
                      placeholder="Pre Litigation"
                      allowNegative={false}
                      value={preLitigation}
                      suffix="%"
                      decimalScale={2}
                      isAllowed={(values) => {
                        const { floatValue } = values;
                        return floatValue === undefined || floatValue <= 100;
                      }}
                      onValueChange={(e) => {
                        const { value } = e;
                        if (value === '.' && !value.startsWith('0')) {
                          setPreLitigation('0' + value);
                        } else {
                          setPreLitigation(value);
                        }
                      }}
                      disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center py-2">
                  <div className="col-4 form-label">If the case is resolved through litigation, the contingency fee is set at</div>
                  <div className="col-8">
                    <NumberFormat
                      className="input-shadow  p-2 border-0 number-format"
                      placeholder="Litigation"
                      value={litigation}
                      allowNegative={false}
                      suffix="%"
                      decimalScale={2}
                      isAllowed={(values) => {
                        const { floatValue } = values;
                        return floatValue === undefined || floatValue <= 100;
                      }}
                      onValueChange={(e) => {
                        const { value } = e;
                        if (value === '.' && !value.startsWith('0')) {
                          setLitigation('0' + value);
                        } else {
                          setLitigation(value);
                        }
                      }}
                      disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center py-2">
                  <div className="col-4 form-label">Expenses will be deducted from the settlement amount</div>
                  <div className="col-8">
                    <span className="p-input-icon-right w-100">
                      <InputSwitch
                        className="input-swich"
                        checked={isExpense}
                        disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
                        onChange={(e) => setIsExpense(e.value)}
                      />
                    </span>
                  </div>
                </div>
                <div className="footer-btn mb-3">
                  <Button
                    label="Save"
                    className="btn-outline p-button-secondary py-1 px-3 "
                    type="button"
                    disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
                    onClick={() => {
                      updateCaseDetails();
                    }}
                  />
                </div>
              </>
            )}
            {['Standard retainer', 'Evergreen retainer'].includes(billingSummary?.billing_info?.payment_structure) && (
              <>
                <div className="d-flex align-items-center py-2">
                  <div className="col-4 form-label">Retainer Amount</div>
                  <div className="col-8">
                    <span className="p-input-icon-right w-100">
                      <i className="pi pi-dollar" />
                      <InputText className="w-100" value={billingSummary?.billing_info?.retainer_amount} disabled />
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center py-2">
                  <div className="col-4 form-label">Retainer Date</div>
                  <div className="col-8">
                    <span className="p-input-icon-right w-100">
                      <i className="pi pi-dollar" />
                      <InputText
                        className="w-100"
                        value={
                          billingSummary?.billing_info?.retainer_date
                            ? format(new Date(billingSummary?.billing_info?.retainer_date), 'MM/dd/yyyy')
                            : ''
                        }
                        disabled
                      />
                    </span>
                  </div>
                </div>
              </>
            )}
            {[paymentStructure.FLAT_FEE].includes(billingSummary?.billing_info?.payment_structure) && (
              <div className="d-flex align-items-center py-2">
                <div className="col-4 form-label">Payment Plan</div>
                <div className="col-8 d-flex">
                  <InputSwitch
                    className="input-swich"
                    checked={billingSummary?.billing_info?.payment_plan}
                    disabled={isCaseClosed || (isLeadClosed && isLeadSpecific)}
                  />
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
      {/* <div className="row d-none">
        <div className="col-sm-5">
          <Card title="Summary">
            <>
              <div className="row mb-3">
                <div className="col-sm-6 text-muted form-label">Uninvoiced Balances</div>
                <div className="col-sm-6">$0.00</div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-6 text-muted form-label">Case Trust Balances</div>
                <div className="col-sm-6">$0.00</div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-6 text-muted form-label">Total Amount Paid</div>
                <div className="col-sm-6">$0.00</div>
              </div>
              <div className="row mb-3">
                <div className="col-sm-6 text-muted form-label">Total Amount Pending</div>
                <div className="col-sm-6">$0.00</div>
              </div>
            </>
          </Card>
        </div>
        <div className="col-sm-7">
          <Card title="Billing Preferences">
            <div className="row">
              <div className="col-sm-6">
                <div className="row mb-3">
                  <div className="col-sm-6 text-muted form-label">Billing Model</div>
                  <div className="col-sm-6">Hourly</div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-6 text-muted form-label">Budget</div>
                  <div className="col-sm-6">$0.00</div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12 text-muted">Notify when cases reaches 80% of budget</div>
                </div>
              </div>
              <div className="col-sm-6">
                <h6 className="font-bold">Team Custom Rates</h6>
                <div className="row mb-3">
                  <div className="col-sm-6 text-muted form-label">N/A</div>
                  <div className="col-sm-6">$/hr 0.00</div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div> */}

      <div className="mt-5 billing-wrap billing-case">
        <div className="col-sm-12">
          <TabView
            className="curve-tab"
            activeIndex={activeIndex}
            onTabChange={(e) => {
              setActiveIndex(e.index);
            }}
            scrollable={true}
          >
            <TabPanel header="Expenses">
              <Expenses
                caseView={true}
                isLeadSpecific={props?.isLeadSpecific}
                isFromCaseSpecificModule={props.isFromCaseSpecificModule}
                caseDetails={props?.caseDetails}
                isCaseClosed={isCaseClosed}
                isLeadClosed={isLeadClosed}
                handleExpenseTableRedirection={handleBillingRedirection}
                getTimeEntrySummarty={loadBillingSummary}
              />
            </TabPanel>
            <TabPanel
              header="Automated Time Entries"
              headerStyle={{
                display: userContext.checkSubPermission('automatic_time_tracking') && props?.isFromCaseSpecificModule ? '' : 'none',
              }}
            >
              <TimeEntries
                caseView={true}
                isLeadSpecific={props?.isLeadSpecific}
                caseDetails={props?.caseDetails}
                is_automated={true}
                isCaseClosed={isCaseClosed}
                caseDetailsPage={true}
                isLeadClosed={isLeadClosed}
                isFromCaseSpecificModule={props?.isFromCaseSpecificModule}
                handleTimeEntryRedirection={handleBillingRedirection}
              />
            </TabPanel>
            <TabPanel header="Manual Time Entries">
              <TimeEntries
                caseView={true}
                isLeadSpecific={props.isLeadSpecific}
                caseDetails={props.caseDetails}
                is_automated={false}
                isCaseClosed={isCaseClosed}
                caseDetailsPage={true}
                isLeadClosed={isLeadClosed}
                isFromCaseSpecificModule={props.isFromCaseSpecificModule}
                handleTimeEntryRedirection={handleBillingRedirection}
                getTimeEntrySummarty={loadBillingSummary}
              />
            </TabPanel>
            <TabPanel
              header="Payment Requests"
              headerStyle={{ display: props?.caseDetails?.case_description?.case_practice_area === 'Personal Injury' ? 'none' : '' }}
            >
              <PaymentRequests
                caseView={true}
                isLeadSpecific={props.isLeadSpecific}
                caseDetails={props.caseDetails}
                billingSummary={billingSummary}
                isCaseClosed={isCaseClosed}
                isLeadClosed={isLeadClosed}
                getCaseDetails={getCaseDetails}
                isFromCaseSpecificModule={props.isFromCaseSpecificModule}
                getTimeEntrySummarty={loadBillingSummary}
              />
            </TabPanel>
            <TabPanel
              header="Invoices"
              headerStyle={{ display: props?.caseDetails?.case_description?.case_practice_area === 'Personal Injury' ? 'none' : '' }}
            >
              <Invoices
                caseView={true}
                isLeadSpecific={props.isLeadSpecific}
                caseDetails={props.caseDetails}
                isCaseClosed={isCaseClosed}
                isLeadClosed={isLeadClosed}
                getCaseDetails={getCaseDetails}
                isFromCaseSpecificModule={props.isFromCaseSpecificModule}
                getTimeEntrySummarty={loadBillingSummary}
              />
            </TabPanel>
            <TabPanel
              header="Payment History"
              headerStyle={{ display: props?.caseDetails?.case_description?.case_practice_area === 'Personal Injury' ? 'none' : '' }}
            >
              <PaymentHistory
                caseView={true}
                isLeadSpecific={props.isLeadSpecific}
                caseDetails={props.caseDetails}
                isCaseClosed={isCaseClosed}
                isLeadClosed={isLeadClosed}
                isFromCaseSpecificModule={props.isFromCaseSpecificModule}
                handlePaymentHistoryRedirection={handleBillingRedirection}
              />
            </TabPanel>
            <TabPanel header="Trust Accounting">
              <TrustAccounting
                caseView={true}
                isLeadSpecific={props.isLeadSpecific}
                caseDetails={props.caseDetails}
                isCaseClosed={isCaseClosed}
                isLeadClosed={isLeadClosed}
                isFromCaseSpecificModule={props.isFromCaseSpecificModule}
              />
            </TabPanel>
            <TabPanel header="Temporary Funds">
              <TemporaryFunds
                caseView={true}
                isLeadSpecific={props.isLeadSpecific}
                caseDetails={props?.caseDetails}
                isCaseClosed={isCaseClosed}
                isLeadClosed={isLeadClosed}
                getCaseDetails={getCaseDetails}
                isFromCaseSpecificModule={props.isFromCaseSpecificModule}
              />
            </TabPanel>
          </TabView>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Billing;
