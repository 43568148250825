import React from 'react';

import Heading from './Heading/Heading';
import Filters from './Filters/Filters';
import SearchFiles from './SearchFiles/SearchFiles';
import Menu from './Menu/Menu';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function FileManagerHeader() {
  const { isGlobalFileManager } = useFileManagerContext();

  return (
    <div className="">
      {isGlobalFileManager && <Heading />}
      <div className="d-flex flex-wrap align-items-center justify-content-between mb-3 col-sm-12 FM-filter">
        <div className="filter-wrap d-flex align-items-center pe-2 ps-lg-2 ps-2 py-2 flex-wrap">
          <SearchFiles />
          <Filters />
        </div>
        <Menu />
      </div>
    </div>
  );
}

export default FileManagerHeader;
