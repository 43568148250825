import React from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { paymentTerms, reminderOptions, statusOptions } from '../../configs/create_invoice_configs';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const InvoiceDetailsForm = (props) => {
  // TODO:MUST remove direct state update

  const {
    isSubscription,
    isContigencyFee,
    isPersonalInjuryPracticeArea,
    invoiceNumber,
    invoiceDate,
    paymentPlan,
    selectedPaymentTerm,
    dueDate,
    isReminder,
    selectedReminder,
    selectedStatus,
    checkDate,
    checkNumber,
    setSelectedPaymentTerm,
    setDueDate,
    setIsReminder,
    setSelectedReminder,
    setCheckDate,
    setSelectedStatus,
    setInvoiceDate,
    setCheckNumber,
    formErrors,
    setFormErrors,
    isChanged,
    setIsChanged = { setIsChanged },
  } = props || {};

  const scrollToPaymentPlan = () => {
    let divElement = document.getElementById('payment-plan');
    divElement?.scrollIntoView({
      top: divElement.scrollHeight, //scroll to the bottom of the element
      behavior: 'smooth', //auto, smooth, initial, inherit
    });
  };

  return (
    <div className="col-12 col-md-6 mt-3 mt-sm-0">
      <div className="row">
        <div className="col-12 caption-bold">INVOICE DETAILS</div>
      </div>
      <div className="shadow-small p-3">
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">
            Invoice #<span className="text-danger">*</span>
          </div>
          <div className="col-12 col-md-6">
            <InputText className="input-shadow w-100 border-0 p-2" placeholder="Invoice ID" value={invoiceNumber} disabled />
            {formErrors?.invoice_id && <span className="text-danger">Required</span>}
          </div>
        </div>
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">
            Invoice Date
            <span className="text-danger">*</span>
          </div>
          <div className="col-12 col-md-6">
            <Calendar
              className="input-shadow w-100 border-0 p-2 input-height"
              placeholder="Invoice Date"
              value={invoiceDate}
              onChange={(e) => {
                setInvoiceDate(e.value);
                isChanged.invoice = true;
                formErrors.invoice_date = '';
                setFormErrors({ ...formErrors });
              }}
              disabled={isSubscription}
            />
            {formErrors && formErrors.invoice_date && <span className="text-danger">Required</span>}
          </div>
        </div>

        {!isContigencyFee && (
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">
              Due Date
              <span className="text-danger">*</span>
            </div>
            <div className="col-12 col-md-6">
              {paymentPlan ? (
                <span role="button" onClick={scrollToPaymentPlan}>
                  <u>Payment Plan</u>
                </span>
              ) : (
                <Calendar
                  className="input-shadow input-height w-100 border-0 p-2"
                  placeholder="Due Date"
                  value={dueDate}
                  onChange={(e) => {
                    setDueDate(e.value);
                    isChanged.invoice = true;
                    formErrors.due_date = '';
                    setFormErrors({ ...formErrors });
                  }}
                  disabled={isSubscription}
                />
              )}

              {formErrors && formErrors.due_date && <span className="text-danger">Required</span>}
            </div>
          </div>
        )}

        {!paymentPlan && !isContigencyFee && (
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-12 col-md-6 p-medium">
              Payment Terms
              {/* <span className="text-danger">*</span> */}
            </div>
            <div className="col-12 col-md-6">
              <Dropdown
                className="input-shadow w-100 border-0"
                options={paymentTerms}
                placeholder="Select"
                onChange={(e) => {
                  setSelectedPaymentTerm(e?.value);
                  setIsChanged((prevVal) => {
                    return {
                      ...prevVal,
                      invoice: true,
                    };
                  });

                  // isChanged.invoice = true;
                  // formErrors.payment_terms = '';
                  // setFormErrors({ ...formErrors });
                }}
                value={selectedPaymentTerm}
                disabled={isSubscription}
                filter
              />
              {/* {formErrors && formErrors.payment_terms && <span className="text-danger">Required</span>} */}
            </div>
          </div>
        )}
        {!isContigencyFee && (
          <div className="d-flex align-items-center py-2 flex-wrap">
            <div className="col-md-6 col-sm-5 p-medium">Add Reminder</div>
            <div className="col-md-6 col-sm-7 mt-2">
              <div className="d-flex align-items-center">
                <label className="switch mb-2">
                  <input
                    type="checkbox"
                    checked={isReminder}
                    name="non_billable"
                    onChange={(e) => {
                      setIsReminder(e.target.checked);
                      isChanged.invoice = true;
                      setSelectedReminder();
                      formErrors.reminder = '';
                      setFormErrors({ ...formErrors });
                    }}
                  />{' '}
                  <span className="slider round"></span>
                </label>
                {isReminder && (
                  <>
                    <Dropdown
                      className="input-shadow w-50 border-0 mb-2 ms-2"
                      placeholder="Select"
                      options={reminderOptions}
                      onChange={(e) => {
                        setSelectedReminder(e.value);
                        isChanged.invoice = true;
                        formErrors.reminder = '';
                        setFormErrors({ ...formErrors });
                      }}
                      value={selectedReminder}
                      filter
                    />
                    {formErrors && formErrors.reminder && <span className="text-danger">Required</span>}
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">
            Status
            <span className="text-danger">*</span>
          </div>
          <div className="col-12 col-md-6">
            <Dropdown
              className="input-shadow w-100 border-0"
              options={statusOptions}
              onChange={(e) => {
                setSelectedStatus(e.value);
                isChanged.invoice = true;
                formErrors.status = '';
                setFormErrors({ ...formErrors });
              }}
              placeholder="Select"
              value={selectedStatus}
              filter
            />
            {formErrors && formErrors.status && <span className="text-danger">Required</span>}
          </div>
        </div>
        {isContigencyFee && isPersonalInjuryPracticeArea && (
          <>
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-12 col-md-6 p-medium">
                Check Date
                <span className="text-danger">*</span>
              </div>
              <div className="col-12 col-md-6">
                <Calendar
                  value={checkDate}
                  onChange={(e) => {
                    setCheckDate(e.value);
                    isChanged.invoice = true;
                    formErrors.check_date = '';
                    setFormErrors({ ...formErrors });
                  }}
                  className="input-shadow w-100 border-0 p-2 input-height"
                  placeholder="Check Date"
                />
                {formErrors && formErrors.check_date && <span className="text-danger">Required</span>}
              </div>
            </div>
            <div className="d-flex align-items-center py-2 flex-wrap">
              <div className="col-12 col-md-6 p-medium">
                Check Number
                <span className="text-danger">*</span>
              </div>
              <div className="col-12 col-md-6">
                <TextSnippetWrapper
                  type="text"
                  value={checkNumber}
                  onChange={(e) => {
                    setCheckNumber(e?.target?.value);
                    isChanged.invoice = true;
                    formErrors.check_number = '';
                    setFormErrors({ ...formErrors });
                  }}
                  className="input-shadow w-100 border-0 p-2 input-height"
                  placeholder="Check Number"
                />
                {formErrors && formErrors.check_number && <span className="text-danger">Required</span>}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default InvoiceDetailsForm;
