import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox } from 'primereact/checkbox';

import ImageComponent from 'shared/ImageComponent';
import { generateFullName } from 'utils/generateFullNameUtils';
import { useAssignToFilter } from 'modules/lead/hooks/useAssignToFilter';
import { handleRedirection } from 'utils/utils';
import { handleSettingSkAndPkToLocalStorage } from 'utils/localStorageUtils';
import { useHistory } from 'react-router-dom';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

export default function AssignTo({ control }) {
  const { searchTerm, setSearchTerm, filteredUserList } = useAssignToFilter();
  const history = useHistory();

  return (
    <div className="col-md-6 col-12">
      <h5 className="caption-bold">ASSIGN TO</h5>
      <div className="shadow-middle bg-white lead-data-box p-3">
        <div>
          <TextSnippetWrapper
            type="text"
            placeholder="Search"
            className="input-shadow input-search w-100"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <hr></hr>
        <div className="mt-1 mb-2">
          <i className="pi pi-sort-alpha-down me-1"></i>
          <span>Team Member Name</span>
        </div>
        <div className="user-list">
          {filteredUserList.map((item) => {
            const { firm_user_id, sk, pk } = item;
            const route = firm_user_id ? `/settings/team-management/${firm_user_id}` : '/settings/team-management';

            return (
              <div className="d-flex mt-1 align-items-center" key={item.user_id}>
                <Controller
                  name="lead_assigned_to"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <Checkbox
                      inputId={field.name}
                      ref={field.ref}
                      onChange={(e) => field.onChange(item)}
                      checked={field.value?.user_id === item?.user_id}
                      className="me-2"
                    />
                  )}
                />
                <div
                  className="d-flex"
                  onClick={() => {
                    handleRedirection(history, route);
                    handleSettingSkAndPkToLocalStorage(sk, pk);
                  }}
                >
                  <ImageComponent filePath={item?.profile_image} fileName={item?.first_name} fileSize="small.jpg" />
                  <div className="ms-1 redirection-link">{generateFullName(item)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
