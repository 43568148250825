import { useState } from 'react';
import PdfViewerComponent from 'modules/file_manager/PdfViewerComponent';
import FileDownload from '../../ListView/Templates/ActionsTemplate/FileDownload';
import PrintFile from '../../ListView/Templates/ActionsTemplate/PrintFile';
import GetSignature from '../../ListView/Templates/ActionsTemplate/GetSignature';

export default function DocumentViewer(props) {
  const [setInstance] = useState(null);
  const [setPSPDFKit] = useState(false);
  const fileType = props?.item?.file_type;
  const docTypeVal = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
  const printFileFormats = ['image/png', 'application/pdf', docTypeVal];

  const sigCondition = fileType && (fileType === 'application/pdf' || fileType === docTypeVal);

  return (
    <>
      {props?.document ? (
        <>
          {!props?.isClientFileManager && sigCondition && <GetSignature rowData={props?.item} />}
          <FileDownload
            rowData={props?.item}
            isFromFilePreviewModal={true}
            filePreviewModalIconClassName={'icon-save p-2 pointer float-end h2 mb-0'}
          />
          {fileType && printFileFormats?.includes(fileType?.toLowerCase()) ? (
            <PrintFile
              rowData={props?.item}
              isFromFilePreviewModal={true}
              filePreviewModalIconClassName={'icon-Print p-2 pointer float-end h2 mb-0'}
            />
          ) : null}

          <PdfViewerComponent
            document={props?.document}
            setPSPDFKit={setPSPDFKit}
            setInstance={setInstance}
            signatureOption={true}
            assignee={''}
            clientOptions={[]}
            onLoad={props?.onLoad}
          />
        </>
      ) : null}
    </>
  );
}
