import React, { useRef } from 'react';
import { VariableSizeList as List } from 'react-window';
import { classNames } from 'primereact/utils';

import { useUserDetailsContext } from 'context/userDetailsContext';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import constants from 'constants/index';
import Message from './Message';
import { debounce } from 'lodash';

const InfiniteScrollList = ({ nextToken, listRef, messages, loading, selectedThread, fetchMessageHistory }) => {
  const userContext = useUserDetailsContext();

  const rowHeights = useRef({});

  const isMessageLoaded = (index) => index < messages.length;

  const loadMoreMessages = () => {
    if (!loading && nextToken) {
      fetchMessageHistory(selectedThread?.contact_id, false, selectedThread?.to?.replace('+1', ''), nextToken);
    }
  };

  const onItemsRendered = debounce(({ scrollOffset }) => {
    if (scrollOffset === 0 && !loading) {
      loadMoreMessages();
    }
  }, 200);

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  function getRowHeight(index) {
    const message = messages[index];
    const t1 = handleDateTimeOffset(userContext?.userDetails?.timezone, message?.time, constants.month_date_year_format);

    const t2 = handleDateTimeOffset(userContext?.userDetails?.timezone, messages[index - 1]?.time, constants.month_date_year_format);
    const isGroupHeader = t1 !== t2;
    return rowHeights.current[index] + (isGroupHeader ? 33 : 1) || 150;
  }

  const Row = ({ index, style }) => {
    if (!isMessageLoaded(index)) {
      return null;
    }
    const message = messages[index];

    const t1 = handleDateTimeOffset(userContext?.userDetails?.timezone, message?.time, constants.month_date_year_format);

    const t2 = handleDateTimeOffset(userContext?.userDetails?.timezone, messages[index - 1]?.time, constants.month_date_year_format);

    const isGroupHeader = t1 !== t2;

    return (
      <div
        style={{ ...style, borderBottom: 'none' }}
        className={classNames({ 'msg-container': true, 'mb-0 pt-0 pb-0': true })}
        id="msgContainer"
      >
        {isGroupHeader && (
          <div className="text-center msg-filter">
            <span>{handleDateTimeOffset(userContext.userDetails.timezone, message?.time, constants.month_date_year_format)}</span>
          </div>
        )}
        <Message {...{ selectedThread, message, index, setRowHeight }} />
      </div>
    );
  };

  const renderLoader = () => {
    return (
      <div className="chat-loader-container">
        <div className="overlay">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: '2em' }}></i>
        </div>
      </div>
    );
  };

  return (
    <div style={{ position: 'relative' }}>
      {loading && renderLoader()}
      <List
        height={400}
        itemCount={loading ? messages.length + 1 : messages.length}
        itemSize={getRowHeight}
        onScroll={onItemsRendered}
        ref={listRef}
        layout="vertical"
        initialScrollOffset={messages.length * 30} // Set initial scroll offset to show the bottom of the list
        initialScrollOffsetAlignment="end" // Align initial scroll offset to the bottom
        overscanCount={5}
      >
        {Row}
      </List>
    </div>
  );
};

export default InfiniteScrollList;
