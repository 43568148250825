import React, { useState, useEffect, useContext } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
//import { Dropdown } from "primereact/dropdown";
//import { CreatePaymentRequest } from "./CreatePaymentRequest";
import { useSelector, useDispatch } from 'react-redux';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { deletePaymentRecord } from 'services/billingServices';
import { format } from 'date-fns';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { EditPaymentRecord } from './EditPaymentRecord';
import { listPaymentRecords } from 'redux/actions/PaymentHistoryAction';
import { RefundPayment } from './RefundPayment';
import useSpinner from 'hooks/useSpinner';
import { dollarFormat, paginationDropdownOptions } from 'utils/utils';
import ImageComponent from 'shared/ImageComponent';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import { convertToTimezoneFormat, handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { UserDetailsContext } from 'context/userDetailsContext';
import constants from 'constants/index';
import { handlePageNumberInPagination } from 'common/pagination';
import Cookies from 'universal-cookie';
import moment from 'moment';
import TextSnippetWrapper from 'components/TextSnippetWrapper';
import { getPracticeAreaAction } from 'redux/actions/PracticeAreaAction';
import { totalCountEvent } from 'services/generalServices';

const cookie = new Cookies();
export const PaymentHistory = (props) => {
  const { isFromCaseSpecificModule, handlePaymentHistoryRedirection, isFromGlobalBilling } = props || {};
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [dateFilter, setDateFilter] = useState('');
  const [showRecordModal, setShowRecordModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const paymentRecords = useSelector((state) => state.paymentHistory.payment_records);

  const userContext = useContext(UserDetailsContext);
  const isLoading = useSelector((state) => state.paymentHistory.isLoading);
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [spinner, showSpinner, hideSpinner] = useSpinner(true);
  let tenantId = cookie.get('tenantId');
  const [selectedPractice, setSelectedPractice] = useState(null);
  const [pageLimit, setPageLimit] = useState(getPageLimit() ?? 10);
  const [isForward, setIsForward] = useState(true);
  const next_Token = paymentRecords.next_token;
  const transition_Token = paymentRecords.transition_token;

  const practiceData = useSelector((state) => state.practiceArea.practiceAreaList['practice-area']);
  const [practiceAreaOptions, setPracticeAreaOptions] = useState([]);

  function getPageLimit() {
    if (props.isLeadSpecific && userContext) {
      return userContext?.firmDetails?.pagination_preference?.leads?.billings?.payment_history;
    } else if (isFromCaseSpecificModule && userContext) {
      return userContext?.firmDetails?.pagination_preference?.case?.billings?.payment_history;
    } else if (userContext) {
      return userContext?.firmDetails?.pagination_preference?.billings?.payment_history;
    }
  }

  function getFilePathForPagination() {
    if (props.isLeadSpecific && userContext) {
      return { module: 'leads', subModule: 'billings', listName: 'payment_history' };
    } else if (isFromCaseSpecificModule && userContext) {
      return { module: 'case', subModule: 'billings', listName: 'payment_history' };
    } else {
      return { module: 'billings', subModule: '', listName: 'payment_history' };
    }
  }

  useEffect(() => {
    if (practiceData && practiceData.length) {
      let practice_options = practiceData.map((val) => {
        return {
          label: val.practice_area_name,
          value: val.practice_area_name,
        };
      });
      setPracticeAreaOptions(practice_options);
    } else {
      setPracticeAreaOptions([]);
    }
  }, [practiceData]);

  useEffect(() => {
    loadPaymentRequests(getPageLimit() ?? 10);
  }, []);

  useEffect(() => {
    if (!isFromCaseSpecificModule) {
      totalCountEvent('practice-area')
        .then((response) => {
          dispatch(getPracticeAreaAction(response.data));
        })
        .catch((err) => {});
    }
  }, []);

  useEffect(() => {
    if (isLoading) {
      showSpinner();
    } else {
      hideSpinner();
    }
  }, [isLoading]);

  const loadPaymentRequests = (pageLimit = 10, pagination_token = '', pagination_direction = 'forward') => {
    let filters = {
      ...pickExtractedDate(dateFilter),
      search_key: searchKey,
    };
    if (props.caseView) {
      filters.case_id = props?.caseDetails?.case_id;
    }
    dispatch(listPaymentRecords(filters, pageLimit, pagination_token, pagination_direction));
  };
  const onCreateSuccess = () => {
    setShowModal(false);
    setShowRecordModal(false);
    loadPaymentRequests(getPageLimit() ?? 10);
    setShowRefundModal(false);
  };

  const notesBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Notes</span>
        <span className="text-break">{rowData.description}</span>
      </React.Fragment>
    );
  };

  const paymentMethodBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Payment method</span>
        <span className="text-break">{rowData.payment_method}</span>
      </React.Fragment>
    );
  };

  const amountBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Amount</span>
        <span className="text-break">{dollarFormat(rowData?.payment_record_amount)}</span>
      </React.Fragment>
    );
  };

  const relatedToBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Related To</span>
        <span className="text-break">{rowData?.payment_ref_number}</span>
      </React.Fragment>
    );
  };

  const enterByBody = (rowData) => {
    const firmUserId = rowData?.created_by_details?.created_by_firm_user_id;
    let name = rowData?.created_by_details?.first_name;
    if (rowData?.created_by_details?.last_name) name = name + ' ' + rowData?.created_by_details?.last_name;

    return (
      <React.Fragment>
        <div
          onClick={(e) => {
            const route = firmUserId ? `/settings/team-management/${firmUserId}` : '/settings/team-management';
            handlePaymentHistoryRedirection(e, rowData, route);
          }}
        >
          <span className="p-column-title text-break">Entered By</span>
          <ImageComponent
            filePath={`${tenantId}/${firmUserId}/profile_image`}
            fileName={rowData?.created_by_details?.first_name}
            fileSize="small.jpg"
          />
          <span className="redirection-link">{name}</span>
        </div>
      </React.Fragment>
    );
  };

  const dateBody = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title text-break">Date</span>
        <span className="text-break">
          {handleDateTimeOffset(userContext?.userDetails?.timezone, rowData.payment_record_date, constants.month_date_year_format)}
        </span>
      </React.Fragment>
    );
  };

  const caseBody = (rowData, ...rest) => {
    const { case_id, is_lead } = rowData;
    const mainModuleName = is_lead ? '/leads' : '/cases';
    let route = case_id ? `${mainModuleName}/${case_id}` : mainModuleName;

    return (
      <React.Fragment>
        <span className="p-column-title text-break">Case</span>
        <span
          onClick={(e) => {
            handlePaymentHistoryRedirection(e, rowData, route);
          }}
          // className="text-break pointer F-size14 text-bold call-back text-decoration-none"
          className="redirection-link-no-text-transform"
        >
          {rowData.case_name ? rowData.case_name : '-'}
        </span>
      </React.Fragment>
    );
  };

  const actionBody = (rowData) => {
    return (
      <React.Fragment>
        <div>
          <span className="p-column-title text-break">Action</span>

          {rowData.is_refund === false && rowData.payment_record_ref === '' && rowData.payment_record_type !== 'REFUND' && (
            <span role="button" className={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed) ? 'pe-none opacity-50' : ''}>
              <i
                className="icon-edit ms-2"
                onClick={(e) => {
                  setSelectedRecord({ ...rowData });
                  setShowRecordModal(true);
                }}
                id="edit"
              >
                <Tooltip target={'.icon-edit'} position={'bottom'} className="case-tooltip mb-3" showDelay={500}>
                  Edit
                </Tooltip>
              </i>
            </span>
          )}

          {(rowData.is_refund === false || (rowData.is_refund === true && rowData.payment_record_type === 'REFUND')) && (
            <span role="button" className={props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed) ? 'pe-none opacity-50' : ''}>
              <i
                className="icon-delete ms-2"
                onClick={(e) => {
                  setSelectedRecord({ ...rowData });
                  setConfirmDelete(true);
                }}
                id="delete"
              >
                <Tooltip target={'.icon-delete'} position={'bottom'} className="case-tooltip mb-3" showDelay={500}>
                  Delete
                </Tooltip>
              </i>
            </span>
          )}
          {rowData.is_refund === false && rowData.payment_record_ref === '' && rowData.payment_record_type !== 'REFUND' && (
            <span
              className={
                // 'ms-2 text-primary-dark text-decoration-none text-medium ' +
                'ms-2 redirection-link' + (props.isCaseClosed || (props.isLeadSpecific && props.isLeadClosed) ? 'pe-none opacity-50' : '')
              }
              role="button"
              onClick={() => {
                setSelectedRecord({ ...rowData });
                setShowRefundModal(true);
              }}
            >
              Refund
            </span>
          )}
        </div>
      </React.Fragment>
    );
  };

  const deleteRequest = () => {
    if (selectedRecord) {
      setConfirmDelete(false);
      deletePaymentRecord(selectedRecord.sk, props.isLeadSpecific)
        .then(() => {
          addToast(
            false,
            toastConstant.toasterType.SUCCESS,
            toastConstant.api.SUCCESS,
            toastConstant.message.DELETE_PAYMENT_RECORD_SUCCESS
          );
          loadPaymentRequests(getPageLimit() ?? 10);
        })
        .catch((err) => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.DELETE_PAYMENT_RECORD_FAILED);
        });
    }
  };

  const onSearchKeyDown = (e) => {
    let code = e.keyCode ? e.keyCode : e.which;
    if (code === 13) {
      dispatch(
        listPaymentRecords(
          {
            payment_date_upper: dateFilter[1] ? format(dateFilter[1], 'yyyy-MM-dd') : '',
            payment_date_lower: dateFilter[0] ? format(dateFilter[0], 'yyyy-MM-dd') : '',
            payment_request_status: '',
            search_key: searchKey,
          },
          getPageLimit() ?? 10
        )
      );
    }
  };

  const pickExtractedDate = (dateArray) => {
    return {
      payment_date_lower: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[0]).startOf('day'), userContext?.userDetails.timezone)
        : '',
      payment_date_upper: dateArray?.[0]
        ? convertToTimezoneFormat(moment(dateArray[1] ? dateArray[1] : dateArray[0]).endOf('day'), userContext?.userDetails.timezone)
        : '',
    };
  };

  const onChangeSearch = (e) => {
    let value = e.target.value;
    setSearchKey(value);
    if (!value) {
      dispatch(
        listPaymentRecords(
          {
            ...pickExtractedDate(dateFilter),
            payment_request_status: '',
            search_key: '',
          },
          getPageLimit() ?? 10
        )
      );
    }
  };

  const onChangeCalendar = (e) => {
    setDateFilter(e.value);
    dispatch(
      listPaymentRecords(
        {
          ...pickExtractedDate(e.value),
          payment_request_status: '',
          search_key: searchKey,
          practice_area: selectedPractice,
        },
        getPageLimit() ?? 10
      )
    );
  };
  const onChangePractice = (e) => {
    setSelectedPractice([...e.value]);
    dispatch(
      listPaymentRecords(
        {
          ...pickExtractedDate(dateFilter),
          payment_request_status: '',
          search_key: searchKey,
          practice_area: [...e.value],
        },
        getPageLimit() ?? 10
      )
    );
  };

  const refreshCase = () => {
    setSearchKey('');
    setDateFilter();
    setSelectedPractice();

    let filters = {
      payment_date_upper: '',
      payment_date_lower: '',
      search_key: '',
      practice_area: '',
    };
    dispatch(listPaymentRecords(filters));
  };

  const paginatorTemplate = {
    layout: 'RowsPerPageDropdown PrevPageLink NextPageLink',
    RowsPerPageDropdown: (options) => {
      return (
        <React.Fragment>
          <span className="mx-1" style={{ color: 'var(--text-color)', userSelect: 'none' }}>
            Item Per Page
          </span>
          <Dropdown value={options.value} options={paginationDropdownOptions} onChange={onPageChange} />
        </React.Fragment>
      );
    },
    NextPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onNextPageClick}
          disabled={next_Token === null && isForward}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
    PrevPageLink: ({ iconClassName }) => {
      return (
        <Button
          onClick={onPrevPageClick}
          disabled={transition_Token === null || (transition_Token && next_Token === null && !isForward)}
          className={'p-paginator-next p-paginator-element p-link'}
          icon={iconClassName}
        />
      );
    },
  };

  const onNextPageClick = () => {
    loadPaymentRequests(pageLimit, !isForward ? transition_Token : next_Token, 'forward');
    setIsForward(true);
  };
  const onPrevPageClick = () => {
    loadPaymentRequests(pageLimit, isForward ? transition_Token : next_Token, 'reverse');
    setIsForward(false);
  };
  const onPageChange = (event) => {
    setPageLimit(event.value);
    let path = getFilePathForPagination();
    handlePageNumberInPagination(userContext, path?.module, path?.subModule, path?.listName, event.value);
    loadPaymentRequests(event.value, '', 'forward');
  };

  return (
    <div>
      {spinner}
      {/*  <CreatePaymentRequest
        modal={showModal}
        setModal={setShowModal}
        onCreateSuccess={onCreateSuccess}
        selectedRecord={selectedRecord}

      /> */}
      <ConfirmDialog
        header="Confirm Delete"
        rejectClassName="p-button-secondary outline"
        message={' Do you want to delete this payment record?'}
        visible={confirmDelete}
        icon="pi pi-exclamation-triangle"
        className="confirm-delete-popup"
        accept={() => {
          deleteRequest();
        }}
        reject={() => {
          setConfirmDelete(false);
          setSelectedRecord();
        }}
        onHide={() => {
          setConfirmDelete(false);
          setSelectedRecord();
        }}
      />
      {Boolean(showRecordModal) && (
        <EditPaymentRecord
          show={showRecordModal}
          onHide={() => {
            setShowRecordModal(false);
          }}
          selectedRecord={selectedRecord}
          onSuccess={onCreateSuccess}
          isLeadSpecific={props.isLeadSpecific}
        />
      )}
      {Boolean(showRefundModal) && (
        <RefundPayment
          show={showRefundModal}
          onHide={() => setShowRefundModal(false)}
          selectedRecord={selectedRecord}
          onSuccess={onCreateSuccess}
          isLeadSpecific={props.isLeadSpecific}
        />
      )}
      <div className="">
        <div className="d-flex flex-wrap align-items-center filter-wrap mb-3 p-lg-0 p-2 w-100 align-items-center justify-content-between">
          <div className="p-2 filter-wraper">
            <span className="p-input-icon-left input-shadow">
              <i className="icon-search F-size16"></i>
              <TextSnippetWrapper
                type="text"
                placeholder="Search"
                onChange={(e) => {
                  onChangeSearch(e);
                }}
                value={searchKey}
                onKeyDown={onSearchKeyDown}
                className="p-inputtext p-component input-search"
              />
            </span>
          </div>
          <div className="d-flex flex-wrap align-items-center p-2 filter-wraper">
            <label className="text-bold black-600  filter-label">Filters:</label>
            <div className="d-flex flex-column ms-2 filter-wraper">
              <Calendar
                className="input-shadow filter-calender"
                id="icon"
                value={dateFilter}
                onChange={(e) => {
                  onChangeCalendar(e);
                }}
                selectionMode="range"
                showIcon
                placeholder="Date"
              />
            </div>
            {!isFromCaseSpecificModule && (
              <div className="d-flex flex-column mx-lg-2 mx-0 filter-wraper">
                <MultiSelect
                  value={selectedPractice}
                  options={practiceAreaOptions}
                  onChange={onChangePractice}
                  placeholder="Practice Area"
                  maxSelectedLabels={1}
                  display="chip"
                  filter
                  className="input-shadow w-100 F-size14 input-width"
                  style={{ height: '40px' }}
                />
              </div>
            )}

            <div className="d-flex flex-column mx-2">
              <div className="pointer ms-0">
                {/* <label className="font-bold mx-1 d-block">&nbsp;</label> */}
                <i className="fas fa-sync-alt" onClick={refreshCase}></i>
                <Tooltip content="Clear Filter" position="top" target=".fa-sync-alt" showDelay={500} />
              </div>
            </div>
            {/*  <div className="d-flex flex-column mx-2">
              <Dropdown
                options={statusOptions} onChange={onChangeStatus} value={selectedStatus} showClear
              />
            </div> */}

            {/* <div className="d-flex flex-column mx-2"></div> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12 datatable-responsive">
          <DataTable
            value={paymentRecords.payment_records}
            className="p-datatable-responsive"
            paginator
            paginatorTemplate={paginatorTemplate}
            rows={pageLimit}
          >
            <Column body={dateBody} header="Date" />
            {isFromGlobalBilling && <Column body={caseBody} header="Case" />}
            <Column header="Related To" body={relatedToBody} />
            <Column header="Entered By" body={enterByBody} />
            <Column header="Notes" body={notesBody} />
            <Column header="Payment Method" body={paymentMethodBody} />
            <Column header="Amount" body={amountBody} />
            <Column body={actionBody} />
          </DataTable>
        </div>
      </div>
    </div>
  );
};
