import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Controller, useFieldArray } from 'react-hook-form';
import moment from 'moment';

import { timeIntervalsWithServerValue } from 'constants/dropdownOptions';

export default function NestedRemider({ control, dueDate }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'reminder',
  });

  //Filter reminder options based on current date
  const filteredList = timeIntervalsWithServerValue?.filter(
    (v) => moment(dueDate).startOf('day').diff(moment().startOf('day'), 'days') >= v.days
  );

  return (
    <>
      {filteredList?.length > 0 && (
        <>
          <div className="col-md-4 col-12 py-3 p-medium">Reminder</div>
          <div className="col-md-8 col-12 edit-field">
            {fields.map((item, index) => {
              return (
                <div className="d-flex align-items-center" key={item.id}>
                  <Controller
                    name={`reminder[${index}].value`}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Dropdown
                        className="mb-2 w-100 border border-0"
                        placeholder="Select Reminder"
                        options={filteredList}
                        filter
                        optionDisabled={(e) => fields.find((v) => v.value === e.value)}
                        {...field}
                      />
                    )}
                  />
                  <div className="ms-3">
                    <i className="icon-delete pointer" onClick={() => remove(index)} />
                  </div>
                </div>
              );
            })}
            {fields.length < filteredList.length && (
              <div className="mt-2 edit-field pointer addfield" onClick={() => append({ value: '' })}>
                <i className="icon-add F-size14 me-1"></i>Add Reminder
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
