import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { reorderList, groupBy } from 'utils/utils';
import { useToggle } from 'hooks/useToggle';
import { useDebounce } from 'hooks/useDebounce';
import { closeLead, getLeads } from 'services/lead/leadServices';
import { totalCountEvent } from 'services/generalServices';
import { createLeadStages, updateCaseStagePosition } from 'services/Settings';
import { useLeadContext } from '../Context/LeadContext';
import { Board } from './components/Board/Board';
import Header from './components/Header/Header';
import AddNewLead from '../AddLead/AddLead';
import AddNewStage from 'modules/Tasks/components/TaskBoard/Board/AddNewStage';
import CloseLeadDialog from 'modules/lead/LeadDetails/components/Overview/components/CloseLeadDialog/CloseLeadDialog';
import { ConvertCaseDialogue } from 'modules/lead/LeadDetails/components/Overview/components/ConvertCaseDialog/ConvertCaseDialog';
import { useLeadDetails } from '../hooks/useLeadDetails';
import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';

const prepareData = (data) => {
  const laneData = {};
  if (data) {
    const formattedData = groupBy(data, 'lead_stage');
    const keys = Object.keys(formattedData);
    keys.forEach((key) => {
      const stageData = formattedData[key] || [];
      const column = {
        id: key,
        items: stageData,
      };
      laneData[key] = column;
    });
  }
  return laneData;
};

const LeadList = () => {
  const location = useLocation();
  const [leadList, setLeadList] = useState({});
  const [filter, setFilter] = useState({});
  const [selectedLead, setSelectedLead] = useState(null);
  const [movePayLoad, setMovePayload] = useState();
  const [convertCaseToggle, setConvertCaseToggle] = useToggle();
  const [closeCaseToggle, setCloseCaseToggle] = useToggle();
  const { updateLead, loadLeadStages, leadStages: stages, setLeadStages: setStages } = useLeadContext();
  const { leadDetails, loadLeadDetails, clearData } = useLeadDetails();

  const debouncedFilter = useDebounce(filter, 500);
  const [addToggle, setAddToggle] = useToggle(false);
  const { addToast } = useToast();

  useEffect(() => {
    setAddToggle(Boolean(location?.state?.createLead));
  }, [location]);

  useEffect(() => {
    localStorage.removeItem('pagination_token_notes');
    localStorage.removeItem('pagination_direction_notes');
  }, []);

  const updateStagePosition = (data = []) => {
    updateCaseStagePosition({
      updated_positions: data.map((v, i) => {
        if (v.position === 0 || v.position === -1) return v;
        return { ...v, position: i + 1 };
      }),
    }).catch((err) => console.error(err));
  };

  const handleColumnReorder = (source, destination) => {
    const columnOrder = reorderList(stages, source.index, destination.index);
    if (
      destination &&
      source.index !== 0 &&
      destination.index !== 0 &&
      source.index !== stages.length - 1 &&
      destination.index !== stages.length - 1
    ) {
      setStages(columnOrder);
      if (source.index !== destination.index) {
        updateStagePosition(columnOrder);
      }
    }
  };

  const handleSameColumnReorder = (source, destination) => {
    const column = leadList[source.droppableId];
    const items = reorderList(column.items, source.index, destination.index);
    setLeadList({
      ...leadList,
      [column.id]: {
        ...column,
        items,
      },
    });
  };

  const handleDifferentColumnReorder = (source, destination) => {
    const sourceColumn = leadList[source.droppableId];
    const destinationColumn = leadList[destination.droppableId] || {
      id: destination.droppableId,
      items: [],
    };
    const item = sourceColumn.items[source.index];

    if (source.droppableId !== destination.droppableId) {
      const destStage = stages.find((stage) => stage?.lead_stage_name?.toLowerCase() === 'closed');
      if (destination.droppableId === destStage?.sk) {
        cardAction('close', item);
        return;
      } else updateLead({ ...item, lead_stage: destination.droppableId, case_description: undefined });
    }

    const newSourceColumn = {
      ...sourceColumn,
      items: [...sourceColumn.items],
    };
    newSourceColumn.items.splice(source.index, 1);

    const newDestinationColumn = {
      ...destinationColumn,
      items: [...destinationColumn.items],
    };
    newDestinationColumn.items.splice(destination.index, 0, {
      ...(item || {}),
      task_stage: newDestinationColumn.id,
      lead_stage: newDestinationColumn.id,
      lead_timeline: [],
      previous_lead_stage: source.droppableId,
    });

    const newState = {
      ...leadList,
      [newSourceColumn.id]: newSourceColumn,
      [newDestinationColumn.id]: newDestinationColumn,
    };

    setLeadList(newState);
  };

  const onDragEnd = (result) => {
    const { source, destination, type } = result;

    if (!destination) {
      return;
    }

    if (type === 'column') {
      handleColumnReorder(source, destination);
    } else {
      if (source.droppableId === destination.droppableId) {
        handleSameColumnReorder(source, destination);
      } else {
        handleDifferentColumnReorder(source, destination);
      }
    }
  };

  const getLeadsData = async () => {
    try {
      const totalCountResponse = await totalCountEvent('lead');
      const response = await getLeads({
        data: { ...debouncedFilter },
        keyword: debouncedFilter?.keyword,
        limit: totalCountResponse.data,
      });
      setLeadList(prepareData(response.data?.leads || []));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLeadsData();
  }, [debouncedFilter]);

  const handleAddStage = async (data) => {
    try {
      await createLeadStages(
        {
          position: stages?.length,
          lead_stage_name: data?.laneName,
        },
        true
      );
      loadLeadStages();
      addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.STAGE_CREATED_SUCCESSFULLY);
    } catch (error) {
      console.error(error);
      addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.STAGE_UPDATED_FAILED);
    }
  };

  const checkStageExists = (val) => {
    return !Boolean(stages?.find((v) => v?.lead_stage_name?.toLocaleLowerCase() === val?.toLocaleLowerCase()));
  };

  const filterOnChange = (e) => {
    setFilter((prevVal) => ({ ...prevVal, [e.target.name]: e.target.value }));
  };

  const refreshList = () => setFilter((prevVal) => ({ refresh: !prevVal?.refresh }));

  const onHideConvertCase = (action) => {
    if (action === 'converted') {
      refreshList();
    }
    setConvertCaseToggle();
    clearData();
  };

  const onHideCloseCase = (action) => {
    if (action === 'closed') refreshList();
    setSelectedLead(null);
    setCloseCaseToggle();
  };

  const cardAction = (action, lead) => {
    if (action === 'convert') {
      setConvertCaseToggle();
      loadLeadDetails(lead?.sk);
    } else if (action === 'close') {
      setCloseCaseToggle();
      setSelectedLead(lead);
    } else if (action === 'reopen') {
      // Handle reopening here
      closeLeadAction(lead?.sk);
    } else if (action === 'refresh') {
      refreshList();
    } else if (action === 'move') {
      onDragEnd(lead);
    }
  };

  const closeLeadAction = (sk) => {
    closeLead(sk, { is_open: true })
      .then(() => {
        refreshList();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LEAD_CLOSE_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.LEAD_CLOSE_FAILURE);
      });
  };

  const handleCloseLead = (action) => {
    if (action === 'created') refreshList();
    setAddToggle();
  };

  return (
    <div className="container-fluid task-wrap">
      <Header {...{ filter, setAddToggle, filterOnChange, refreshList }} />
      <div className="d-flex">
        <div className="d-flex leads-draggable task-list mb-4 mt-0">
          <Board {...{ leadList, stages, onDragEnd, cardAction }} />
        </div>
        <AddNewStage {...{ handleAddStage, checkTaskStageExists: checkStageExists }} />
      </div>
      {addToggle && <AddNewLead handleClose={handleCloseLead} />}
      {convertCaseToggle && leadDetails && <ConvertCaseDialogue onHide={onHideConvertCase} leadDetails={leadDetails} />}
      {closeCaseToggle && selectedLead && <CloseLeadDialog onHide={onHideCloseCase} leadDetails={selectedLead} />}
    </div>
  );
};

export default LeadList;
