import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import LabelName from 'components/UI/LabelName/LabelName';
import { reminderCountOptions, reminderCustomOptions, reminderOptions } from 'constants/dropdownOptions';

function EventReminders({ watch, control }) {
  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'reminder',
  });

  function onAddReminder() {
    insert(fields.length, { value: '30#minutes#' });
  }
  function onDeleteReminder(index) {
    remove(index);
  }
  return (
    <>
      {fields && fields.length > 0 && (
        <div className="d-flex py-2 flex-wrap">
          <div className="col-md-4 col-12 mt-4">
            <LabelName required={false}>Reminders</LabelName>
          </div>
          <div className="col-md-8 col-12">
            {fields.map((data, index) => {
              let reminderValue = watch(`reminder.${index}.value`);
              return (
                <div className="mt-3 " key={index}>
                  <div className="d-flex align-items-center">
                    <Controller
                      name={`reminder.${index}.value`}
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          placeholder="Add"
                          name={field.name}
                          id={field.name}
                          value={field.value}
                          onChange={(e) => field.onChange(e.value)}
                          options={reminderOptions}
                          className="w-100"
                          filter
                        />
                      )}
                    />
                    <span
                      onClick={(e) => {
                        onDeleteReminder(index);
                      }}
                      className="pointer"
                      style={{ marginLeft: '0.5rem' }}
                    >
                      <i className="pi icon-delete"></i>
                      <Tooltip content="Delete Reminder" position="top" target=".icon-delete" showDelay={500} />
                    </span>
                  </div>

                  {reminderValue === 'custom' && (
                    <div className="d-flex mt-3 gap-2">
                      <Controller
                        name={`reminder.${index}.customValue`}
                        control={control}
                        defaultValue={1}
                        render={({ field }) => (
                          <Dropdown
                            placeholder="Add"
                            name={field.name}
                            id={field.name}
                            className="w-100"
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            options={reminderCountOptions}
                            filter
                          />
                        )}
                      />

                      <Controller
                        name={`reminder.${index}.type`}
                        control={control}
                        defaultValue="minutes"
                        render={({ field }) => (
                          <Dropdown
                            placeholder="Select Type"
                            name={field.name}
                            id={field.name}
                            value={field.value}
                            onChange={(e) => field.onChange(e.value)}
                            options={reminderCustomOptions}
                            filter
                          />
                        )}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="mt-2 ">
        <span className="pointer text-primary" onClick={onAddReminder}>
          + Add Reminder
        </span>
      </div>
    </>
  );
}

export default EventReminders;
