import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Editor } from 'primereact/editor';
import { classNames } from 'primereact/utils';

import { downloadAttachments } from 'services/calendarServices';
import { UserDetailsContext } from 'context/userDetailsContext';
import { useSecureMessageContext } from 'modules/messages/SecureMessages/Context/SecureMessageContext';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import { openLinkInBlank } from 'utils/utils';
import { generateFullName } from 'utils/generateFullNameUtils';
import { SentimentDot } from 'components/SentimentDot/SentimentDot';
import ImageComponent from 'shared/ImageComponent';
import constants from 'constants/index';

function Conversations({ messageDetails }) {
  const history = useHistory();

  const userContext = useContext(UserDetailsContext);
  const { isFromClientPortal } = useSecureMessageContext();

  const onClickAttachments = (e, fileInfo) => {
    e.preventDefault();
    downloadAttachments(fileInfo)
      .then((data) => {
        let link = document.createElement('a');
        link.download = fileInfo.display_name;
        let url = window.URL.createObjectURL(data.Body);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // navigate to event or task module
  function taskAndEventModuleNavigation(e, item, type) {
    let route = '/';
    if (type === 'event') {
      route = '/calendar';
    } else {
      route = '/tasks';
    }
    if (item?.task_name || item?.event_name) {
      if (!item?.case_name && !item?.case_id) {
        item.not_linked_to_case = false;
      }
      redirectToPage(e, route, item);
    }
  }

  const redirectToPage = (e, pathname, state) => {
    e.preventDefault();
    if (!openLinkInBlank(pathname, state)) {
      history?.push({
        pathname,
        state,
      });
    }
  };
  // navigate to event or task module
  function taskAndEventModuleNavigation(e, item, type) {
    let route = '/';
    if (type === 'event') {
      route = '/calendar';
    } else {
      route = '/tasks';
    }
    if (item?.task_name || item?.event_name) {
      if (!item?.case_name && !item?.case_id) {
        item.not_linked_to_case = false;
      }
      redirectToPage(e, route, item);
    }
  }

  const navigateToUser = (e, item) => {
    e.preventDefault();
    if (!isFromClientPortal && item?.access_level) {
      let route = '/';
      if (item?.access_level === 'client') {
        route = item?.contact_id ? `/contacts/${item?.contact_id}` : '/contacts';
      } else {
        route = item?.firm_user_id ? `/settings/team-management/${item?.firm_user_id}` : '/settings/team-management';
      }
      redirectToPage(e, route, item);
    }
  };

  return (
    <>
      {messageDetails?.message?.length ? (
        messageDetails?.message.map((msg, index) => (
          <div className="row col-12 p-3 msg-reply-wrap mssg-reply-bgcolor" key={index}>
            <div className="col-md-8 col-sm-6 col-12 d-flex">
              <div className="msg-img-size">
                <div className="avatar-wrp d-flex" onClick={(e) => navigateToUser(e, msg?.created_by_details)}>
                  <ImageComponent
                    filePath={msg?.created_by_user_image}
                    fileName={msg?.created_by_name?.first_name}
                    fileSize="medium.jpg"
                    avatarSize="medium"
                    className={isFromClientPortal ? 'pe-none' : ''}
                  />
                </div>
              </div>
              <div className="w-100">
                <div className="row">
                  <div className="col-sm-12 view-message-editor">
                    <p
                      className={`p-m-0 text-bold mb-0 text-capitalize ${classNames({
                        'redirection-link': !isFromClientPortal && Boolean(generateFullName(msg?.created_by_name)),
                      })}`}
                      onClick={(e) => navigateToUser(e, msg?.created_by_details)}
                    >
                      {generateFullName(msg?.created_by_name)}
                    </p>
                    <div className="d-flex">
                      <Editor value={msg?.message ? msg?.message : ' '} readOnly className="message-reply pe-2" />
                      {msg?.sentiment_analysis?.sentiment && !isFromClientPortal && (
                        <SentimentDot tooltipPosition="top" type={msg?.sentiment_analysis?.sentiment} typeOnly />
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 my-2">
                    {(msg.attachment && msg.attachment.length) ||
                    msg.event_attachment ||
                    (msg.task_attachment && msg.task_attachment.length) ? (
                      <>
                        {msg.attachment && msg.attachment.length
                          ? msg.attachment.map((index) => {
                              return (
                                <div key={index} className="p-chip p-component pointer text-nowrap">
                                  <p
                                    className="small pointer p-chip-text"
                                    onClick={(e) => {
                                      onClickAttachments(e, index);
                                    }}
                                  >
                                    <i className="fas fa-file-pdf"></i> {index.file_name}
                                  </p>
                                </div>
                              );
                            })
                          : null}

                        {msg.event_attachment && msg.event_attachment.length
                          ? msg.event_attachment.map((event, i) => {
                              return (
                                <div
                                  key={i}
                                  className="p-chip p-component text-break"
                                  style={{ marginRight: '0.5rem' }}
                                  onClick={(e) => {
                                    taskAndEventModuleNavigation(e, event, 'event');
                                  }}
                                >
                                  <p className="small p-chip-text">
                                    <i className="icon-calendar"></i> {event && event.event_name ? event.event_name : '-'}
                                  </p>
                                </div>
                              );
                            })
                          : null}

                        {msg.task_attachment && msg.task_attachment.length
                          ? msg.task_attachment.map((task, i) => {
                              return (
                                <div
                                  key={i}
                                  className="p-chip p-component text-break"
                                  style={{ marginRight: '0.5rem' }}
                                  onClick={(e) => {
                                    taskAndEventModuleNavigation(e, task, 'task');
                                  }}
                                >
                                  <p className="text-muted small mb-1 p-chip-text">
                                    <i className="fas fa-tasks"></i> {task && task?.task_name ? task?.task_name : '-'}
                                  </p>
                                </div>
                              );
                            })
                          : null}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12 view-message-editor text-end-sm">
              <p className="p-m-0 mb-0">
                {handleDateTimeOffset(userContext?.userDetails?.timezone, msg.time, constants.month_date_year_time_12_format)}
              </p>
              {!isFromClientPortal && (
                <>
                  {msg?.viewed?.length
                    ? msg?.viewed?.map((user, index) => {
                        user['access_level'] = 'client';
                        return (
                          <React.Fragment key={index}>
                            <p
                              key={index}
                              className={`p-m-0 text-bold mb-0 ${classNames({ 'redirection-link': Boolean(user?.viewed_by) })}`}
                              onClick={(e) => navigateToUser(e, user)}
                            >
                              {user?.viewed_by}
                            </p>
                            {user.viewed_at ? (
                              <p className="p-m-0 text-muted mb-0">
                                {`Viewed: ${handleDateTimeOffset(
                                  userContext?.userDetails?.timezone,
                                  user.viewed_at,
                                  constants.month_date_year_time_12_format
                                )}
                                    `}
                              </p>
                            ) : null}
                          </React.Fragment>
                        );
                      })
                    : null}
                </>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="row border-bottom mt-2">
          <div className="col-sm-12">No Message Details Found</div>
        </div>
      )}
    </>
  );
}

export default Conversations;
