import { performRequestSecond, performRequestThird } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';
import Cookies from 'universal-cookie';
const cookie = new Cookies();

export const loginLogoutEvent = (is_login = false, tenant_id = '', tenant_selected = false) => {
  const [tenantId] = getTenantDetails();
  tenant_id = tenant_id ? tenant_id : tenantId;
  let url = `${apiEndPoints.log_in_out}?tenant_id=${tenantId}&is_login=${is_login}&tenant_selected=${tenant_selected}`;
  return performRequestSecond(apiEndPoints.methodType.GET, url);
};

export const globalSearchEvent = (search_keyword = '') => {
  const tenantId = cookie.get('tenantId') || null;
  let url = `${apiEndPoints.globalSearch}?tenant_id=${tenantId}&search_keyword=${encodeURI(search_keyword)}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const totalCountEvent = async (module = ' ', case_id = '', contact_id = '', phone_number = '', pk = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenantSk = cookie.get('tenantSk') || null;
  let url = `${
    apiEndPoints.totalCount
  }?tenant_id=${tenantId}&tenant_sk=${tenantSk}&module=${module}&case_id=${case_id}&contact_id=${contact_id}&phone_number=${phone_number}&pk=${
    pk ? pk : ''
  }`;
  return await performRequestThird(apiEndPoints.methodType.GET, url);
};

export const totalCountClientEvent = async (module = ' ', contact_id = '') => {
  const tenantId = cookie.get('tenantId') || null;
  const tenantSk = cookie.get('tenantSk') || null;
  let url = `${apiEndPoints.totalCount}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&module=${module}&contact_id=${contact_id}`;
  return await performRequestThird(apiEndPoints.methodType.GET, url);
};
export const getDomainStatus = (data) => {
  let url = `/sub-domain-status-check`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data);
};

export const getStorageInformation = () => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `/storage-info?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.GET, url);
};

export const postActivityLog = (data) => {
  const [tenantId, tenantSk] = getTenantDetails();
  let url = `/create-activity-log?tenant_id=${tenantId}&tenant_sk=${tenantSk}`;
  return performRequestThird(apiEndPoints.methodType.POST, url, data, '', true, true);
};
