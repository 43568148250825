import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { getClientUserList } from 'services/CaseServices';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { updatePaymentRecord, getPaymentRecord, getTrustAccountBalance } from 'services/billingServices';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import { totalCountEvent } from 'services/generalServices';
import { listAccounts } from 'redux/actions/AccountListAction';
import { ModalHeader } from 'shared/ModalHeader';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

export const EditPaymentRecord = (props) => {
  const { isLeadSpecific = false } = props;

  const dispatch = useDispatch();
  const [billingContacts, setBillingContacts] = useState([]);
  const [typeOfPayment, setTypeOfPayment] = useState('offline');
  const [amount, setAmount] = useState();
  const [recordDate, setRecordDate] = useState();
  const [payFull, setPayFull] = useState(false);
  const [description, setDescription] = useState();
  let [formErrors, setFormErrors] = useState({
    contact: '',
    amount: '',
    record_date: '',
    payment_method: '',
    deposit_info: '',
  });
  const paymentMethods = [
    {
      label: 'Credit Card',
      value: 'Credit Card',
    },
    { label: 'Check', value: 'Check' },
    { label: 'Cash', value: 'Cash' },
    { label: 'Other', value: 'other' },
  ];
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const accountsList = useSelector((state) => state.accountsList.accounts.account);
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const [loading, setLoading] = useState(false);
  let [recordDetails, setRecordDetails] = useState();
  const { addToast } = useToast();
  const [trustAccountBalance, setTrustAccountBalance] = useState();
  const [selectedTrustAccount, setSelectedTrustAccount] = useState();
  const [trustAccounts, setTrustAccounts] = useState([]);
  const typeOfPayments = [
    {
      label: 'Offline',
      value: 'offline',
    },
    {
      label: 'From trust account',
      value: 'trust',
    },
  ];

  useEffect(() => {
    if (props.selectedRecord?.case_id) {
      clearFormError();
      loadBillingContacts(props.selectedRecord.case_id);
      //loadTrustAccountBalance(props.selectedRecord?.billing_contact_id)
      getPaymentRecord(props.selectedRecord.sk)
        .then((response) => {
          setRecordDetails({ ...response.data });
          let record_details = response.data;
          setAmount(record_details?.payment_record_amount);
          if (record_details.payment_record_date) setRecordDate(new Date(record_details.payment_record_date));
          if (record_details.payment_method) setSelectedPaymentMethod(record_details.payment_method);
          record_details.payment_record_status === 'FULL' ? setPayFull(true) : setPayFull(false);
          let payment_record_type = record_details.payment_record_type;
          payment_record_type === 'OFFLINE' ? setTypeOfPayment('offline') : setTypeOfPayment('trust');
          setDescription(record_details.description);
        })
        .catch((err) => {});
    }
  }, [props.selectedRecord]);

  useEffect(() => {
    totalCountEvent('account')
      .then((response) => {
        dispatch(listAccounts());
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    if (selectedTrustAccount?.account_id) {
      loadTrustAccountBalance(selectedTrustAccount?.account_id);
    }
  }, [selectedTrustAccount]);

  const onChangePayFull = (e) => {
    let due_amount = recordDetails?.due_amount;
    due_amount = due_amount ? parseFloat(due_amount) : 0;
    let payment_record_amount = recordDetails?.payment_record_amount;
    payment_record_amount = payment_record_amount ? parseFloat(payment_record_amount) : 0;
    let allowed_amount = due_amount + payment_record_amount;
    setPayFull(e.target.checked);
    if (e.target.checked) {
      setAmount(allowed_amount);
    }
  };

  useEffect(() => {
    setSelectedAccount();
    if (accountsList && accountsList.length > 0) {
      if (typeOfPayment === 'offline') {
        setAccounts([...accountsList]);
      } else {
        let _accounts = accountsList.filter((index) => index.account_type === 'operating');
        setAccounts([..._accounts]);
        let trust_accounts = accountsList.filter((index) => index.account_type === 'trust');
        setTrustAccounts([...trust_accounts]);
      }
      let deposit_account_name = recordDetails?.deposit_account_name;
      let debit_account_name = recordDetails?.debit_account_name;
      let selected_account = accountsList.filter((index) => index.account_name === deposit_account_name);
      let selected_trust_account = accountsList.filter((index) => index.account_name === debit_account_name);
      if (selected_account && selected_account.length > 0) setSelectedAccount(selected_account[0]);
      if (selected_trust_account && selected_trust_account.length > 0) setSelectedTrustAccount(selected_trust_account[0]);
    }
  }, [accountsList, typeOfPayment, recordDetails]);

  const loadBillingContacts = (case_id) => {
    getClientUserList(case_id, true)
      .then((response) => {
        let contacts = [];
        if (response?.data && response.data.length > 0) {
          response.data.forEach((index) => {
            let name = index.first_name ? index.first_name : '';
            if (index.last_name) name = name + ' ' + index.last_name;
            let obj = {
              name: name,
              contact_id: index.contact_id,
            };
            contacts.push(obj);
          });
        }
        setBillingContacts([...contacts]);
      })
      .catch((err) => {});
  };
  const loadTrustAccountBalance = (billing_contact_id) => {
    getTrustAccountBalance(billing_contact_id)
      .then((response) => {
        let available_balance = response?.data?.available_balance;
        available_balance = available_balance ? parseFloat(available_balance) : '';
        setTrustAccountBalance(available_balance);
      })
      .catch((err) => {});
  };

  const validateForm = () => {
    let due_amount = recordDetails?.due_amount;
    due_amount = due_amount ? parseFloat(due_amount) : 0;

    let payment_record_amount = recordDetails?.payment_record_amount;
    payment_record_amount = payment_record_amount ? parseFloat(payment_record_amount) : 0;

    let allowed_amount = due_amount + payment_record_amount;
    let isValid = true;
    if (!amount || amount <= 0) {
      isValid = false;
      formErrors.amount = 'Required a valid amount';
    }
    if (amount && amount > allowed_amount) {
      isValid = false;
      formErrors.amount = "Amount can't be higher than the due amount";
    }
    if (!recordDate) {
      isValid = false;
      formErrors.record_date = 'Required';
    }
    if (!selectedAccount) {
      isValid = false;
      formErrors.deposit_info = 'Required';
    }
    if (typeOfPayment === 'offline' && !selectedPaymentMethod) {
      isValid = false;
      formErrors.payment_method = 'Required';
    }
    if (typeOfPayment === 'trust' && amount && amount > trustAccountBalance) {
      isValid = false;
      formErrors.amount = "Amount can't be higher than the trust account balance";
    }
    setFormErrors({ ...formErrors });
    return isValid;
  };

  const onSubmit = () => {
    if (validateForm()) {
      if (!props.isEdit) {
        setLoading(true);
        let data = {
          description: description,
          payment_record_amount: amount,
          payment_record_date: recordDate ? format(recordDate, 'yyyy-MM-dd') : '',
          payment_method: selectedPaymentMethod,
          payment_record_status: amount === props.selectedRecord.due_amount ? 'FULL' : 'PARTIAL',
          payment_record_type: typeOfPayment === 'offline' ? 'OFFLINE' : 'TRUST',
          old_payment_record_amount: props.selectedRecord?.payment_record_amount,
        };
        data.deposit_account_id = selectedAccount?.account_id;
        if (typeOfPayment === 'trust') {
          data.debit_account_name = selectedTrustAccount?.account_name;
          data.debit_account_id = selectedTrustAccount?.account_id;
        }
        if (props.selectedRecord.payment_ref_number.includes('INV')) {
          data.payment_for = 'invoice';
        }
        let requestData = { ...props.selectedRecord, ...data };
        updatePaymentRecord(requestData, isLeadSpecific)
          .then((response) => {
            setLoading(false);
            addToast(
              false,
              toastConstant.toasterType.SUCCESS,
              toastConstant.api.SUCCESS,
              toastConstant.message.UPDATE_PAYMENT_RECORD_SUCCESS
            );

            props.onSuccess();
          })
          .catch((err) => {
            console.log('err', err);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.UPDATE_PAYMENT_RECORD_FAILED);
            setLoading(false);
          });
      }
    }
  };
  const clearFormError = () => {
    formErrors = {
      contact: '',
      amount: '',
      payment_method: '',
      deposit_info: '',
    };
    setFormErrors({ ...formErrors });
  };

  return (
    <Dialog
      visible={props.show}
      onHide={props.onHide}
      // //header={"Record Payment"}
      header={ModalHeader}
      className="create-msg-popup"
      closable={false}
    >
      <div>
        {props.selectedRecord && (
          <>
            <div className="row">
              <div className="d-flex justify-content-between align-items-center mt-3 mb-3">
                <div className="modal-heading F-size16 text-bold">{'Record Payment'}</div>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">{props.selectedRecord.case_name}</div>
                <div className="col-12 col-sm-8">${recordDetails && recordDetails.amount}</div>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">Type of Payment</div>
                <div className="col-12 col-sm-8">
                  <Dropdown
                    options={typeOfPayments}
                    value={typeOfPayment}
                    onChange={(e) => {
                      setTypeOfPayment(e.target.value);
                      formErrors.payment_method = '';
                      setFormErrors({ ...formErrors });
                      setSelectedPaymentMethod();
                    }}
                    filter
                    //showClear={(typeOfPayment ==="" )? false : true}
                  />
                  {/* <span
                  className={
                    typeOfPayment === "offline"
                      ? "text-primary-dark text-decoration-none"
                      : ""
                  }
                  onClick={() => {
                    setTypeOfPayment("offline");
                  }}
                  role="button"
                >
                  Offline
                </span>
                <span
                  className={
                    typeOfPayment === "trust"
                      ? "text-primary-dark text-decoration-none ms-3"
                      : "ms-3"
                  }
                  onClick={() => {
                    setTypeOfPayment("trust");
                  }}
                  role="button"
                >
                  From trust account
                </span> */}
                </div>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">
                  Contact
                  <span className="text-danger">*</span>
                </div>
                <div className="col-12 col-sm-8">
                  {/* <Dropdown options={billingContacts} optionLabel={"name"} filterBy={"name"} value={billingContact}
                /> */}
                  <InputText className="input-shadow w-100" value={props.selectedRecord?.billing_contact_name} disabled={true} />
                </div>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">
                  Amount
                  <span className="text-danger">*</span>
                </div>
                <div className="col-12 col-sm-8">
                  <div className="d-flex align-items-center d-flex align-items-center">
                    <InputText
                      className="input-shadow w-50 border-0 p-2"
                      type="number"
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                        formErrors.amount = '';
                        setFormErrors({ ...formErrors });
                      }}
                      disabled={payFull}
                    />
                    <label className="switch ms-2">
                      <input type="checkbox" checked={payFull} name="weekends" onChange={onChangePayFull} />{' '}
                      <span className="slider round"></span>
                    </label>
                    <span className="ms-2">Pay in full</span>
                  </div>

                  {formErrors && formErrors.amount && <span className="text-danger">{formErrors.amount}</span>}
                </div>
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">
                  Date
                  <span className="text-danger">*</span>
                </div>
                <div className="col-12 col-sm-8">
                  <Calendar
                    className="input-shadow w-100 border-0"
                    value={recordDate}
                    onChange={(e) => {
                      setRecordDate(e.value);
                      formErrors.record_date = '';
                      setFormErrors({ ...formErrors });
                    }}
                  />
                  {formErrors && formErrors.record_date && <span className="text-danger">{formErrors.record_date}</span>}
                </div>
              </div>
              {typeOfPayment === 'offline' && (
                <>
                  <div className="d-flex align-items-center py-2">
                    <div className="col-12 col-sm-4 p-medium">
                      Payment Method
                      <span className="text-danger">*</span>
                    </div>
                    <div className="col-12 col-sm-8">
                      <Dropdown
                        className="input-shadow w-100 border-0"
                        options={paymentMethods}
                        value={selectedPaymentMethod}
                        onChange={(e) => {
                          setSelectedPaymentMethod(e.value);
                          formErrors.payment_method = '';
                          setFormErrors({ ...formErrors });
                        }}
                        filter
                        //showClear={(selectedPaymentMethod ==="" )? false : true}
                      />
                      {formErrors && formErrors.payment_method && <span className="text-danger">{formErrors.payment_method}</span>}
                    </div>
                  </div>
                </>
              )}
              <div className="d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">
                  Deposit Info
                  <span className="text-danger">*</span>
                </div>
                <div className="col-12 col-sm-8">
                  <Dropdown
                    className="input-shadow w-100 border-0"
                    options={accounts}
                    value={selectedAccount}
                    onChange={(e) => {
                      setSelectedAccount(e.target.value);
                      formErrors.deposit_info = '';
                      setFormErrors({ ...formErrors });
                    }}
                    optionLabel={'account_name'}
                    filter
                    //showClear={(selectedAccount ==="" )? false : true}
                  />
                  {formErrors && formErrors.deposit_info && <span className="text-danger">{formErrors.deposit_info}</span>}
                </div>
              </div>
              <div className="d-flex align-items-center py-2">
                {typeOfPayment === 'trust' && (
                  <>
                    <div className="col-12 col-sm-4 p-medium">
                      Trust
                      <span className="text-danger">*</span>
                    </div>
                    <div className="col-12 col-sm-8">
                      <Dropdown
                        className="input-shadow w-100 border-0"
                        options={trustAccounts}
                        value={selectedTrustAccount}
                        onChange={(e) => {
                          setSelectedTrustAccount(e.target.value);
                          formErrors.trust_account = '';
                          setFormErrors({ ...formErrors });
                        }}
                        optionLabel={'account_name'}
                        filter
                        // showClear={(selectedTrustAccount ==="" )? false : true}
                      />
                      {formErrors && formErrors.trust_account && <span className="text-danger">{formErrors.trust_account}</span>}
                    </div>
                  </>
                )}
              </div>
              <div className="d-flex align-items-center py-2">
                <div className="col-12 col-sm-4 p-medium">Description</div>
                <div className="col-12 col-sm-8">
                  <TextSnippetWrapper
                    type="textarea"
                    className="input-shadow w-100 border-0 p-2"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="footer-btn">
              <div className="col-12 d-flex justify-content-end">
                <Button className="p-button-secondary outline mx-2" label="Cancel" onClick={props.onHide}></Button>
                <Button
                  className="p-button-secondary"
                  label={props.selectedExpense ? 'Update' : 'Save'}
                  onClick={onSubmit}
                  loading={loading}
                ></Button>
              </div>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};
