import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Header from './components/Header/Header';
import PersonalInformation from './components/PersonalInformation/PersonalInformation';
import Summary from './components/Summary/Summary';
import CloseLeadDialog from './components/CloseLeadDialog/CloseLeadDialog';
import ContactDetails from './components/ContactDetails/ContactDetails';
import { ConvertCaseDialogue } from './components/ConvertCaseDialog/ConvertCaseDialog';
import { useLeadContext } from 'modules/lead/Context/LeadContext';
import { useToggle } from 'hooks/useToggle';
import { confirmDialog } from 'primereact/confirmdialog';
import { closeLead } from 'services/leadServices';
import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';

export default function Overview({ leadDetails, loadLeadDetails }) {
  const [convertCaseToggle, setConvertCaseToggle] = useToggle();
  const [closeCaseToggle, setCloseCaseToggle] = useToggle();
  const [reopenLeadToggle, setReopenLeadToggle] = useToggle();
  const history = useHistory();
  const { addToast } = useToast();

  const { loadLeadSources, leadSourceList, leadStages, businessRole, relationRole } = useLeadContext();

  const onHide = (action) => {
    setConvertCaseToggle();
    if (action === 'converted') history.replace('/leads');
  };

  const onHideCloseCase = (e) => {
    setCloseCaseToggle();
    if (e === 'closed') loadLeadDetails();
  };

  const onCaseClose = (sk) => {
    const stageIndex = leadStages.findIndex((v) => v?.sk === sk && v?.lead_stage_name?.toLowerCase() == 'closed');
    if (stageIndex > -1) {
      setCloseCaseToggle();
    }
  };

  const onOpenLead = (sk) => {
    closeLead(sk, { is_open: true })
      .then(() => {
        loadLeadDetails();
        addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.LEAD_OPEN_SUCCESS);
      })
      .catch(() => {
        addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.LEAD_OPEN_FAILURE);
      });
  };

  useEffect(() => {
    if (reopenLeadToggle) {
      confirmDialog({
        className: 'common-popup-style',
        icon: 'pi pi-exclamation-triangle',
        header: 'Confirm Reopen Lead',
        message: 'Do you want to reopen this lead?',
        accept: () => onOpenLead(leadDetails?.sk),
        onHide: () => {},
        reject: () => {},
      });
    }
  }, [reopenLeadToggle]);

  return (
    <React.Fragment>
      <GlobalLoader />
      <Header
        {...{
          leadDetails,
          setConvertCaseToggle,
          setCloseCaseToggle,
          setReopenLeadToggle,
        }}
      />
      <div className="custom-fields">
        <div className="my-3 lead-info">
          <div className="col-lg-12 col-12 mb-4">
            <Summary
              {...{
                leadDetails,
                loadLeadSources,
                leadSourceList,
                onCaseClose,
                leadStages,
                loadLeadDetails,
              }}
            />
          </div>
          <PersonalInformation {...{ leadDetails, loadLeadDetails }} />
          <div className="row my-4 py-2">
            {leadDetails?.contact?.length > 0 && (
              <div className="col-lg-6 col-12 mb-4">
                <ContactDetails {...{ leadDetails, businessRole, relationRole, loadLeadDetails }} />
              </div>
            )}
          </div>
        </div>
      </div>
      {convertCaseToggle && <ConvertCaseDialogue onHide={onHide} leadDetails={leadDetails} />}
      {closeCaseToggle && <CloseLeadDialog onHide={onHideCloseCase} leadDetails={leadDetails} />}
    </React.Fragment>
  );
}
