import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputSwitch } from 'primereact/inputswitch';
import { Tooltip } from 'primereact/tooltip';
import { MultiSelect } from 'primereact/multiselect';

import { getNoteDetails, updateCaseNote } from 'services/CaseNoteServices';
import constants from 'constants/index';

import { toastConstant } from 'constants/toastmessage';
import { useToast } from 'context/ToastContext';
import { UserDetailsContext } from 'context/userDetailsContext';
import { findArrayDifferences, getTenantDetails, handleRedirection, toCapitalized } from 'utils/utils';
import { handleDateTimeOffset } from 'utils/utility_functions/timezone';
import classNames from 'classnames';
import { GroupUserTooltip } from 'components/UI/GroupUserTooltip/GroupUserTooltip';

import { generateFullName } from 'utils/generateFullNameUtils';
import GlobalLoader from 'components/GlobalLoader/GlobalLoader';
import { totalCountEvent } from 'services/generalServices';
import ImageComponent from 'shared/ImageComponent';

import { ReactComponent as OutgoingIcon } from 'assets/svg/outgoing_call.svg';
import { ReactComponent as IncomingIcon } from 'assets/svg/incoming_call.svg';
import { ReactComponent as FilesIcon } from 'assets/svg/files.svg';
import { listUsers } from 'services/users/userServices';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const NoteDetails = (props) => {
  const { isFromCaseSpecificModule, isCaseClosed, onMountUnMount, onOperationBreak } = props;
  const { addToast } = useToast();
  const [tenantId] = getTenantDetails();
  const [resolved, setResolved] = useState(false);
  const [titleEdit, setTitleEdit] = useState(false);
  const [title, setTitle] = useState('');
  const [descriptionEdit, setDescriptionEdit] = useState(false);
  const [description, setDescription] = useState('');
  const [toggleResolution, setToggleResolution] = useState(false);
  const [resolutionEdit, setResolutionEdit] = useState(false);
  const [resolvedEdit, setResolvedEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userContext = useContext(UserDetailsContext);
  const [formError, setFormError] = useState({});

  const [firmUsersWithGroups, setFirmUsersWithGroups] = useState([]);
  const [teamMemberOptions, setTeamMemberOptions] = useState([]);
  const [teamMemberArr, setTeamMemberArr] = useState([]);
  const [oldTeamMemberArr, setOldTeamMemberArr] = useState([]);

  const [groups, setGroups] = useState([]);
  const [firmUsers, setFirmUsers] = useState([]);
  const [filteredUserList, setFilteredUserList] = useState();

  const [areArraysEqual, setAreArraysEqual] = useState(true);
  const [userList, setUserList] = useState([]);
  const [currentData, setCurrentData] = useState('');
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  const fetchData = async () => {
    try {
      const response = await getNoteDetails({ pk: location?.state?.pk, sk: location?.state?.sk, id });
      setCurrentData(response?.data);
      setTitle(response?.data?.title);
      setDescription(response?.data?.description);
      setToggleResolution(response?.data?.resolution_status);
      setResolved(response?.data?.resolved_status);
    } catch (err) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getNoteDetails({ pk: location?.state?.pk, sk: location?.state?.sk, id });
        setCurrentData(response?.data);
        setTitle(response?.data?.title);
        setDescription(response?.data?.description);
        setToggleResolution(response?.data?.resolution_status);
        setResolved(response?.data?.resolved_status);
      } catch (err) {}
    };
    fetchData();
  }, []);

  useEffect(() => {
    isFromCaseSpecificModule && typeof onMountUnMount === 'function' && onMountUnMount('update-note', 'mounted');
    return () => {
      isFromCaseSpecificModule && typeof onOperationBreak === 'function' && onOperationBreak();
    };
  }, []);

  const loadUserList = () => {
    totalCountEvent('user').then((response) => {
      listUsers({ is_include_groups: true, is_confirmed_user: true, status: 'all', limit: response.data })
        .then((response) => setUserList(response.data?.firm_users || []))
        .catch((error) => console.log(error));
    });
  };

  const ChangeCaseUserData = (data) =>
    data?.map((v) => ({
      ...v,
      label: generateFullName(v),
      value: v.client_cognito_username || v.contact_id,
    }));

  function combineUserAndGroup(response) {
    const groupedContacts = {};

    if (response?.length > 0) {
      response.forEach((element) => {
        const contactType = element.res_type === 'user-group' ? 'Groups' : 'Users';

        if (!groupedContacts[contactType]) {
          groupedContacts[contactType] = {
            label: contactType,
            items: [],
          };
        }

        if (element?.res_type === 'user-group') {
          groupedContacts[contactType].items.push({
            label: element.group_name,
            value: element.user_group_id,
            ...element,
          });
        } else {
          function generateLabel(element) {
            if (element.contact_type === 'person' || element.first_name || element.middle_name || element.last_name) {
              return generateFullName(element);
            }
            return element.title;
          }

          const label = generateLabel(element);

          groupedContacts[contactType].items.push({
            label: label,
            value: element.client_cognito_username ? element.client_cognito_username : element.contact_id,
            email: element.email,
            is_create_client_portal: element.is_create_client_portal,
            access_level: element.access_level,
            ...element,
          });
        }
      });
    }

    // return Object.values(groupedContacts).reverse();
    return Object.values(groupedContacts);
  }

  const handleTeamMemberChange = (selectedOption) => {
    setTeamMemberArr(selectedOption);
    let data = {};
    if (selectedOption && selectedOption?.length) {
      data.teamMember = false;
    } else {
      toggleResolution ? (data.teamMember = true) : (data.teamMember = false);
    }
    setFormError(data);
  };

  function onChangeAttendeesWhileEditing(newArray, oldArray) {
    const { added, removed } = findArrayDifferences(oldArray, newArray);

    const contacts = firmUsersWithGroups.reduce((arr, val) => [...arr, ...val.items], []);
    if (added?.[0]) {
      const addedContact = contacts?.find(
        (v) => (v?.client_cognito_username || v.contact_id) === added?.[0] || v?.user_group_id?.includes(added?.[0])
      );
      if (addedContact?.user_group_id) {
        const { group_name, user_group_id, sk } = addedContact;

        let updatedGroups = [
          ...groups,
          {
            group_name,
            group_id: user_group_id,
            sk: sk,
          },
        ];
        setGroups(updatedGroups);
      } else if (addedContact?.contact_id || addedContact?.client_cognito_username) {
        const contactIndex = firmUsers?.findIndex((c) => c?.team_id === addedContact?.contact_id);
        if (contactIndex !== -1) {
          let users = firmUsers?.map((c, i) => {
            if (i === contactIndex) {
              return { ...c, is_individual_user: true };
            } else return c;
          });
          setFirmUsers(users);
        } else {
          const { firm_user_id, user_id, is_confirmed, status } = addedContact;

          let new_users = [
            ...firmUsers,
            {
              team_id: user_id,
              team_member_name: generateFullName(addedContact),
              is_individual_user: true,
              firm_user_id,
              is_confirmed,
              status,
            },
          ];
          setFirmUsers(new_users);
        }
      }
    }
    if (removed?.[0]) {
      const removedContact = contacts?.find(
        (v) => (v?.client_cognito_username ?? v.contact_id) === removed?.[0] || v?.user_group_id?.includes(removed?.[0])
      );
      if (removedContact?.user_group_id) {
        let updatedGroups = groups?.filter((g) => g.group_id !== removedContact.user_group_id);
        setGroups(updatedGroups);
      } else if (removedContact?.contact_id) {
        const newArray = [];

        firmUsers.forEach((item) => {
          if (item?.team_id === removedContact?.user_id && !!item?.is_group_user === false) {
            // Skip this item, effectively removing it
            return;
          }

          if (item?.contact_id === removedContact?.contact_id && !!item?.is_group_user === true) {
            newArray.push({
              ...item,
              is_individual_user: false,
            });
          } else newArray.push(item);
        });
        setFirmUsers(newArray);
      }
    }
  }

  const getCreated = () => {
    let rowData = currentData;

    let res = `Created: ${handleDateTimeOffset(
      userContext?.userDetails?.timezone,
      rowData.time,
      constants.month_date_year_time_12_format
    )}`;
    return res;
  };

  const getUpdated = () => {
    let rowData = currentData;
    let res = `Updated: ${handleDateTimeOffset(
      userContext?.userDetails?.timezone,
      rowData.last_updated_time,
      constants.month_date_year_time_12_format
    )}`;
    return res;
  };

  const cancelEdit = () => {
    setDescriptionEdit(false);
    setTitleEdit(false);
    setResolutionEdit(false);
    setResolvedEdit(false);
    if (currentData) {
      if (currentData.description) {
        setDescription(currentData.description);
      }
      if (currentData.title) {
        setTitle(currentData.title);
      }
      if (currentData.resolution_status) {
        setToggleResolution(true);
      } else {
        setToggleResolution(false);
      }
      if (currentData.resolved_status) {
        setResolved(true);
      } else {
        setResolved(false);
      }
      setTeamMemberArr(oldTeamMemberArr);
      setFormError({});
    }
  };

  const getSelectedCaseUsers = (e) => {
    let teamMembers = teamMemberArr?.flatMap((val) => {
      const fullData = teamMemberOptions?.find((item) => {
        return item.value === val;
      });

      if (!fullData) {
        return [];
      }

      return [
        {
          team_id: fullData?.value,
          team_member_name: fullData?.label,
          firm_user_id: fullData?.firm_user_id,
        },
      ];
    });

    return teamMembers;
  };

  function navigateToModule(item, module) {
    let url = '/';
    if (module === 'group') {
      if (item.group_status === 'Deleted') {
        return;
      }
      url = '/settings/groups';
    } else if (module === 'user') {
      url = item?.created_by_firm_user_id ? `/settings/team-management/${item?.created_by_firm_user_id}` : '/settings/team-management';
    } else if (module === 'client') {
      url = item ? `/contacts/${item}` : '/contacts';
    }
    handleRedirection(history, url);
  }

  const updateCaseNoteDetails = () => {
    let data = JSON.parse(JSON.stringify(currentData));
    if (descriptionEdit || titleEdit || resolutionEdit || resolvedEdit || !areArraysEqual) {
      if (toggleResolution && teamMemberArr?.length === 0) {
        let data = {};
        data.teamMember = true;
        setFormError(data);
        return;
      }

      setIsLoading(true);
      if (data.description !== description) {
        data.description = description;
      }

      if (data.title !== title) {
        data.title = title;
      }

      if (data.resolution_status !== toggleResolution) {
        data.resolution_status = toggleResolution;
      }

      if (data.resolved_status !== resolved) {
        data.resolved_status = resolved;
      }
      data.resolution_status = toggleResolution;

      if (currentData.note_type === 'case_note') {
        data.groups = groups;
        data.firm_user = firmUsers;
      }

      if (currentData.note_type === 'call_log') {
        data.firm_user = getSelectedCaseUsers();
      }

      updateCaseNote(data, props.isLeadSpecific)
        .then(() => {
          isFromCaseSpecificModule && typeof onMountUnMount === 'function' && onMountUnMount('update-note', 'unmounted');
          setIsLoading(false);
          fetchData().then(() => {
            setTitleEdit(false);
            setDescriptionEdit(false); // Set description edit to false after fetching data
            setResolutionEdit(false);
            setResolvedEdit(false);
          });
          handleTeamMembers();
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.NOTE_UPDATED_CREATED);
        })
        .catch(() => {
          setIsLoading(false);
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.NOTE_UPDATED_FAILED);
        });
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentData?.note_type === 'case_note' || currentData?.note_type === 'call_log') {
      const oldIDs = oldTeamMemberArr?.map((member) => member).sort();
      const newIDs = teamMemberArr?.map((member) => member).sort();
      const areEqual = JSON.stringify(newIDs) === JSON.stringify(oldIDs);
      setAreArraysEqual(areEqual);
    }
  }, [oldTeamMemberArr, teamMemberArr]);

  useEffect(() => {
    if (currentData) {
      if (currentData?.description) {
        setDescription(currentData.description);
      }
      if (currentData?.title) {
        setTitle(currentData?.title);
      }
      if (currentData?.resolution_status) {
        setToggleResolution(true);
      }
      if (currentData?.resolved_status) {
        setResolved(true);
      }
    }
    loadUserList();
  }, []);

  useEffect(() => {
    if (userList?.length) {
      if (currentData?.note_type === 'case_note') {
        // let combined_data = combineUserAndGroup(userList);
        let combined_data = combineUserAndGroup(filteredUserList);
        setFirmUsersWithGroups(combined_data);

        let firm_users = currentData?.firm_user || [];
        let groups = currentData?.groups?.filter((v) => v?.group_id) || [];

        let individualUsers = firm_users?.filter((v) => Boolean(v?.is_individual_user));
        let individualUserTeamIds = individualUsers?.map((v) => v?.team_id);

        let selected_items = [...individualUserTeamIds, ...groups?.map((v) => v?.group_id)];
        setTeamMemberArr(selected_items ?? []);
        setOldTeamMemberArr(selected_items ?? []);
        setFirmUsers(firm_users ?? []);
        setGroups(groups ?? []);
      } else if (currentData.note_type === 'call_log') {
        const filterDataWithoutGroups = userList?.filter((item) => item?.res_type === 'user');
        let x = ChangeCaseUserData(filterDataWithoutGroups);
        setTeamMemberOptions(x);
        let firm_users = currentData.firm_user;
        let selected_items = firm_users?.map((v) => v?.team_id);
        setTeamMemberArr(selected_items ?? []);
        setOldTeamMemberArr(selected_items ?? []);
      }
    } else {
      setFirmUsersWithGroups([]);
      setTeamMemberOptions([]);
    }
  }, [userList, currentData, filteredUserList]);

  const handleTeamMembers = () => {
    totalCountEvent('user').then((response) => {
      listUsers({ is_include_groups: true, is_confirmed_user: true, status: '', limit: response.data })
        .then((response) => setUserList(response.data?.firm_users || []))
        .catch((error) => console.log(error));
    });

    if (userList?.length) {
      if (currentData?.note_type === 'case_note') {
        let combined_data = combineUserAndGroup(userList);
        setFirmUsersWithGroups(combined_data);

        let firm_users = currentData?.firm_user || [];
        let groups = currentData?.groups?.filter((v) => v?.group_id) || [];

        let individualUsers = firm_users?.filter((v) => Boolean(v?.is_individual_user));
        let individualUserTeamIds = individualUsers?.map((v) => v?.team_id);

        let selected_items = [...individualUserTeamIds, ...groups?.map((v) => v?.group_id)];
        setTeamMemberArr(selected_items ?? []);
        setOldTeamMemberArr(selected_items ?? []);
        setFirmUsers(firm_users ?? []);
        setGroups(groups ?? []);
      } else if (currentData.note_type === 'call_log') {
        const filterDataWithoutGroups = userList?.filter((item) => item?.res_type === 'user');
        let x = ChangeCaseUserData(filterDataWithoutGroups);
        setTeamMemberOptions(x);
        let firm_users = currentData.firm_user;
        let selected_items = firm_users?.map((v) => v?.team_id);
        setTeamMemberArr(selected_items ?? []);
        setOldTeamMemberArr(selected_items ?? []);
      }
    } else {
      setFirmUsersWithGroups([]);
      setTeamMemberOptions([]);
    }
  };

  useEffect(() => {
    if (userList && currentData) {
      let finalUserList = userList.filter(
        (group) =>
          (group?.res_type === 'user-group' && group?.status === 'ARCHIVED' && currentData?.group_list?.includes(group?.user_group_id)) ||
          group?.status === 'ACTIVE' ||
          group?.res_type !== 'user-group'
      );
      setFilteredUserList(
        finalUserList.filter((user) => {
          // If user_group_id is present, include the user directly
          if (user.hasOwnProperty('user_group_id')) {
            return true;
          } else {
            const isActive = user.status === 'ACTIVE' && user.is_confirmed;
            const isArchivedConfirmed = user.status === 'ARCHIVED' || user.is_confirmed === false;
            // const isInGroup = currentData.accessible_list.includes(user.client_cognito_username);

            const isIndividualUser = currentData?.firm_user?.some(
              (firmUser) => firmUser?.is_individual_user && firmUser?.team_id === user?.client_cognito_username
            );
            return isActive || (isArchivedConfirmed && isIndividualUser);
          }
        })
      );
    }
  }, [userList, currentData]);

  const assignToItemTemplate = (option) => {
    if (option?.res_type === 'user' && option?.is_confirmed === false && option?.status === 'ACTIVE') {
      return `${option?.label} (Unconfirmed)`;
    } else if (option?.res_type === 'user' && option?.is_confirmed && option?.status === 'ARCHIVED') {
      return `${option?.label} (Inactive)`;
    } else if (option?.res_type === 'user' && option?.is_confirmed === false && option?.status === 'ARCHIVED') {
      return `${option?.label} (Inactive)`;
    } else if (option?.res_type === 'user-group' && option?.status === 'ARCHIVED') {
      return `${option?.label} (Inactive)`;
    }
    return option?.label;
  };

  const getStatusLabel = (option) => {
    if (option) {
      if (option?.is_confirmed === false && (option?.status === 'ACTIVE' || option?.status === undefined)) {
        return '(Unconfirmed)';
      } else if ((option?.is_confirmed || option?.is_confirmed === undefined) && option?.status === 'ARCHIVED') {
        return '(Archived)';
      } else if (option?.is_confirmed === false && option?.status === 'ARCHIVED') {
        return '(Archived)';
      }
    }
    return '';
  };

  return (
    <React.Fragment>
      <GlobalLoader />
      {currentData?.case_note_id && (
        <React.Fragment>
          <div className="row mb-4">
            <div className="col-sm-12">
              <i
                className="fas fa-arrow-left pointer"
                onClick={() => {
                  const url = `/${props.isLeadSpecific ? 'leads' : 'cases'}/${props?.caseDetails?.case_id}/${
                    props.isLeadSpecific ? 'note' : 'notes'
                  }`;
                  handleRedirection(history, url);
                }}
              ></i>
              <span style={{ marginLeft: '0.5rem' }}>Go Back to Notes List</span>
            </div>
          </div>
          <div className="p-card shadow-middle me-3 mb-4">
            <div className="p-card-body">
              <div className="p-card-content pt-0">
                <div className="d-flex align-items-center mb-2 pb-3 border-bottom-dark">
                  <div className="d-block">
                    <div className="d-flex align-items-center">
                      {titleEdit ? (
                        <>
                          <TextSnippetWrapper
                            type="text"
                            className=" rounded flex-grow-1 w-75"
                            placeholder="Title"
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                            name="title"
                          />
                          <span
                            onClick={(e) => {
                              setTitleEdit(!titleEdit);
                            }}
                            className="pointer px-2"
                          >
                            <i className="pi pi-times-circle"></i>
                          </span>
                        </>
                      ) : (
                        <div
                          onClick={(e) => {
                            !isCaseClosed && setTitleEdit(!titleEdit);
                          }}
                          className="d-flex align-items-center"
                        >
                          {currentData && currentData.note_type === 'case_note' ? (
                            <FilesIcon width={18} height={18} className="me-2" />
                          ) : currentData.note_type === 'call_log' && currentData.call_type === 'outgoing' ? (
                            <OutgoingIcon width={18} height={18} className="me-2" />
                          ) : currentData.note_type === 'call_log' && currentData.call_type === 'incoming' ? (
                            <IncomingIcon width={18} height={18} className="me-2" />
                          ) : null}
                          <h5 className="mb-0 text-bold">{currentData && currentData.title ? currentData.title : '-'} </h5>
                        </div>
                      )}
                    </div>
                  </div>
                  {currentData.resolution_status && !currentData.resolved_status ? (
                    <div className="blue-tag ms-3">
                      <p className="blue-tag-value">Needs Resolution</p>
                    </div>
                  ) : currentData.resolved_status ? (
                    <div className="green-tag ms-3">
                      <p className="green-tag-value">Resolved</p>
                    </div>
                  ) : null}
                </div>
                <div className="d-flex">
                  <div className="d-flex align-items-center mb-1 p-avatar-clickable" onClick={() => navigateToModule(currentData, 'user')}>
                    <ImageComponent
                      filePath={`${tenantId}/${currentData?.created_by_firm_user_id}/profile_image`}
                      fileName={currentData?.created_by_name?.['first_name']}
                      fileSize="small.jpg"
                    />
                    <div className="d-flex flex-column">
                      <span className="text-decoration-none text-primary-dark text-bold case-details-link text-capitalize">
                        {currentData.created_by_name?.first_name} {currentData.created_by_name?.last_name}
                      </span>
                      {/* <span class="F-size12 black-500">Client</span> */}
                    </div>
                  </div>
                  <div className="ms-auto text-end">
                    <p className="F-size14 mb-1">{getCreated()}</p>
                    <p className="black-500 text-hint">{getUpdated()}</p>
                  </div>
                </div>
                <div className="d-flex case-notes-assign">
                  {/* {currentData?.note_type === 'case_note' ? ( */}
                  {currentData?.note_type === 'case_note' ? (
                    <div className="d-flex align-items-center py-2 col-8 text-nowrap">
                      <div className="p-medium me-2">Assign to Firm User {toggleResolution && <span className="alert-star"> *</span>}</div>
                      <div className="col-sm-8 col-8">
                        <MultiSelect
                          value={firmUsersWithGroups?.length ? teamMemberArr : []}
                          placeholder="Select"
                          onChange={(e) => {
                            onChangeAttendeesWhileEditing(e.value, teamMemberArr);
                            handleTeamMemberChange(e.value);
                          }}
                          display="chip"
                          className="input-shadow w-100"
                          filter
                          options={firmUsersWithGroups ?? []}
                          optionValue={'value'}
                          optionLabel="label"
                          optionGroupLabel="label"
                          optionGroupChildren="items"
                          itemTemplate={assignToItemTemplate}
                          emptyFilterMessage="No records found"
                          showSelectAll={false}
                          name="team_member"
                          disabled={isCaseClosed}
                        />
                        {toggleResolution && formError && formError.teamMember && <span style={{ color: '#dc3545' }}>Required</span>}
                      </div>
                    </div>
                  ) : null}
                  {currentData?.note_type === 'call_log' ? (
                    <div className="d-flex align-items-center py-2 col-8 case-notes-assign">
                      <div className="me-2 p-medium">Assign to Firm User {toggleResolution && <span className="alert-star"> *</span>}</div>
                      <div className="col-sm-8">
                        <MultiSelect
                          value={teamMemberOptions?.length ? teamMemberArr : []}
                          placeholder="Select"
                          onChange={(e) => handleTeamMemberChange(e.value)}
                          display="chip"
                          className="input-shadow w-100"
                          filter
                          options={teamMemberOptions ?? []}
                          optionLabel="label"
                          optionValue="value"
                          emptyFilterMessage="No records found"
                          showSelectAll={false}
                          name="team_member"
                          disabled={isCaseClosed}
                        />
                        {toggleResolution && formError && formError.teamMember && <span style={{ color: '#dc3545' }}>Required</span>}
                      </div>
                    </div>
                  ) : null}
                  <div className="d-flex ms-auto align-items-center col-4 justify-content-end required-switch">
                    {currentData && currentData.resolution_status ? (
                      <>
                        <label className="ps-2 p-medium align-items-center d-flex me-2">Resolved</label>
                        <InputSwitch
                          onChange={(e) => {
                            setResolved(e.value);
                            setResolvedEdit(!resolvedEdit);
                          }}
                          checked={resolved}
                          disabled={isCaseClosed}
                        />
                      </>
                    ) : (
                      <>
                        <label className="ps-2 p-medium align-items-center d-flex me-2">Needs Resolution</label>
                        <InputSwitch
                          onChange={(e) => {
                            setToggleResolution(e.value);
                            setResolutionEdit(!resolutionEdit);
                          }}
                          checked={toggleResolution}
                          disabled={isCaseClosed}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 col-12 d-flex mb-1">
                    {descriptionEdit ? (
                      <>
                        <TextSnippetWrapper
                          type="textarea"
                          className=" rounded flex-grow-1 w-100 case-note-area"
                          placeholder="Description"
                          value={description}
                          onChange={(e) => {
                            setDescription(e.target.value);
                          }}
                          name="description"
                          autoResize
                        />
                        <span
                          onClick={(e) => {
                            setDescriptionEdit(!descriptionEdit);
                          }}
                          className="pointer px-2"
                        >
                          <i className="pi pi-times-circle"></i>
                        </span>
                      </>
                    ) : (
                      <Card className="w-100  border rounded-3 shadow-sm">
                        <pre
                          className="case-note-text"
                          onClick={(e) => {
                            !isCaseClosed && setDescriptionEdit(!descriptionEdit);
                          }}
                        >
                          {currentData && currentData.description}
                        </pre>
                      </Card>
                    )}
                  </div>
                  <div className="col-md-4 col-12 case-notes-details mb-1">
                    <div className="d-flex align-items-center col-sm-12">
                      {userContext.getPermission('cases') === constants.permissions.WRITE && (
                        <>
                          <div className="d-flex justify-content-between">
                            <div className="">
                              {currentData && currentData.note_type === 'call_log' && (
                                <>
                                  <div className="d-flex vertical-align-middle text-align-center my-2">
                                    <span className="text-medium">Call type : </span>{' '}
                                    <span className="text-capitalize mx-1">{currentData.call_type}</span>
                                  </div>{' '}
                                </>
                              )}
                              {currentData?.note_type === 'call_log' && currentData?.call_type === 'incoming' && (
                                <>
                                  <div className="my-2">
                                    <span className="text-medium">Call For : </span>
                                    {currentData?.call_for?.map((item, index, array) => (
                                      <span
                                        key={index}
                                        className="F-size14 text-primary-dark text-decoration-none case-details-link pointer"
                                        onClick={(e) => navigateToModule(item, 'user')}
                                      >
                                        {` ${toCapitalized(item?.team_member_name)}`}
                                        {index !== array.length - 1 ? ',' : ''}
                                      </span>
                                    ))}
                                  </div>
                                  <div className="my-2">
                                    <span className="text-medium">Caller Name : </span>
                                    <span
                                      className="F-size14 text-primary-dark text-decoration-none case-details-link pointer"
                                      onClick={(e) => navigateToModule(currentData?.caller_name?.client_id, 'client')}
                                    >
                                      {` ${toCapitalized(currentData?.caller_name?.client_name)}`}
                                    </span>
                                  </div>
                                </>
                              )}
                              {currentData?.note_type === 'call_log' && currentData?.call_type === 'outgoing' && (
                                <>
                                  <div className="my-2">
                                    <span className="text-medium">Caller Name : </span>
                                    <span
                                      className="F-size14 text-primary-dark text-decoration-none case-details-link pointer"
                                      onClick={(e) => navigateToModule(currentData?.person_called?.client_id, 'client')}
                                    >
                                      {` ${toCapitalized(currentData?.person_called?.client_name || '')}`}
                                    </span>
                                  </div>
                                </>
                              )}
                              {currentData?.note_type === 'call_log' ? (
                                <div className="my-2">
                                  <span className="text-medium">Phone Number : </span>
                                  <span className="F-size14 text-decoration-none pointer">{currentData?.phone_number}</span>
                                </div>
                              ) : null}
                              <div className="my-2">
                                <span className="text-medium">Assignees : </span>

                                {currentData?.note_type === 'case_note' ? (
                                  <>
                                    {currentData?.groups?.map((group, index, array) => (
                                      <span
                                        key={index}
                                        className={classNames({
                                          'F-size14 text-primary-dark text-decoration-none case-details-link pointer': true,
                                          'disabled-element': group?.group_status === 'Deleted',
                                          ['name-' + index]: true,
                                        })}
                                        onClick={(e) => group?.group_status !== 'Deleted' && navigateToModule(group, 'group')}
                                      >
                                        {` ${toCapitalized(group?.group_name)} (Group${
                                          group?.group_status === 'ARCHIVED'
                                            ? ' - Archived'
                                            : group?.group_status === 'Deleted'
                                            ? ' - Deleted'
                                            : ''
                                        })`}

                                        {index !== array.length - 1 && (
                                          <>,&nbsp;</> // Add a comma and space if not the last element
                                        )}

                                        {index === array.length - 1 &&
                                          currentData?.firm_user?.filter((data) => data.is_individual_user).length > 0 && (
                                            <>,&nbsp;</> // Add a comma and space if not the last element
                                          )}

                                        <Tooltip target={`.name-${index}`} position="top">
                                          {GroupUserTooltip(group, currentData?.firm_user)}
                                        </Tooltip>
                                      </span>
                                    ))}
                                    {currentData?.firm_user
                                      ?.filter((data) => data.is_individual_user)
                                      ?.map((item, index, array) => (
                                        <span
                                          key={index}
                                          className="F-size14 text-primary-dark text-decoration-none case-details-link pointer"
                                          onClick={(e) => navigateToModule(item, 'user')}
                                        >
                                          {` ${toCapitalized(item?.team_member_name)} `}
                                          {getStatusLabel(item)}
                                          {/* {item?.status === 'ARCHIVED'
                                            ? ` (${
                                                item?.status?.toLowerCase()?.charAt(0)?.toUpperCase() +
                                                item?.status?.slice(1)?.toLowerCase()
                                              })`
                                            : ''} */}

                                          {index !== array.length - 1 ? ',' : ''}
                                        </span>
                                      ))}

                                    {!currentData?.groups_list && !currentData?.firm_user && <span>Not Assigned</span>}
                                  </>
                                ) : currentData?.note_type === 'call_log' ? (
                                  <>
                                    {currentData?.firm_user?.map((item, index, array) => (
                                      <span
                                        key={index}
                                        className="F-size14 text-primary-dark text-decoration-none case-details-link pointer"
                                        onClick={(e) => navigateToModule(item, 'user')}
                                      >
                                        {` ${toCapitalized(item?.team_member_name)}`}
                                        {index !== array.length - 1 ? ',' : ''}
                                      </span>
                                    ))}
                                    {!currentData?.firm_user || currentData?.firm_user?.length === 0 ? <span>Not Assigned</span> : null}
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="text-end pe-5">
                  {descriptionEdit || titleEdit || resolutionEdit || resolvedEdit || !areArraysEqual ? (
                    <>
                      <Button
                        type="button"
                        label="Save"
                        className="p-button p-button-secondary"
                        onClick={updateCaseNoteDetails}
                        loading={isLoading}
                      />
                      <Button type="button" label="Cancel" className="p-button p-button-sec ms-2" onClick={cancelEdit} />
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default NoteDetails;
