import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React from 'react';
import { convertToTimezoneWithStartOfDayFormat } from 'utils/utility_functions/timezone';
import { fixToTwoDecimal, formatNumbersToCurrencyString } from 'utils/utils';
import {
  actionBody,
  billableTimeEntriesBody,
  caseNameBody,
  dateTemplate,
  durationTemplate,
  teamMemberBody,
  teamMemberEditTimeEntries,
  timeEntryDataTableFooterKeyTemplate,
  timeEntryDataTableFooterValueTemplate,
  totalBody,
  totalHoursTemplate,
} from './helpers/timeEntriesDataTableHelpers';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const TimeEntries = (props) => {
  const {
    activityTypes,
    caseDetails,
    timeEntires,
    userContext,
    addTimeEntry,
    timeEntiresError,
    setTimeEntries,
    setTimeEntriesError,
    isChanged,
    setIsChanged,
    deleteTimeEntry,
    isEverGreenRetainer,
    isStandardRetainer,
    teamMembers,
    totalHours,
    timeEntriesTotal,
    rateWithTax,
    selectedCase,
  } = props || {};

  const onChangeTeamMemberEditTimeEntries = (e, index) => {
    //TODO:FOUND direct state update - must remoe it
    let name = e.target?.value?.name;
    let contact_id = e.target?.value?.contact_id;
    let team_member = caseDetails?.team_member?.filter((index) => index.contact_id === contact_id);
    if (team_member && team_member.length > 0) timeEntires[index].hour_rate = team_member[0]?.rate;
    timeEntires[index].team_member_name = name;
    timeEntires[index].team_member_id = contact_id;
    timeEntiresError[`${index}_team_member`] = '';
    setTimeEntries([...timeEntires]);
    setTimeEntriesError({ ...timeEntiresError });
    isChanged.time_entries = true;
    setIsChanged({ ...isChanged });
  };

  const onChangeBillable = (e, index) => {
    //TODO:FOUND direct state update - must remoe it

    let timeEnriesErrors = { ...timeEntiresError };

    for (let key in timeEnriesErrors) {
      if (key.includes(index.toString())) {
        timeEnriesErrors[key] = '';
      }
    }

    timeEntires[index].billable = e.target.checked;
    isChanged.time_entries = true;

    setTimeEntriesError(timeEnriesErrors);
    setTimeEntries([...timeEntires]);
    setIsChanged({ ...isChanged });
  };

  return (
    <>
      <DataTable
        className="p-datatable-responsive"
        value={timeEntires}
        editMode="cell"
        emptyMessage={() => <div onClick={addTimeEntry}>No records found</div>}
      >
        <Column
          header="Date"
          field="time_entry_date"
          body={(rowData, props) => {
            return dateTemplate({ rowData, props, timeEntiresError });
          }}
          editor={(props) => {
            //TODO:convert it to template
            return (
              <div className="table-input">
                <Calendar
                  value={props.rowData.time_entry_date}
                  onChange={(e) => {
                    let index = props.rowIndex;
                    let date = e.value;
                    timeEntires[index].time_entry_date = convertToTimezoneWithStartOfDayFormat(date, userContext?.userDetails.timezone);
                    timeEntiresError[`${index}_date`] = ''; //TODO:DIRECT state mutation not recommended
                    setTimeEntries([...timeEntires]);
                    setTimeEntriesError({
                      ...timeEntiresError,
                    });
                    isChanged.time_entries = true;
                    setIsChanged({ ...isChanged });
                  }}
                />
              </div>
            );
          }}
          // footer={timeEntryDataTableFooterKeyTemplate(caseDetails?.billing_preferences?.payment_structure)}
          footer={timeEntryDataTableFooterKeyTemplate({
            isEvergreenOrStandardRetainer: isEverGreenRetainer || isStandardRetainer,
            taxPercentage: caseDetails?.billing_preferences?.tax_percentage,
          })}
          sortable
        ></Column>
        <Column
          header="Case Name"
          body={(rowData, props) =>
            caseNameBody({ caseName: rowData?.case_name || caseDetails?.case_description?.case_name || selectedCase?.case_name })
          }
        />
        <Column header="Action" body={(rowData, props) => actionBody({ rowData, props })} />
        <Column
          header="Members"
          body={(rowData, props) => {
            return teamMemberBody({ rowData, props, timeEntiresError });
          }}
          sortable
          editor={(props) => {
            return teamMemberEditTimeEntries({ props, teamMembers, timeEntiresError, timeEntires, onChangeTeamMemberEditTimeEntries });
          }}
        ></Column>
        <Column
          header="Billing Code"
          className="time-entry-billing"
          body={(rowData, props) => {
            return (
              <>
                <span className="p-column-title text-break">Billing Code</span>
                <span className="text-break">{rowData.activity_type ? rowData.activity_type : <i>Please Enter</i>}</span>
                <br></br>
                {timeEntires &&
                  timeEntires[props?.rowIndex].billable &&
                  timeEntiresError &&
                  timeEntiresError[`${props.rowIndex}_activity_type`] && (
                    <span className="text-danger">{timeEntiresError[`${props.rowIndex}_activity_type`]}</span>
                  )}
              </>
            );
          }}
          sortable
          editor={(props) => {
            return (
              <div>
                <Dropdown
                  options={activityTypes}
                  value={{
                    activity_type: props.rowData?.activity_type,
                    activity_type_id: props.rowData?.activity_type_id,
                  }}
                  optionLabel={'activity_type'}
                  onChange={(e) => {
                    let activity_type = e.target.value?.activity_type || '';
                    let activity_type_id = e.target.value?.activity_type_id || '';
                    let index = props.rowIndex;
                    let allTimeEntries = [...timeEntires];
                    allTimeEntries[index].activity_type = activity_type;
                    allTimeEntries[index].activity_type_id = activity_type_id;
                    setTimeEntries([...allTimeEntries]);
                    timeEntiresError[`${props.rowIndex}_activity_type`] = '';
                    setTimeEntriesError({
                      ...timeEntiresError,
                    });
                    isChanged.time_entries = true;
                    setIsChanged({ ...isChanged });
                  }}
                  className="w-100"
                  placeholder="Select"
                  filter
                  showFilterClear={true}
                  showClear={timeEntires[props?.rowIndex].activity_type_id && timeEntires[props?.rowIndex].activity_type ? true : false}
                />
                {timeEntires &&
                  timeEntires[props?.rowIndex].billable &&
                  timeEntiresError &&
                  timeEntiresError[`${props?.rowIndex}_activity_type`] && (
                    <span className="text-danger">{timeEntiresError[`${props.rowIndex}_activity_type`]}</span>
                  )}
              </div>
            );
          }}
        ></Column>
        <Column
          header="Description"
          field="notes"
          body={(rowData) => {
            return (
              <>
                <span className="p-column-title text-break">Description</span>
                <span className="text-break">{rowData.notes ? rowData.notes : <i>Please Enter</i>}</span>
              </>
            );
          }}
          editor={(props) => {
            return (
              <TextSnippetWrapper
                type="text"
                value={props.rowData?.notes}
                onChange={(e) => {
                  let index = props.rowIndex;
                  timeEntires[index].notes = e.target.value;
                  setTimeEntries([...timeEntires]);
                  isChanged.time_entries = true;
                  setIsChanged({ ...isChanged });
                }}
                className="w-100 input-shadow"
              />
            );
          }}
        />
        <Column
          header="Rate"
          field="hour_rate"
          sortable
          body={(rowData, props) => {
            return (
              <>
                <span className="p-column-title text-break">Rate</span>
                <span className="text-break">
                  {rowData.hour_rate ? `${formatNumbersToCurrencyString(rowData.hour_rate)}` : <i>Please Enter</i>}
                </span>
                <br></br>
                {timeEntiresError && timeEntiresError[`${props.rowIndex}_hour_rate`] && (
                  <span className="text-danger">{timeEntiresError[`${props.rowIndex}_hour_rate`]}</span>
                )}
              </>
            );
          }}
          editor={(props) => {
            // TODO: Convert it to template
            return (
              <div>
                <InputText
                  value={props.rowData?.hour_rate}
                  onChange={(e) => {
                    let index = props.rowIndex;
                    timeEntires[index].hour_rate = fixToTwoDecimal(e.target.value);
                    setTimeEntries([...timeEntires]);
                    timeEntiresError[`${props.rowIndex}_hour_rate`] = '';
                    setTimeEntriesError({
                      ...timeEntiresError,
                    });
                    isChanged.time_entries = true;
                    setIsChanged({ ...isChanged });
                  }}
                  className="w-100 input-shadow"
                  type="number"
                  min={0}
                  keyfilter={'pnum'}
                />
                {timeEntiresError && timeEntiresError[`${props.rowIndex}_hour_rate`] && (
                  <span className="text-danger">{timeEntiresError[`${props.rowIndex}_hour_rate`]}</span>
                )}
              </div>
            );
          }}
        ></Column>
        <Column
          header="Duration"
          field="total_hours"
          body={(rowData, props) => {
            return durationTemplate({ rowData, props, timeEntiresError });
          }}
          editor={(props) => {
            //TODO:Convert to template
            return (
              <div>
                <InputText
                  value={props.rowData?.total_hours}
                  onChange={(e) => {
                    let index = props.rowIndex;
                    timeEntires[index].total_hours = fixToTwoDecimal(e.target.value);
                    setTimeEntries([...timeEntires]);
                    timeEntiresError[`${props.rowIndex}_total_hours`] = '';
                    setTimeEntriesError({
                      ...timeEntiresError,
                    });
                    isChanged.time_entries = true;
                    setIsChanged({ ...isChanged });
                  }}
                  className="w-100 input-shadow"
                  type="number"
                  min={0}
                  keyfilter={'pnum'}
                />
                <small id="username1-help" className="p-d-block">
                  0.1 = 6 minutes
                </small>
                {timeEntiresError && timeEntiresError[`${props.rowIndex}_total_hours`] && (
                  <span className="text-danger">{timeEntiresError[`${props.rowIndex}_total_hours`]}</span>
                )}
              </div>
            );
          }}
          footer={() => totalHoursTemplate(totalHours)}
          sortable
        ></Column>
        <Column
          header="Total"
          body={totalBody}
          // footer={timeEntryDataTableFooterValueTemplate(caseDetails?.billing_preferences?.payment_structure)}
          footer={timeEntryDataTableFooterValueTemplate({
            isEvergreenOrStandardRetainer: isEverGreenRetainer || isStandardRetainer,
            taxPercentage: caseDetails?.billing_preferences?.tax_percentage,
            timeEntriesTotal,
            rateWithTax,
          })}
        />
        <Column
          header="Billable"
          className="time-entry-billable"
          body={(rowData, props) => {
            return billableTimeEntriesBody({ rowData, props, onChangeBillable });
          }}
        />
        <Column
          className="time-entry-delete"
          body={(rowData, props) => {
            return (
              <>
                <span className="p-column-title text-break">Actions</span>
                <i
                  className="icon-delete icon-red ms-2 mt-2"
                  onClick={(e) => {
                    deleteTimeEntry(props);
                  }}
                />
              </>
            );
          }}
        />
      </DataTable>
      <div className="col-12 p-2">
        <span className="mt-3 addfield" role="button" onClick={addTimeEntry}>
          {' '}
          <i className="icon-add F-size14 me-1"></i>Add Time Entry
        </span>
      </div>
    </>
  );
};

export default TimeEntries;
