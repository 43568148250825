import * as moment from 'moment';

import 'moment-timezone';

export const getTimeZoneOffsetAsString = () => {
  let timeZoneInMinutes = new Date().getTimezoneOffset();
  let hours;
  let minutes;
  let offSetSign;
  if (timeZoneInMinutes < 0) {
    hours = Math.floor(-timeZoneInMinutes / 60);
    minutes = -timeZoneInMinutes % 60;
    offSetSign = '+';
  } else {
    hours = Math.floor(timeZoneInMinutes / 60);
    minutes = timeZoneInMinutes % 60;
    offSetSign = '-';
  }
  let formattedHours = hours < 10 ? '0' + hours : hours;
  let formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
  let timeZoneOffSet = offSetSign + formattedHours + ':' + formattedMinutes;
  return timeZoneOffSet;
};

export const currentBrowserTimeZone = moment.tz.guess();

const dateTimeOffset = (timeZone, date, format) => {
  let getDate = moment(date)
    .tz(timeZone || currentBrowserTimeZone)
    .format(format);
  return `${getDate}`;
};

export const handleDateTimeOffset = (timeZone, lastUpdateTime = new Date(), format = 'MM/DD/YYYY [,] hh:mm a') => {
  return dateTimeOffset(timeZone ? timeZone : currentBrowserTimeZone, lastUpdateTime ? lastUpdateTime : new Date(), format);
};

export const convertToTimezoneFormat = (date, timezone) => {
  return moment(date)
    ?.tz(timezone || currentBrowserTimeZone, true)
    ?.format();
};

export const convertToTimezoneWithStartOfDayFormat = (date, timezone) => {
  return moment(date)
    ?.startOf('day')
    ?.tz(timezone || currentBrowserTimeZone, true)
    ?.format();
};

export const getTimezoneOffset = (timezone) => {
  // Get the current date-time in the specified timezone
  var currentTime = moment?.tz(timezone);

  // Format the offset in hours and minutes (e.g., +05:30)
  var offset = currentTime?.format('Z');

  return offset;
};

// Function to calculate the number of whole days difference, including time
export const calculateDaysPastDue = (dueDate, timeZone) => {
  // Convert dueDate to the given time zone
  const dueDateTime = moment.tz(dueDate, timeZone);
  // Get the current date and time in the given time zone
  const currentDate = moment.tz(timeZone);

  // Calculate the difference in days including fractional days
  const daysDifference = currentDate.diff(dueDateTime, 'days', true);

  // Get the whole number of days difference
  const wholeDaysDifference = Math.floor(daysDifference); // or Math.round(daysDifference)

  if (wholeDaysDifference > 0) {
    return `${wholeDaysDifference} day${wholeDaysDifference > 1 ? 's' : ''}`;
  } else {
    return '-';
  }
};
