import { performRequestSecond, performRequest } from 'common/apiHandler';
import { default as apiEndPoints } from 'common/apiEndPoints';
import { getTenantDetails } from 'utils/utils';

export const listCaseContacts = (case_id) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequest(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listCaseContacts}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${case_id ? `&case_id=${case_id}` : ''}`
  );
};

export const listAvailablePhoneNumbers = (region, area_code, limit, next) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.availablePhoneNumber}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&region=${region}&area_code=${area_code}&limit=${limit}`
  );
};

export const listTextMessages = (
  case_id,
  contact_id,
  last_message_date = false,
  limit = 10
  //   next= null,
  // limit = 10
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.listTextMessage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}${case_id ? `&case_id=${case_id}` : ''}${
      contact_id ? `&contact_id=${contact_id}` : ''
    }${last_message_date ? `&last_message_date=${last_message_date}` : ''}&limit=${limit}`
  );
};

export const listMessageHistory = (contact_id, message_date = false, phoneNumber = '', limit = 10, next = null) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.GET,
    `${apiEndPoints.textMessageHistory}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_id=${contact_id}${
      message_date ? `&message_date=${message_date}` : ''
    }&phone_number=${phoneNumber}&limit=${limit}`
  );
};

export const sendTextMessage = (
  contact_id,
  text_message_thread_sk,
  file_path,
  media_content_type,
  display_filename,
  size = 0,
  data = {},
  timeEntryData = ''
) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.sendTextMessage}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&contact_id=${contact_id}${
      text_message_thread_sk ? `&text_message_thread_sk=${text_message_thread_sk}` : ''
    }${file_path ? `&file_path=${file_path}` : ''}${media_content_type ? `&media_content_type=${media_content_type}` : ''}${
      display_filename ? `&display_filename=${display_filename}` : ''
    }${size ? `&size=${size}` : ''}`,
    data,
    timeEntryData
  );
};

export const purchasePhoneNumber = (incoming_phone_number, data = {}) => {
  const [tenantId, tenantSk] = getTenantDetails();
  return performRequestSecond(
    apiEndPoints.methodType.POST,
    `${apiEndPoints.purchasePhoneNumber}?tenant_id=${tenantId}&tenant_sk=${tenantSk}&incoming_phone_number=${incoming_phone_number}`,
    data
  );
};
