/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';

import { useCalendarContext } from 'modules/calendar/Context/CalendarContext';
import { UserDetailsContext } from 'context/userDetailsContext';

import EventTitle from './Components/EventTitle';
import EventCaseName from './Components/EventCaseName';
import EventNotLinkedToCase from './Components/EventNotLinkedToCase';
import EventType from './Components/EventType/EventType';
import EventDate from './Components/EventDate';
import EventTime from './Components/EventTime';
import EventVideoConferenceUrl from './Components/EventVideoConferenceUrl';
import EventLocation from './Components/EventLocation/EventLocation';
import EventAttendees from './Components/EventAttendees/EventAttendees';
import EventDescription from './Components/EventDescription';
import EventAttachments from './Components/EventAttachments';
import EventReminders from './Components/EventReminders';

import { reminderOptions } from 'constants/dropdownOptions';

function CreateEventForm({ control, resetField, setValue, getValues, watch, errors, trigger }) {
  const { isGlobal, editEventModal, eventDetails, typeList, setUploadedAttachments, isClientCalendar } = useCalendarContext();
  const userContext = useContext(UserDetailsContext);

  const notLinkedToCaseWatcher = watch('not_linked_to_case');
  const whenWatcher = watch('when');
  const startTimeWatcher = watch('meeting_start_time');
  const endTimeWatcher = watch('meeting_end_time');

  useEffect(() => {
    if (editEventModal && eventDetails) {
      setValue('title', eventDetails.title);
      setValue('case', eventDetails?.case);
      setValue('not_linked_to_case', eventDetails?.not_linked_to_case);
      let event_type = typeList.find((val) => eventDetails?.event_type?.event_type === val.event_type);
      setValue('event_type', event_type);
      setValue('when', new Date(eventDetails.when));
      setValue('meeting_start_time', new Date(eventDetails?.meeting_start_time));
      setValue('meeting_end_time', new Date(eventDetails?.meeting_end_time));

      setValue('conference_url', eventDetails?.conference_url);

      // to remove the current user from the accessible_list
      let users = eventDetails?.accessible_list.filter((user) => user !== eventDetails?.created_by);
      setValue('selected_attendees_list', eventDetails?.selected_attendees_list);
      setValue('contacts', eventDetails?.contacts ?? []);
      setValue('firm_users', eventDetails?.firm_users ?? []);

      // console.log(eventDetails)

      setValue('guests', eventDetails?.guests);

      let groups = eventDetails?.groups?.length > 0 ? eventDetails?.groups?.filter((group) => group?.group_status !== 'Deleted') : [];

      setValue('groups', groups);

      setUploadedAttachments(eventDetails?.attachments);

      setValue('location', eventDetails.location);
      setValue('description', eventDetails.description);

      // set reminders and custom reminder properties
      if (eventDetails?.reminder?.length > 0) {
        let _reminders = [];
        eventDetails.reminder.forEach((value) => {
          let _reminder = reminderOptions.filter((index) => index.value === value);
          if (_reminder.length > 0) {
            _reminders.push({
              value: _reminder[0].value,
              count: 1,
              type: 'minutes',
            });
          } else {
            let custom_reminder = value.split('#');
            let obj = {
              value: 'custom',
              customValue: custom_reminder[0] ? parseInt(custom_reminder[0]) : 0,
              type: custom_reminder[1] ? custom_reminder[1] : 'minutes',
            };
            _reminders.push(obj);
          }
        });
        setValue('reminder', _reminders);
      }
    }
  }, [editEventModal, eventDetails]);

  let timezoneBasedTime = new Date().toLocaleString('en-US', {
    timeZone: userContext?.userDetails?.timezone,
  });

  return (
    <form>
      {/* form heading */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="modal-heading F-size16 text-bold">{editEventModal ? 'Event Details' : 'Create Event'}</div>
      </div>
      <EventTitle control={control} errors={errors} />
      {isGlobal ? (
        <EventCaseName
          control={control}
          errors={errors}
          notLinkedToCaseWatcher={notLinkedToCaseWatcher}
          isClientCalendar={isClientCalendar}
        />
      ) : null}
      {isGlobal ? <EventNotLinkedToCase control={control} resetField={resetField} /> : null}
      <EventType control={control} setValue={setValue} resetField={resetField} />
      <EventDate control={control} trigger={trigger} errors={errors} setValue={setValue} timezoneBasedTime={timezoneBasedTime} />
      <EventTime
        control={control}
        trigger={trigger}
        errors={errors}
        setValue={setValue}
        timezoneBasedTime={timezoneBasedTime}
        whenWatcher={whenWatcher}
        startTimeWatcher={startTimeWatcher}
        endTimeWatcher={endTimeWatcher}
      />
      <EventVideoConferenceUrl control={control} />
      <EventLocation control={control} setValue={setValue} />
      <EventAttendees control={control} getValues={getValues} setValue={setValue} trigger={trigger} />
      <EventDescription control={control} />

      {/* {!notLinkedToCaseWatcher && ( */}
      <EventAttachments control={control} setValue={setValue} getValues={getValues} />
      {/* )} */}

      <EventReminders control={control} watch={watch} />
    </form>
  );
}

export default CreateEventForm;
