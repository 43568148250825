import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import { createPaymentReminder, updatePaymentReminder, deletePaymentReminder } from 'services/billingServices';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useToast } from 'context/ToastContext';
import { toastConstant } from 'constants/toastmessage';
import useSpinner from 'hooks/useSpinner';
import { ModalHeader } from 'shared/ModalHeader';

export const Reminders = (props) => {
  let [reminders, setReminders] = useState([
    {
      reminder_date_time: '',
      reminder_channel_id: '',
      reminder_channel_type: '',
    },
  ]);
  const Channels = [
    {
      reminder_channel_id: 1,
      reminder_channel_type: 'Notification Center',
    },
    {
      reminder_channel_id: 2,
      reminder_channel_type: 'Email Notification',
    },
    {
      reminder_channel_id: 3,
      reminder_channel_type: 'Secure Message',
    },
  ];
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const [spinner, showSpinner, hideSpinner] = useSpinner(true);
  let [formErrors, setFormErrors] = useState([]);

  useEffect(() => {
    if (props.selectedRequest?.reminder && props.selectedRequest?.reminder.length > 0) {
      setIsEdit(true);
      setReminders([...props.selectedRequest?.reminder]);
    } else {
      setReminders([
        {
          reminder_date_time: '',
          reminder_channel_id: '',
          reminder_channel_type: '',
        },
      ]);
      setIsEdit(false);
    }
  }, [props.selectedRequest]);

  const validateForm = () => {
    let isValid = true;

    if (reminders && reminders.length > 0) {
      formErrors = reminders.map((item, id) => {
        return { id: id };
      });
      reminders.forEach((item, id) => {
        if (!item.reminder_date_time) {
          formErrors[id].date = 'Required';
          isValid = false;
        }
        if (!item.reminder_channel_id) {
          formErrors[id].channels = 'Required';
          isValid = false;
        }
      });
      setFormErrors([...formErrors]);
    }
    return isValid;
  };

  const onSubmit = () => {
    if (validateForm()) {
      let data = {
        due_date: props.selectedRequest?.due_date,
        reminder_data: reminders,
      };
      if (isEdit) {
        setLoading(true);
        updatePaymentReminder(data, props.selectedRequest.sk)
          .then((response) => {
            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.REMINDER_UPDATE_SUCCESS);
            props.onSuccess();
            props.onHide();
            setLoading(false);
          })
          .catch((err) => {
            console.log('err', err);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.REMINDER_UPDATE_FAILED);
            setLoading(false);
          });
      } else {
        setLoading(true);
        createPaymentReminder(props.selectedRequest.sk, data)
          .then((response) => {
            addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.REMINDER_CREATE_SUCCESS);
            setLoading(false);
            props.onSuccess();
            props.onHide();
          })
          .catch((err) => {
            console.log('err', err);
            addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.REMINDER_CREATE_FAILED);
            setLoading(false);
          });
      }
    }
  };

  const deleteReminder = (reminder, id) => {
    if (reminder?.sk) {
      showSpinner();
      deletePaymentReminder(reminder?.sk)
        .then((response) => {
          reminders.splice(id, 1);
          if (formErrors[id]) {
            formErrors[id].date = '';
            formErrors[id].channels = '';
            setFormErrors([...formErrors]);
          }
          setReminders([...reminders]);
          if (reminders.length <= 0) {
            props.onHide();
          }
          addToast(false, toastConstant.toasterType.SUCCESS, toastConstant.api.SUCCESS, toastConstant.message.REMINDER_DELETE_SUCCESS);
          hideSpinner();
          props.onSuccess();
        })
        .catch((err) => {
          addToast(false, toastConstant.toasterType.ERROR, toastConstant.api.FAILED, toastConstant.message.REMINDER_DELETE_FAILED);
          hideSpinner();
        });
    } else {
      reminders.splice(id, 1);
      setReminders([...reminders]);
      if (formErrors[id]) {
        formErrors[id].date = '';
        formErrors[id].channels = '';
        setFormErrors([...formErrors]);
      }
    }
  };

  return (
    <Dialog
      visible={props.show}
      onHide={props.onHide}
      //header={isEdit ? "Update Reminder" : "Create Reminder"}
      //style={{ width: "800px" }}
      className="new-task-popup"
      header={ModalHeader}
      footer={<></>}
    >
      <div className="modal-heading F-size16 text-bold mb-3">{isEdit ? 'Update Reminder' : 'Create Reminder'}</div>
      {spinner}
      {reminders &&
        reminders.length > 0 &&
        reminders.map((item, id) => {
          return (
            <div className="row py-2">
              <div className="col-12 col-md-3 pe-0 r-pb-8">
                <Calendar
                  value={typeof item.reminder_date_time === 'object' ? item.reminder_date_time : new Date(item.reminder_date_time)}
                  onChange={(e) => {
                    reminders[id].reminder_date_time = e.value;
                    if (reminders[id] && reminders[id].sk) {
                      reminders[id].is_changed = true;
                    }
                    setReminders([...reminders]);
                    if (formErrors[id]) {
                      formErrors[id].date = '';
                      setFormErrors([...formErrors]);
                    }
                  }}
                  placeholder="Select Date"
                  minDate={new Date()}
                  className="input-shadow w-100"
                />
                {formErrors && formErrors.length > 0 && formErrors[id]?.date && <span className="text-danger">Required</span>}
              </div>
              <div className="col-12 col-md-3 pe-0 r-pb-8">
                <Calendar
                  value={typeof item.reminder_date_time === 'object' ? item.reminder_date_time : new Date()}
                  onChange={(e) => {
                    reminders[id].reminder_date_time = e.value;
                    if (reminders[id] && reminders[id].sk) {
                      reminders[id].is_changed = true;
                    }
                    setReminders([...reminders]);
                  }}
                  timeOnly
                  hourFormat="12"
                  placeholder="Select Time"
                  className="input-shadow w-100"
                  minDate={new Date()}
                />
              </div>
              <div className="col-12 col-sm-4 r-pb-8 pe-0">
                <Dropdown
                  options={Channels}
                  placeholder="Select Channel"
                  optionLabel="reminder_channel_type"
                  className="w-100"
                  optionValue="reminder_channel_id"
                  onChange={(e) => {
                    reminders[id].reminder_channel_id = e.value;
                    reminders[id].reminder_channel_type = Channels[e.value]?.reminder_channel_type;

                    if (reminders[id] && reminders[id].sk) {
                      reminders[id].is_changed = true;
                    }
                    setReminders([...reminders]);
                    if (formErrors[id]) {
                      formErrors[id].channels = '';
                      setFormErrors([...formErrors]);
                    }
                  }}
                  value={item.reminder_channel_id}
                  filter
                  showClear={item.reminder_channel_id === '' ? false : true}
                />

                {formErrors && formErrors.length > 0 && formErrors[id]?.channels && <span className="text-danger">Required</span>}
              </div>
              <div className="col-12 col-sm-2 align-items-center d-flex">
                <span>
                  <i
                    className="icon icon-delete ms-2 cursor-pointer"
                    onClick={(e) => {
                      deleteReminder(item, id);
                    }}
                  ></i>
                </span>
              </div>
            </div>
          );
        })}
      <div className="py-2">
        <div className="col-12  pointer">
          <span
            // role="button"
            onClick={(e) => {
              if (isEdit) {
                reminders.push({
                  reminder_date_time: '',
                  reminder_channel_id: '',
                  reminder_channel_type: '',
                  is_new: true,
                  is_changed: false,
                });
              } else {
                reminders.push({
                  reminder_date_time: '',
                  reminder_channel_id: '',
                  reminder_channel_type: '',
                });
              }

              setReminders([...reminders]);
            }}
            className="mt-2 edit-field addfield"
          >
            + Add Reminder
          </span>
        </div>
      </div>
      <div className="footer-btn">
        <Button label="Cancel" onClick={props.onHide} className="p-button-secondary outline me-2" />
        <Button
          label={isEdit ? 'Update' : 'Save'}
          className="p-button-secondary"
          onClick={onSubmit}
          loading={loading}
          disabled={!reminders || (reminders && reminders.length === 0) || props.selectedRequest?.due_amount <= 0}
        />
      </div>
    </Dialog>
  );
};
