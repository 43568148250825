import React from 'react';
import { useFileManagerContext } from 'modules/file_manager/context/FileManagerContext';

function Name({ item, selectFile }) {
  const { modals, setSelectedItem, handleSingleCheckboxClick, selectedRows, caseDetails } = useFileManagerContext();

  function handleVersionControlClick() {
    setSelectedItem(item);
    modals.setShowUploadedVersionsModal(true);
  }

  return (
    <div className="d-flex w-100" style={{ background: '#EFF4FF', borderRadius: '0px 0px 16px 16px' }}>
      <input
        type="checkbox"
        onChange={(e) => handleSingleCheckboxClick(e, item)}
        checked={selectedRows?.includes(item)}
        className="m-2"
        disabled={caseDetails?.case_closed || (caseDetails?.is_lead && !caseDetails?.is_open)}
      />
      <div
        className="d-inline-block text-truncate"
        style={{
          width: '100%',
          color: '#2655B9',
          fontWeight: 'bolder',
          padding: '16px',
        }}
        title={item.display_name}
        onClick={() => selectFile(item)}
      >
        {item.display_name}
      </div>

      {item.entry_type !== 'folder' && item?.versions?.length > 0 && (
        <i
          className="fas fa-history"
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '10px',
            color: '#2655B9',
          }}
          title="Version history"
          onClick={handleVersionControlClick}
        />
      )}
    </div>
  );
}

export default Name;
