import React from 'react';
import NumberFormat from 'react-number-format';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';

import { getFormattedCountryList, getFormattedStateList } from '../../helpers/createInvoiceHelpers';
import TextSnippetWrapper from 'components/TextSnippetWrapper';

const InvoiceForForm = (props) => {
  const {
    isLeadSpecific,
    caseDetails,
    caseView,
    selectedInvoice,
    caseList,
    selectedCase,
    billingContacts,
    selectedBillingContact,
    contactDetails,
    onChangeCase,
    onChangeContact,
    setContactDetails,
    formErrors,
    setFormErrors,
    searchValue,
    setSearchValue,
    load,
    setLoad,
  } = props || {};

  const statesList = getFormattedStateList();
  const countriesList = getFormattedCountryList();

  //TODO: Can split to different components
  //TODO:REMOVE direct state manipulation  --- done

  const searchCase = (e) => {
    let query = e?.query;
    setSearchValue(query);
  };

  return (
    <div className="col-12 col-md-6 ">
      <div className="row">
        <div className="col-12 caption-bold">INVOICE FOR</div>
      </div>
      <div className="shadow-small p-3">
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">
            Case
            <span className="text-danger">*</span>
          </div>
          <div className="col-12 col-md-6">
            {isLeadSpecific ? (
              <InputText
                className="input-shadow w-100"
                placeholder="Case Name"
                value={caseDetails?.case_description?.case_name}
                disabled={caseView}
              />
            ) : (
              <>
                {/* <Dropdown
                  options={caseList}
                  optionLabel={'new_case_name'}
                  value={selectedCase}
                  onChange={(e) => {
                    onChangeCase(e);
                    setFormErrors({ ...formErrors, case: '' });
                  }}
                  placeholder="Please Select a case"
                  className="input-shadow w-100 border-0"
                  disabled={selectedInvoice || caseView}
                  filter
                  valueTemplate={(option, props) => SelectedCaseTemplate(option, props)}
                /> */}

                <AutoComplete
                  value={selectedCase?.case_description?.case_name ? selectedCase?.case_description?.case_name : selectedCase}
                  suggestions={caseList}
                  completeMethod={searchCase}
                  field="label"
                  onChange={(e) => {
                    onChangeCase(e);
                    setFormErrors({ ...formErrors, case: '' });
                  }}
                  placeholder="Select or Search a case"
                  className="input-shadow w-100 border-0"
                  disabled={selectedInvoice || caseView}
                />
                {formErrors?.case && <span className="text-danger">Required</span>}
              </>
            )}
          </div>
        </div>

        {/* TODO:"Please select a case to list contacts"  Validation needs to be added*/}
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">
            Contact
            <span className="text-danger">*</span>
          </div>
          <div className="col-12 col-md-6">
            <Dropdown
              placeholder="Please select case contacts"
              options={billingContacts}
              optionLabel={'name'}
              value={selectedBillingContact}
              onChange={onChangeContact}
              className="input-shadow w-100 border-0"
              filter
            />
            {formErrors?.contact && <span className="text-danger">Required</span>}
          </div>
        </div>
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">Street</div>
          <div className="col-12 col-md-6">
            <TextSnippetWrapper
              type="text"
              value={contactDetails?.street ? contactDetails?.street : ''}
              onChange={(e) => {
                setContactDetails({ ...contactDetails, street: e.target.value });
              }}
              className="input-shadow w-100 border-0 p-2"
              placeholder="Please type in"
            />
          </div>
        </div>
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">City</div>
          <div className="col-12 col-md-6">
            <TextSnippetWrapper
              type="text"
              value={contactDetails?.city ? contactDetails?.city : ''}
              onChange={(e) => {
                setContactDetails({ ...contactDetails, city: e.target.value });
              }}
              className="input-shadow w-100 border-0 p-2"
              placeholder="Please select a city"
            />
          </div>
        </div>
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">State</div>
          <div className="col-12 col-md-6">
            {contactDetails?.country === 'United States' ? (
              <Dropdown
                options={statesList}
                value={contactDetails?.state || ''}
                onChange={(e) => {
                  setContactDetails({ ...contactDetails, state: e.target.value });
                }}
                className="input-shadow w-100 border-0 p-2"
                placeholder="State"
                filter
              />
            ) : (
              <TextSnippetWrapper
                type="text"
                value={contactDetails?.state ? contactDetails?.state : ''}
                onChange={(e) => {
                  contactDetails.state = e.target.value;
                  setContactDetails({ ...contactDetails });
                }}
                className="input-shadow w-100 border-0 p-2"
                placeholder="Please type in"
              />
            )}
          </div>
        </div>

        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">Country</div>
          <div className="col-12 col-md-6">
            <Dropdown
              options={countriesList}
              value={contactDetails?.country || ''}
              onChange={(e) => {
                setContactDetails({ ...contactDetails, country: e.target.value });
              }}
              className="input-shadow w-100 border-0"
              placeholder="Please select a country"
              filter
            />
          </div>
        </div>

        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">Phone Number</div>
          <div className="col-12 col-md-6">
            <NumberFormat
              className="input-shadow w-100 p-2 border-0 number-format"
              placeholder="Please type in"
              format="(###) ###-####"
              mask="_"
              onValueChange={(e) => {
                setContactDetails((pre) => ({
                  ...pre,
                  phone: e.value,
                }));
              }}
              value={contactDetails?.phone || ''}
            />
          </div>
        </div>
        <div className="d-flex align-items-center py-2 flex-wrap">
          <div className="col-12 col-md-6 p-medium">Zip Code</div>
          <div className="col-12 col-md-6">
            <InputText
              value={contactDetails?.zip || ''}
              onChange={(e) => {
                setContactDetails({ ...contactDetails, zip: e.target.value });
              }}
              className="input-shadow w-100 border-0 p-2"
              placeholder="Please type in"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceForForm;
