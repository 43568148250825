import React, { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Controller, useFieldArray } from 'react-hook-form';
import { timeIntervalsWithServerValue } from 'constants/dropdownOptions';
import moment from 'moment';
import { useTaskContext } from 'modules/Tasks/Context/TaskContext';

const getLabelFromValue = (value, field = 'label') => {
  const item = timeIntervalsWithServerValue.find((item) => item.value === value);
  return item ? item[field] : '';
};

export default function NestedRemider({ control, dueDate }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'reminder',
  });
  const { isClosed } = useTaskContext();

  const [editingIndexes, setEditingIndexes] = useState([]);

  const toggleEdit = (index) => {
    if (editingIndexes.includes(index)) {
      setEditingIndexes(editingIndexes.filter((item) => item !== index));
    } else {
      setEditingIndexes([...editingIndexes, index]);
    }
  };

  const handleAppend = () => {
    append({ value: '' });
    setEditingIndexes([...editingIndexes, fields.length]); // Make the newly appended field editable
  };

  const handleRemove = (index) => {
    remove(index);
    setEditingIndexes((prevIndexes) => prevIndexes.filter((item) => item !== index).map((item) => (item > index ? item - 1 : item)));
  };

  //Filter reminder options based on current date
  const filteredList = timeIntervalsWithServerValue?.filter(
    (v) => moment(dueDate).startOf('day').diff(moment().startOf('day'), 'days') >= v.days
  );

  return (
    <>
      {fields?.map((item, index) => {
        const isEditing = editingIndexes.includes(index);

        const isafterDue = getLabelFromValue(item.value, 'days') <= moment(dueDate).startOf('day').diff(moment().startOf('day'), 'days');
        return (
          <div className="d-flex align-items-center" key={item.id}>
            {!isClosed && isEditing ? (
              <Controller
                name={`reminder[${index}].value`}
                control={control}
                render={({ field }) => (
                  <Dropdown
                    optionDisabled={(e) => fields?.find((v) => v.value === e.value)}
                    className="mb-2 w-100 border border-0"
                    placeholder="Select Reminder"
                    options={filteredList}
                    filter
                    {...field}
                  />
                )}
              />
            ) : (
              <label className="mt-2 mb-2" onClick={() => isafterDue && toggleEdit(index)}>
                {getLabelFromValue(item.value)}
              </label>
            )}
            {isafterDue && (
              <div className={`ms-3 ${isClosed ? 'pe-none opacity-50' : ''}`}>
                <i className="icon-delete icon-red pointer" onClick={() => handleRemove(index)} />
              </div>
            )}
          </div>
        );
      })}
      {fields?.length < filteredList.length && (
        <div className={`mt-2 edit-field pointer addfield  ${isClosed ? 'pe-none opacity-50' : ''}`} onClick={handleAppend}>
          <i className="icon-add F-size14 me-1"></i>Add Reminder
        </div>
      )}
      {fields?.length === 0 && filteredList.length === 0 && '--'}
    </>
  );
}
